// This is a drop down menu that appears under the About Us tab
// in the menu bar at the top of the website.
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function BasicMenu({ urls }) {
  const [open, setOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null); // Track which item is being hovered
  const history = useHistory(); // Initialize useHistory
  const menuRef = useRef(null); // Reference to the menu div

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleOurConceptClick = () => {
    history.push(urls.aboutUs);
    setOpen(false);
  };

  const handleHowItWorksClick = () => {
    history.push(urls.howItWorks);
    setOpen(false);
  };

  const handleClickOutside = (event) => {
    // Check if the click occurred outside the menu and the button
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      event.target.id !== "basic-button"
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <button
        id="basic-button"
        onClick={handleButtonClick}
        className="Menu-Font-Style"
      >
        About Us
      </button>
      {/* Conditionally render the list based on the open state */}
      {open && (
        <div
          ref={menuRef}
          className="menu"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            zIndex: 1,
            width: "144px",
          }}
        >
          <div
            className="Menu-Item-Font-Style"
            onClick={handleOurConceptClick}
            onMouseEnter={() => setHoveredItem("ourConcept")}
            onMouseLeave={() => setHoveredItem(null)}
            style={{
              padding: "8px 12px",
              cursor: "pointer",
              backgroundColor:
                hoveredItem === "ourConcept"
                  ? "rgb(69, 170, 69)"
                  : "transparent",
              color: hoveredItem === "ourConcept" ? "#fff" : "#000",
            }}
          >
            Our Concept
          </div>
          <div
            className="Menu-Item-Font-Style"
            onClick={handleHowItWorksClick}
            onMouseEnter={() => setHoveredItem("howItWorks")}
            onMouseLeave={() => setHoveredItem(null)}
            style={{
              padding: "8px 12px",
              cursor: "pointer",
              backgroundColor:
                hoveredItem === "howItWorks"
                  ? "rgb(69, 170, 69)"
                  : "transparent",
              color: hoveredItem === "howItWorks" ? "#fff" : "#000",
            }}
          >
            How It Works
          </div>
        </div>
      )}
    </div>
  );
}
