import React from "react";
import firebase from "./../Firebase.js";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";

export default class PersonalInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      restaurantName: props.restaurantName,
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      phoneNumber: props.phoneNumber,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();

    if (this.props.userInfo.userType === "restaurant") {
      const menuDocRef = database
        .collection("RestaurantMenu")
        .doc(this.state.restaurantName);
      batch.set(
        menuDocRef,
        { phoneNumber: this.state.phoneNumber },
        { merge: true },
      );

      batch.commit();
    }
    this.setState({
      open: false,
    });

    // The individual doesn't have the name of their organisation (as they aren't one)
    // and so this state needs to be removed from the state array before it is put
    // into the database
    if (this.props.userInfo.userType === "individual") {
      const stateTemp = { ...this.state }; // make a separate copy of the array
      delete stateTemp.restaurantName;

      // if the user changed the email then we have to update their sign in options.
      // In the event the user hasn't logged in recently nothing will be updated.
      if (this.props.email !== this.state.email) {
        this.props.currentUser
          .updateEmail(this.state.email)
          .then((props) => {
            // Update successful.
            this.props.handleInfoUpdate(stateTemp);
            console.log("Email Updated");
          })
          // eslint-disable-next-line n/handle-callback-err
          .catch(function (error) {
            // eslint-disable-next-line no-undef
            alert(
              "It's been too long since you signed in last please log out and sign in again.",
            );
          });
      } else {
        this.props.handleInfoUpdate(stateTemp);
      }
    } else {
      this.props.handleInfoUpdate(this.state);
    }
    return null;
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    // Depending on whether this is an organisation or a restaurant it will show
    // the proper label.
    let labelName = "Organisation Name";
    if (this.props.userInfo.userType === "restaurant") {
      labelName = "Restaurant Name";
    }
    return (
      <div>
        <Button
          className="Edit-Button"
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          edit
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Organisation Information
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              {this.props.userInfo.userType !== "individual" && (
                <TextField
                  margin="dense"
                  id="restaurant name"
                  label={labelName}
                  name="restaurantName"
                  value={this.state.restaurantName}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
              )}
              <TextField
                margin="dense"
                id="first name"
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="last name"
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="phone number"
                label="Phone Number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
