// This a dialog that pops up with editing a volunteer fees. This includes
// the volunteer cash and credit portions.
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UpdateCustomerVolunteerFees from "./../ModifyOrders/UpdateCustomerVolunteerFees.js";
import UpdateCustomerVolunteerFeesBasket from "./../ModifyOrders/UpdateCustomerVolunteerFeesBasket.js";
import "date-fns";

export default function VolunteerFeeChangeDialog({
  userOrder,
  user,
  imported,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  locationName,
  updatePaymentHistory,
  handleCommunityOrderChange,
  importedOrder,
  selectedDate,
}) {
  const [open, setOpen] = useState(false);
  // The original credit portion of the user's order.
  const [oldCreditPortion, setOldCreditPortion] = useState(
    userOrder.creditPortion,
  );
  // The cash portion that is entered by the user.  It's defaulted to the original amount.
  const [cashPortion, setCashPortion] = useState(userOrder.cashPortion);
  // The credit portion that is entered by the user.  It's defaulted to the original amount.
  const [creditPortion, setCreditPortion] = useState(userOrder.creditPortion);
  // The updated user order.
  const [userOrderToUpdate, setUserOrderToUpdate] = useState({ ...userOrder });

  // In case the props change the userOrder needs to be updated and so does the oldCreditPortion
  useEffect(() => {
    setUserOrderToUpdate({ ...userOrder });
    setOldCreditPortion(userOrder.creditPortion);
  }, [
    userOrder.cashPortion,
    userOrder.creditPortion,
    userOrder,
    user,
    imported,
    order,
    userInfo,
    orderLog,
    updateOrderLog,
    orderIndex,
    locationName,
    updatePaymentHistory,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // When the user saves their changes then this calls the functions to save to
  // the database.
  const handleSave = (e) => {
    e.preventDefault();
    // If handleCommunityOrderChange is defined then the call comes from the Basket
    // otherwise it come from the MyAccount Page.  Call these functions to update
    // the database with the changes.
    if (handleCommunityOrderChange !== undefined) {
      UpdateCustomerVolunteerFeesBasket(
        user,
        imported,
        userInfo,
        userOrderToUpdate,
        locationName,
        oldCreditPortion,
        handleCommunityOrderChange,
        importedOrder,
        selectedDate,
      );
    } else {
      UpdateCustomerVolunteerFees(
        user,
        imported,
        order,
        userInfo,
        orderLog,
        updateOrderLog,
        orderIndex,
        userOrderToUpdate,
        updatePaymentHistory,
        locationName,
      );
    }
    setOpen(false);
  };

  // This updates the value that holds the donationSubsidy amount.  This is the
  // actual value.
  const handleChange = (e) => {
    // Tells if it is the cash or credit portion being updated.
    const name = e.target.name;
    // The value of the user input.
    let value = e.target.value;
    // The user's order currently that we will modify.
    const userOrderTemp = { ...userOrderToUpdate };

    // If the user enters nothing then we will input 0
    if (value === "") {
      value = 0;
    }

    // If the value is less than 0 then we won't update.
    if (value < 0) {
      return null;
    }
    // if the user is entering a valid value.
    else {
      // If the user is updating the cash portion.
      if (name === "cashPortion") {
        userOrderTemp.cashPortion = parseFloat(value).toFixed(2);
        setCashPortion(parseFloat(value));
      }
      // Otherwise they're updating the credit portion.
      else {
        userOrderTemp.creditPortion = parseFloat(value).toFixed(2);
        setCreditPortion(parseFloat(value));
      }
    }
    // Update the user order.
    setUserOrderToUpdate({ ...userOrderTemp });
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        SET VOLUNTEER FEES
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Change Participation Fees
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Cash Portion"
            name="cashPortion"
            value={cashPortion}
            onChange={(e) => handleChange(e)}
            type="number"
            inputProps={{
              step: "0.1",
            }}
            onBlur={() => setCashPortion(parseFloat(cashPortion).toFixed(2))}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Credit Portion"
            name="creditPortion"
            value={creditPortion}
            onChange={(e) => handleChange(e)}
            type="number"
            inputProps={{
              step: "0.1",
            }}
            onBlur={() =>
              setCreditPortion(parseFloat(creditPortion).toFixed(2))
            }
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
