// This is a dialog that pops up when a user tries to remove a customer order from
// the basket or the myAccount page.   It will pop to confirm the user meant to
// delete the customer order and warn them the action can't be undone.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import "../../styles/MyAccount.css";
import RemoveCustomerOrder from "./../ModifyOrders/RemoveCustomerOrder.js";
import RemoveCustomerOrderOld from "./../ModifyOrders/RemoveCustomerOrderOld.js";
import RemoveCustomerOrderBasket from "./../ModifyOrders/RemoveCustomerOrderBasket.js";

function RemoveOrderBox(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleRemoveOrder = () => {
    // If handleCommunityOrderChange is defined then we know that the remove order
    // came from the basket page and so it will call the remove basket order Otherwise
    // it will remove the order from the customer.
    if (props.handleCommunityOrderChange !== undefined) {
      RemoveCustomerOrderBasket(
        props.user,
        props.imported,
        props.userInfo,
        props.handleCommunityOrderChange,
        props.importedOrder,
        props.location,
        props.selectedDate,
        props.userOrder,
      );
    } else {
      // With the introduction of the multiple imported orders there is new way to
      // interact with the orders. June 17 2021
      if (props.order.orderDate > "202106150000000000000") {
        RemoveCustomerOrder(
          props.order,
          props.user,
          props.imported,
          props.userInfo,
          props.orderIndex,
          props.orderLog,
          props.updateOrderLog,
          props.location,
          props.updatePaymentHistory,
        );
      } else {
        RemoveCustomerOrderOld(
          props.order,
          props.user,
          props.imported,
          props.userInfo,
          props.orderIndex,
          props.orderLog,
          props.updateOrderLog,
        );
      }
    }
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Confirm Delete Order</DialogTitle>
      <DialogContent>
        Please Confirm you wish to delete this order. This action cannot be
        undone.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleRemoveOrder}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RemoveOrderBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function RemoveCustomerOrderDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="Order-Again-Button">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          REMOVE ORDER
        </Button>
      </div>

      <RemoveOrderBox
        open={open}
        onClose={handleClose}
        user={props.user}
        imported={props.imported}
        userInfo={props.userInfo}
        userOrder={props.userOrder}
        // Variables when it's from myAccount
        order={props.order}
        orderIndex={props.orderIndex}
        orderLog={props.orderLog}
        updateOrderLog={props.updateOrderLog}
        // For when it's from the basket
        handleCommunityOrderChange={props.handleCommunityOrderChange}
        importedOrder={props.importedOrder}
        location={props.location}
        selectedDate={props.selectedDate}
        updatePaymentHistory={props.updatePaymentHistory}
      />
    </div>
  );
}
