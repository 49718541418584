// This is to remove an individual user's order from the myAccount orderHistory
// page.
import firebase from "./../Firebase.js";
import IndividualPriceChangesPaymentHistory from "./../../pages/MyAccountPage/Functions/PriceHistoryChanges/IndividualPriceChangesPaymentHistory.js";
import PriceTotals from "./../../functions/PriceTotals.js";

// order: includes the user's full order {...}
// user : the user in the format FirstName-LastName-UserId-orderDate
// imported: boolean if this order was imported
// userInfo : the distribution location's userInfo
// orderIndex : the index of the distribution location's order in the orderLog (not the individual's order)
// orderLog : the distribution locations orders [...]
// updateOrderLog : a function to update the orderLog
export default function RemoveCustomerOrder(
  order,
  user,
  imported,
  userInfo,
  orderIndex,
  orderLog,
  updateOrderLog,
  location,
  updatePaymentHistory,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userOrderDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userId[3]);

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database.collection("Users").doc(userId[2]);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // Assume the order is coming from the communityOrders this is used to set the
  // database name
  let userDataRef = "communityOrders";

  if (imported) {
    // If the imported Orders are being modified change this to be correct
    userDataRef = ["importedOrder", location, "communityOrders"].join(".");
    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(order.importedOrder[location].date);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  // Add the user to the imported or community orders to correctly update the nested
  // data
  userDataRef = userDataRef.concat(".", user);

  // The user's order before it is deleted.
  let userOrder = {};

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    // Set the user order that is being deleted.
    userOrder = {
      ...orderLogTemp[orderIndex].importedOrder[location].communityOrders[user],
    };
    delete orderLogTemp[orderIndex].importedOrder[location].communityOrders[
      user
    ];
  } else {
    // Set the user order that is being deleted.
    userOrder = { ...orderLogTemp[orderIndex].communityOrders[user] };
    delete orderLogTemp[orderIndex].communityOrders[user];
  }

  // Remove the order from the distirbution location's order history
  batch.update(docRef, {
    [userDataRef]: firebase.firestore.FieldValue.delete(),
  });

  // Remove the order from the DistributionLocation collection
  batch.update(communityOrdersDocRef, {
    [user]: firebase.firestore.FieldValue.delete(),
  });

  // The total each farmer is owed on this order.
  const originalOrderFarmsCost = PriceTotals(userOrder.foodList, true);

  // The total cost of the order.
  let originalOrderCost = 0;

  // Cycle through the totals owed to each farm and add them up.
  Object.keys(originalOrderFarmsCost).forEach((farmName) => {
    originalOrderCost += originalOrderFarmsCost[farmName];
  });
  // Add or subtract the subsidy and donations to the total order cost.
  if (userOrder.customerContribution === "donation") {
    originalOrderCost += userOrder.donationSubsidy;
  } else {
    originalOrderCost -= userOrder.donationSubsidy;
  }

  // If the user has a cashPortion for the volunteer fee the we want to add it to
  // the original cost of this order.
  if (parseFloat(userOrder.cashPortion) > 0) {
    originalOrderCost += parseFloat(userOrder.cashPortion);
  }

  // If the user used credits for their order then we want to refund their credits.
  if (parseFloat(userOrder.creditPortion) > 0) {
    batch.update(userDocRef, {
      credits: firebase.firestore.FieldValue.increment(
        parseFloat(userOrder.creditPortion),
      ),
    });
  }

  // Delete the order from the individual's order
  userOrderDocRef
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  // Since this could be called from the Basket Page we don't want to update the
  // payment history.
  if (updatePaymentHistory) {
    // Update the payment history to include the price change.
    IndividualPriceChangesPaymentHistory(
      order.orderDate,
      originalOrderCost,
      0,
      null,
      null,
      null,
      imported,
      location,
      user,
      false,
      updatePaymentHistory,
    );
  }

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
