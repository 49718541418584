import React from "react";
import useFirestore from "./useFirestore";
import { useIsMounted } from "usehooks-ts";

type Order = { selectedDate: string; importedBy?: string; owner: string };

/**
 * useCommunityOrder gets an order document matching the `selectedDate`.
 *
 * If multiple distribution locations return an Order, the first Order WITHOUT an `importedBy` field will be returned.
 * @param _selectedDate
 * @returns Order
 */
export default function useCommunityOrder(_selectedDate: string) {
  const mounted = useIsMounted();
  const database = useFirestore();
  const [order, setOrder] = React.useState<Order | undefined>();
  const [initialized, setInitialized] = React.useState(false);

  const fetchOrder = React.useCallback(async (date: string) => {
    const ref = database
      .collectionGroup("Orders")
      .where("selectedDate", "==", date);

    try {
      const snap = await ref.get();
      if (!mounted()) return;

      // set the owner to be the parent document id
      // orders before 2021-06-24 did not have the owner field
      const orders = snap.docs.map((v) => ({
        owner: v.ref.parent.parent?.id,
        ...v.data(),
      })) as Order[];
      const found = orders.find((o) => o.importedBy == null);
      setOrder(found);
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (!mounted()) return;
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    fetchOrder(_selectedDate);
  }, [_selectedDate]);

  return { order, initialized };
}
