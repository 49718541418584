// This component takes the user's inputs for the deposit.  It asks for the payment
// method, the amount and the date deposited.
// Inputs: deposit which is a dictionary of the method, amount and of the deposit.
// Index is the index of this specific payment in the array. handleDepositChange
// and handleRemoveDeposit are functions that add and remove a specific payment
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MaterialUIPickers from "../../../components/userInputs/DatePicker.js";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import SyncDisabledRoundedIcon from "@material-ui/icons/SyncDisabledRounded";

export default function PaymentEntry({
  deposit,
  index,
  handleDepositChange,
  handleRemoveDeposit,
  syncDeposits,
}) {
  // The method of payment for the user. Either Etransfer or cash.
  const method = deposit.method;
  // The amount the user deposited.
  const amount = deposit.amount;
  // The date that the user deposited.
  const date = deposit.date;

  return (
    <>
      <div className="Payment-Number-Removal">
        <h5>
          <u>Payment {index + 1}</u>
          {syncDeposits[index] ? (
            <SyncRoundedIcon style={{ fill: "green" }} />
          ) : (
            <SyncDisabledRoundedIcon style={{ fill: "red" }} />
          )}
        </h5>
        {index !== 0 && (
          <IconButton onClick={() => handleRemoveDeposit(index)}>
            <RemoveCircleOutlineOutlinedIcon
              fontSize="large"
              style={{ color: "red" }}
            />
          </IconButton>
        )}
      </div>
      <FormLabel component="legend">Payment Method: </FormLabel>
      <RadioGroup
        aria-label="payment method"
        name="paymentMethod"
        value={method}
        onChange={(e) => handleDepositChange(e, index, true)}
      >
        <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
        <FormControlLabel
          value="ETransfer"
          control={<Radio />}
          label="ETransfer"
        />
      </RadioGroup>

      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
        <OutlinedInput
          required
          id="outlined-adornment-amount"
          value={amount}
          onChange={(e) => handleDepositChange(e, index)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Amount"
          type="number"
        />
      </FormControl>

      <MaterialUIPickers
        required
        date={date}
        handleDateChange={(e) => handleDepositChange(e, index, false, true)}
        label="Distribution Date"
      />
    </>
  );
}
