// This is used to display the current user's community location on the MyAccount
// Page under the Community -> My Community Location tab.  The accordian will display
// either their community host information, if it is pending or if the user can apply
// to become a location
import React, { useContext } from "react";
import { AuthContext } from "../../../../components/authentication/Auth.js";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../../../components/Firebase.js";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommunityLocationEditDialog from "../../Dialogs/CommunityLocationEditDialog.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
// This renders the accordian
export default function CommunityLocationAccordian({ communityLocation }) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { userInfo, currentUser, handleUserInfoChange } =
    useContext(AuthContext);

  let switchLabel = "Community hub is open";
  // If the community location is hidden then we want to change the message
  if (communityLocation.hidden) {
    switchLabel = "Community hub is unavailable.";
  }

  // This switch will hide or unhide a community location from the community hubs
  // If it is hidden then users will default back to the distribution location.  The
  // switch acts as false is on and true is off as it's saying the location is hidden
  // true or false.  As this may be counter intuitive to the user we reversed it for the
  // switch itself and how it is displayed.
  const handleSwitch = () => {
    const database = firebase.firestore();
    const batch = database.batch();
    // Update the database.
    const documentName = (
      communityLocation.firstName +
      communityLocation.lastName +
      firebase.auth().currentUser.uid
    ).replace(/\s+/g, "");
    const docRef = database.collection("CommunityLocations").doc(documentName);
    // Create a temporary variable.
    const communityLocationTemp = { ...communityLocation };
    // Check if the location has a hidden field and if not then create and set it
    // to true.  Otherwise just make it the opposite of whatever it is.
    if (!communityLocation.hidden) {
      communityLocationTemp.hidden = true;
    } else {
      communityLocationTemp.hidden = !communityLocation.hidden;
    }

    batch.update(docRef, communityLocationTemp);

    batch.commit();

    // Updates the userInfo to include the new changes.
    const userInfoTemp = { ...userInfo };
    // Add the new Community location to the userInfo.
    userInfoTemp.communityLocation = communityLocationTemp;
    handleUserInfoChange({ ...userInfoTemp }, false, true);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{communityLocation.locationName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {userInfo.communityLocation.approved ? (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              {communityLocation.approved ? (
                <>
                  <Button disabled>APPROVED</Button>
                  <CommunityLocationEditDialog
                    communityLocation={communityLocation}
                  />
                </>
              ) : (
                <Button disabled>PENDING</Button>
              )}
              <Typography variant="h5" component="h2">
                {communityLocation.firstName} {communityLocation.lastName}
              </Typography>

              <FormControlLabel
                label={switchLabel}
                control={
                  <Switch
                    checked={!communityLocation.hidden}
                    onChange={handleSwitch}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
              <Typography>
                <strong>Partner Location: </strong>
                {communityLocation.distributionLocationName}
              </Typography>
              <Typography>
                <strong>Secret Code: </strong>
                {communityLocation.secretCode}
              </Typography>
              <Typography>
                <strong>Address: </strong>
                {communityLocation.address}
              </Typography>
              <Typography>
                <strong>Close to: </strong>
                {communityLocation.obscuredAddress}
              </Typography>
              <Typography>
                <strong>Pickup Time: </strong>
                {communityLocation.availableTimes}
              </Typography>
              <Typography>
                <strong>Maximum Deliveries: </strong>
                {communityLocation.maxParticipants}
              </Typography>
              <Typography>
                <strong>Additional Comments: </strong>
                {communityLocation.additionalComments}
              </Typography>
              {communityLocation.approved && (
                <>
                  <Typography>
                    <strong>Pickup Day: </strong>
                    {communityLocation.pickupDay}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        ) : (
          <div>
            <Button size="small" variant="outlined" disabled>
              COMMUNITY DISTRIBUTION APPLICATION PENDING PLEASE CHECK YOUR EMAIL
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
