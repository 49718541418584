// Create the drop off messages for each user that includes the order for each user.
// Receives a custom message to start and then enters the remaining part of the email
// with their order.
export default function OrderDropOffMessage(
  message,
  communityHubUsers,
  checkList,
  setUserDropOffMessages,
  baseDeliveryFee,
) {
  // Add the delivery fee per location.
  const deliveryFeesByLocation = {};

  // The userEmailMessages that will be unique for each user that is being emailed.
  // {firstName-lastName-userId : "message + order"}
  const userEmailMessages = {};
  // Cycle through the community hubs.  To add up the order amounts and put together
  // the order string. ex. communityHubUsers = {Debbie's Location : {}, Barbara location : {}...}
  Object.keys(communityHubUsers).forEach((communityLocation) => {
    // Calculate the number of users picking up from this hub to calculate the
    // delivery fee per user.
    const numberOfUsers = Object.keys(
      communityHubUsers[communityLocation],
    ).length;
    // As there may be multiple delivery locations we want to calculate the fee for each
    // spot. We calculate the fee from teh base fee divided by all the users.
    deliveryFeesByLocation[communityLocation] = baseDeliveryFee / numberOfUsers;
    // Cycle through each user that exists at this location.
    Object.keys(communityHubUsers[communityLocation]).forEach((user) => {
      // Check to see if this user was checked to be emailed.
      if (checkList[user]) {
        // The community Pickup details.
        const communityPickup =
          communityHubUsers[communityLocation][user].communityPickup;
        // The distribution location details of this order.
        const pickupLocation = communityPickup.pickupLocation;
        // The etransfer message with the question and answer.
        let etransferMessage =
          " with the question: farmtoplate and answer: marketplace.";
        // The etranfer message when it is auto deposit so no question and answer is needed.
        if (pickupLocation.etransferType === "Auto-Deposit") {
          etransferMessage = ".";
        }
        // This is the cost of the food itself.
        let orderTotal = 0;
        // The stringified version of order
        let orderString = "";
        // The package fees in total for the order.
        let packageFeeTotal = 0;
        // The package fees owed for the order in string format to be put in the email.
        let packageFeeString = "";
        // The delivery fees owed for the order in string format to be put in the email.
        let deliveryFeeString = "";
        // The volunteer Fees owed for the order in string format to be put in the email.
        let volunteerFeeString = "";

        // Individual orders should include which farmers are selling which products so we
        // create the list to be a dictionary with farm keys and the food as an array.
        const individalFarmOrders = {};

        // Cycle through each foodList item and add these items to a dictionary
        // sorted by farm.  That way we have the items sorted by farm.
        // and add the items to the message.
        communityHubUsers[communityLocation][user].foodList.forEach(
          (orderItem) => {
            if (individalFarmOrders[orderItem.farmName]) {
              individalFarmOrders[orderItem.farmName].push(orderItem);
            } else {
              individalFarmOrders[orderItem.farmName] = [orderItem];
            }
          },
        );

        // Cycle through the farms that are providing this order.
        Object.keys(individalFarmOrders).forEach((farmName) => {
          // Add the name of the farm.
          orderString = orderString.concat(
            '<span style="font-size: 14px;"><u><b>',
            farmName.toString(),
            ":</b></u></span><br />",
          );
          // Cycle through the items from the farm.
          individalFarmOrders[farmName].forEach((orderItem) => {
            // Set the price of the individal item
            let individualPrice = parseFloat(
              orderItem.price /
                (orderItem.distributionQuantity / orderItem.individualQuantity),
            );
            // The individual description of the food item for the user to know how
            // much they are purchasing.
            let individualDescription = orderItem.individualDescription;

            // if suggestedPrice is true then we want to set the price to 0
            if (orderItem.suggestedPrice) {
              // First add to the description the suggested Donation.
              // eslint-disable-next-line no-unused-vars
              individualDescription = individualDescription.concat(
                " - Suggested Donation of $",
                individualPrice,
              );
              // Then reset the prices to 0.
              individualPrice = 0.0;
            }

            // If there is a package fee then add it to the item price and add it to the
            // the total package fee.
            if (orderItem.packageFee > 0) {
              individualPrice += parseFloat(orderItem.packageFee);
              packageFeeTotal +=
                parseFloat(orderItem.packageFee) *
                parseFloat(orderItem.quantity);
            }
            // Set the price of the individal item multiplied by the amount of that item.
            const individualPriceTotal = parseFloat(
              individualPrice * orderItem.quantity,
            ).toFixed(2);
            // Add this item to the order string for them email the order item.
            orderString = orderString.concat(
              "• ",
              orderItem.item.toString(),
              " ",
              orderItem.individualDescription.toString(),
              " ",
              " x",
              orderItem.quantity.toString(),
              " -- $",
              individualPrice.toFixed(2),
              " ($",
              individualPriceTotal.toString(),
              ")",
            );
            // If there is a package fee then add it to the string to let the user know.
            if (orderItem.packageFee > 0) {
              orderString = orderString.concat(
                " *includes pkg fee of ",
                parseFloat(orderItem.packageFee).toFixed(2),
                "/per item.",
              );
            }
            orderString = orderString.concat("<br />");
            // Add to the cost total of the order.
            orderTotal += parseFloat(individualPrice * orderItem.quantity);
          });
        });

        // If the packageFee is greater than 0 then we want to print the total fees.
        if (packageFeeTotal > 0) {
          packageFeeString = packageFeeString.concat(
            "Included Package Fees : $",
            packageFeeTotal.toFixed(2),
            "<br />",
          );
        }

        // This the donation or subsidy amount.
        let donationSubsidy =
          communityHubUsers[communityLocation][user].donationSubsidy;
        // This is a string that says "donation" or "subsidy" depending on the order
        const customerContribution =
          communityHubUsers[communityLocation][user].customerContribution;

        // This is a string that is included in the email that says subsidy or donation
        let donationSubsidyString = "- Subsidy Amount : $";

        // This the order total after the donation or subsidy have been added in
        let grandTotal = orderTotal;

        // Check to see if the delivery fee is greater than 0 and then add it
        // to the grandTotal and update the string.
        if (deliveryFeesByLocation[communityLocation] > 0) {
          deliveryFeeString = deliveryFeeString.concat(
            "+ Delivery Fee : $",
            deliveryFeesByLocation[communityLocation].toFixed(2),
            "<br />",
          );
          grandTotal += deliveryFeesByLocation[communityLocation];
        }
        // If the volunteer cash portion is greater than 0 then we want to print the total fees
        // and add it to the grandtotal.
        if (communityHubUsers[communityLocation][user].cashPortion > 0) {
          volunteerFeeString = volunteerFeeString.concat(
            "+ Participation Fee Cash : $",
            communityHubUsers[communityLocation][user].cashPortion,
            "<br />",
          );
          grandTotal += parseFloat(
            communityHubUsers[communityLocation][user].cashPortion,
          );
        }
        // If the volunteer credit portion is greater than 0 then we want to print
        // that but don't need to add it to the total.
        if (communityHubUsers[communityLocation][user].creditPortion > 0) {
          volunteerFeeString = volunteerFeeString.concat(
            "Participation Fee Credits : ",
            communityHubUsers[communityLocation][user].creditPortion,
            " credits<br />",
          );
        }

        // If the user gave a donation calculate the ordertotal by subtracting the donation
        // amount from the grandTotal this is the cost of the order.  If it is a subsidy
        // then add the subsidy amount to the grandTotal to get the cost of the order.
        // If this is a donation change the donationSubsidyString to say "Donation Amount : "
        if (customerContribution === "donation") {
          grandTotal += donationSubsidy;
          donationSubsidyString = "+ Donation Amount : $";
        } else {
          grandTotal -= donationSubsidy;
          // If the grandTotal is less than 0 then we don't want the subsidy to
          // be more than the total amount so we will deduct from the total subsidy and
          // set the grandTotal to 0.
          if (grandTotal < 0) {
            donationSubsidy += grandTotal;
            // We just want to make sure that the subsidy doesn't go negative
            // if there was some strange error.
            if (donationSubsidy < 0) {
              donationSubsidy = 0;
            }
            grandTotal = 0;
          }
        }

        // Create a temporary message that includes the user's order.
        let messageTemp =
          `<p style="font-size: 16px;">
                  <u>` +
          communityPickup.locationName +
          "</u><br />" +
          "&nbsp;- Address : " +
          communityPickup.address +
          "<br /> &nbsp;- Pickup Day : " +
          communityPickup.pickupDay +
          "<br /> &nbsp;- Pickup Time : " +
          communityPickup.availableTimes +
          "<br /> &nbsp;- Additional Info : <i>" +
          communityPickup.additionalComments +
          `</i><br /> </p> <p style="font-size: 16px;">Your final order was as follows:</p>
                  <p style="font-size: 22px;">` +
          orderString +
          `</p>
                  <p style="font-size: 16px;">
                    <i style="font-size: 14px;">
                      ` +
          packageFeeString +
          volunteerFeeString +
          deliveryFeeString +
          `
                      + Food Cost : $` +
          orderTotal.toFixed(2) +
          `
                      <br />
                      ` +
          donationSubsidyString +
          donationSubsidy.toFixed(2) +
          `
                    </i>
                    <br />
                    Total Cost : $` +
          grandTotal.toFixed(2) +
          `
                    <br />
                    <br />
                    Next Steps
                    <br />
                    1. Bring your own bag to pick up your food.
                    <br />
                    2. Pick up food from ` +
          communityPickup.address +
          `.
                    <br />
                    3. Payment is by e-transfer sent to ` +
          pickupLocation.etransferEmail +
          etransferMessage +
          `<br />
                    <br />
                    Please reply to this email for any feedback, questions or concerns you may have. You can also reach Farm-to-Plate Marketplace by phone at 778-896-6754.
                    <br />
                    <br />
                    Thank you for supporting your community and local farmers,
                    <br />
                    Farm-to-Plate Marketplace </p>`; // email content in HTML

        // Add the original custom message to the beginning of the order message.
        messageTemp = message.concat(messageTemp);

        // Add the user with their attached message in a dictionary.
        userEmailMessages[user] = messageTemp;
      }
    });
  });
  // Set the hook with the user email messages.
  setUserDropOffMessages(userEmailMessages);
}
