const urls = {
  myAccount: "/MyAccount",
  deliveryMap: "/DeliveryMap",
  marketPlace: "/Marketplace",
  farms: "/Farms",
  aboutUs: "/AboutUs",
  howItWorks: "/HowItWorks",
  signIn: "/SignIn",
  signUp: "/SignUp",
  communityEngagement: "/CommunityEngagement",
};

export default urls;
