// This actually isn't a dialog but acts as a state machine that determines which
// state the user is while removing an item.  There are 2 states.  The first one where
// the user determines if they want to update the customer orders and if they want
// to send emails to those users.  The second state reviews the affected users and
// selects which users to send an email to.
import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import LoadingEmailsDialog from "../../../../components/dialogs/Components/SendUserEmails/LoadingEmailsDialog.jsx";
import SendingEmailsDialog from "../../../../components/dialogs/Components/SendUserEmails/SendingEmailsDialog.jsx";
import ReviewUserUpdatesEmailDialog from "../../../../components/dialogs/Components/SendUserEmails/RemovedItemEmail/ReviewUserUpdatesEmailDialog.jsx";
import UsersSelectDialog from "./UsersSelectDialog.jsx";
import MultipleFoodItemRemoveDialog from "./MultipleFoodItemRemoveDialog.jsx";
import "date-fns";
import HandleEmailAndOrderRemoval from "../../../../components/dialogs/Components/SendUserEmails/RemovedItemEmail/HandleEmailAndOrderRemoval.js";
import { ChangeOrderLogContext } from "../../../MyAccount.js";
import CreateListOfAffectedOrders from "../../Functions/RemoveFoodItems/CreateListOfAffectedOrders.js";
import CreateListOfForReview from "../../Functions/RemoveFoodItems/CreateListOfForReview.js";

export default function UpdateUserOrdersDialog({
  userInfo,
  selectedItems,
  setSelectedItems,
  selectedPantryItems,
  setSelectedPantryItems,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  setMultipleSelection,
}) {
  // This is to say that the first dialog in 1/3 states is open and in process
  const [openSelectAction, setOpenSelectAction] = useState(false);
  // This is to say that the first dialog in 2/3 states is open and in process
  const [openUsersSelectDialog, setOpenUsersSelectDialog] = useState(false);
  // This is to say that the first dialog in 3/3 states is open and in process
  const [
    openReviewUserUpdatesEmailDialog,
    setOpenReviewUserUpdatesEmailDialog,
  ] = useState(false);
  // This is the dictionary that contains the user's location, the user and their email
  // {location : {firstName-lastName-userId : email}}
  const [userEmails, setUserEmails] = useState({});
  // This sets what state in the 2 state process the user is in.
  const [stateOfDialog, setStateOfDialog] = useState("selectAction");
  // The checkList of customers that the user has selected to message. {firstName-lastName-userId : true}
  const [checkList, setCheckList] = useState({});
  // The list of foodItems that are being removed and the customers that have them {foodList : {indexofCurrentItems :
  //                                                                                             {fullRemovalFlag : bool,
  //                                                                                               ordersToChange : [],
  //                                                                                               detailedOrders :
  //                                                                                                 {checked : bool, quantity : num}}}
  //                                                                                pantryFoodList : {same as foodList}}
  const [foodUserRemovalList, setFoodUserRemovalList] = useState({});
  // Which greeting message the user has selected from the radio buttons in 2nd state.
  const [greeting, setGreeting] = useState("Hi");
  // Flag set to determine if the distribution location wants to remove the item
  // from communityOrders as well.
  const [updateOrders, setUpdateOrders] = useState(true);
  // This is set to true while the program loads the customers' emails and set a
  // progress dialog.
  const [loadingEmails, setLoadingEmails] = useState(false);
  // This is set to true while the program sends the customers' emails and set a
  // progress dialog.
  const [sendingEmails, setSendingEmails] = useState(false);

  // A dictionary of all the users with an array of each foodItem removed.
  const [usersWithFoodItemsRemoved, setUsersWithFoodItemsRemoved] = useState(
    {},
  );

  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { blank, blank2, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  useEffect(() => {
    // This will create the affected users list to start with the items that have
    // been selected.
    CreateListOfAffectedOrders(
      selectedItems,
      selectedPantryItems,
      order,
      setFoodUserRemovalList,
    );
  }, [selectedItems, selectedPantryItems]);

  // This useEffect activates when the state changes and if it is in review. It will load
  // which users orders have been affected along with their emails.
  useEffect(() => {
    // If the state is UsersSelect then we will want to create our list of affectedOrders.
    if (stateOfDialog === "UsersSelect") {
      CreateListOfAffectedOrders(
        selectedItems,
        selectedPantryItems,
        order,
        setFoodUserRemovalList,
      );
    }
    // If the state is in the second state.
    else if (stateOfDialog === "Review") {
      CreateListOfForReview(
        order,
        setUsersWithFoodItemsRemoved,
        setCheckList,
        setLoadingEmails,
        setUserEmails,
        foodUserRemovalList,
        setFoodUserRemovalList,
      );
    }
  }, [stateOfDialog]);

  // This use effect checks the state of the user and makes sure that the correct
  // dialogs are being displayed.
  useEffect(() => {
    // 2/3 State
    if (stateOfDialog === "UsersSelect") {
      setOpenUsersSelectDialog(true);
      setOpenReviewUserUpdatesEmailDialog(false);
      setOpenSelectAction(false);
    } // 3/3 State
    else if (stateOfDialog === "Review") {
      setOpenUsersSelectDialog(false);
      setOpenReviewUserUpdatesEmailDialog(true);
      setOpenSelectAction(false);
    }
    // No state and all the dialogs closed
    else {
      setOpenUsersSelectDialog(false);
      setOpenReviewUserUpdatesEmailDialog(false);
      setOpenSelectAction(false);
    }
  }, [stateOfDialog]);

  // If the user opens the first dialog and is in state 1/2
  const handleClickOpen = () => {
    setOpenSelectAction(true);
  };

  const handleEmailAndOrderRemoval = () => {
    HandleEmailAndOrderRemoval(
      setSelectedItems,
      setSelectedPantryItems,
      order,
      userInfo,
      orderLog,
      updateOrderLog,
      updateOrders,
      orderIndex,
      userEmails,
      checkList,
      setSendingEmails,
      greeting,
      setOpenReviewUserUpdatesEmailDialog,
      setMultipleSelection,
      setStateOfDialog,
      usersWithFoodItemsRemoved,
      updatePaymentHistory,
      foodUserRemovalList,
    );
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        DELETE SELECTED ITEMS
      </Button>

      <MultipleFoodItemRemoveDialog
        openSelectAction={openSelectAction}
        setOpenSelectAction={setOpenSelectAction}
        setStateOfDialog={setStateOfDialog}
        updateOrders={updateOrders}
        setUpdateOrders={setUpdateOrders}
        handleEmailAndOrderRemoval={handleEmailAndOrderRemoval}
      />
      <UsersSelectDialog
        userEmails={userEmails}
        openUsersSelectDialog={openUsersSelectDialog}
        setOpenUsersSelectDialog={setOpenUsersSelectDialog}
        setStateOfDialog={setStateOfDialog}
        order={order}
        foodUserRemovalList={foodUserRemovalList}
        setFoodUserRemovalList={setFoodUserRemovalList}
      />

      <ReviewUserUpdatesEmailDialog
        userEmails={userEmails}
        openReviewUserUpdatesEmailDialog={openReviewUserUpdatesEmailDialog}
        setOpenReviewUserUpdatesEmailDialog={
          setOpenReviewUserUpdatesEmailDialog
        }
        setStateOfDialog={setStateOfDialog}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        handleEmailAndOrderRemoval={handleEmailAndOrderRemoval}
      />

      <LoadingEmailsDialog open={loadingEmails} />
      <SendingEmailsDialog open={sendingEmails} />
    </div>
  );
}
