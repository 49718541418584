// This is old code that was used before the imported orders was changed and multiple
// orders can be imported.  June 17 2021.

// Takes in the parameters for currentOrder and then checks it against the database
// in th DistributionLocations -> {Location} -> CommunityOrders -> selectedDate/importedDistributionDate
// to see whether there are new orders to be added.  It simple sets the hooks that are sent
// in through the parameters.
import firebase from "./../components/Firebase.js";

// This checks the current orders and the loadedOrders again each other.  If there
// are any orders that don't exist then they are added to the new Orders dictionary
// this is then returned
function CheckForNewOrders(currentOrders, loadedOrders) {
  const newOrders = {};
  Object.keys(loadedOrders).forEach((order) => {
    if (!Object.keys(currentOrders).includes(order)) {
      newOrders[order] = loadedOrders[order];
    }
  });

  return newOrders;
}

export default function CheckCustomerUpdateOld(
  order,
  currentOrders,
  selectedDate,
  userInfo,
  imported,
  setCheckingUpdate,
  setNewOrders,
) {
  const database = firebase.firestore();

  // The communityOrders that will be loaded into the database
  let communityOrders = [];

  // This is to give the distribution location's name. The default is the
  // userInfo.organisationName but will be changed if it is imported
  let distributionLocationName = userInfo.organisationName;
  // This is to give the date of the distribution.   The default is the
  // selectedDate but will be changed if it is imported
  let distributionDate = selectedDate;

  // If the order is updated update the name and date
  if (imported) {
    distributionLocationName = order.importedDistributionLocation;
    distributionDate = order.importedDistributionDate;
  }

  const distributionLocationDocRef = database
    .collection("DistributionLocations")
    .doc(distributionLocationName)
    .collection("CommunityOrders")
    .doc(distributionDate);
  distributionLocationDocRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Sets the communityOrders with the most up to date information
        communityOrders = doc.data();

        // Sends the current and loaded orders to be checked if they are different.
        // Then sets newOrders to whatever this dictionary is.  IF there are no new
        // orders than it will just be an empty dictionary.
        setNewOrders(CheckForNewOrders(currentOrders, communityOrders));
        // Checking update has completed.
        setCheckingUpdate(true);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setCheckingUpdate(true);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
