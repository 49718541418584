// This is 3/3 dialogs used by distribution locations to send their customers a
// order drop off email.   It displays the custom email message, the users and the greeting and
// asks the distribution location to confirm before sending out the emails.  It will
// then attach the order to each email.
import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import firebase from "../../../../components/Firebase.js";
import OrderDropOffMessage from "./functions/OrderDropOffMessage.js";
import FinalizeDeliveryFees from "./functions/FinalizeDeliveryFees.js";
import { ChangeOrderLogContext } from "../../../../pages/MyAccount.js";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";

// This function is used to send in to the email batcher to help send off
// the emails.
export async function OrderDropOffEmails(item) {
  const [
    userEmailsNoLocation,
    user,
    setSendingEmails,
    greeting,
    userDropOffMessages,
    setOpenConfirmOrderDropOffMessageDialog,
    setCheckList,
    setStateOfDialog,
  ] = item;

  try {
    // Sets the email cloud function
    const customMessageToUsers = firebase
      .functions()
      .httpsCallable("customMessageToUsers");

    // Sets the destination of the email to the user's email.
    const dest = [userEmailsNoLocation[user]];
    // Sets this to true to display the loading dialog while emails are sent.
    setSendingEmails(true);

    // Calls the cloud function.
    customMessageToUsers({
      dest,
      subject: "Order Has Been Dropped Off",
      greeting,
      firstName: user.split("-")[0],
      customMessage: userDropOffMessages[user],
    });
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    console.error("There was an error when calling the Cloud Function", error);
    window.alert(
      "There was an error when calling the Cloud Function:\n\nError Code: " +
        code +
        "\nError Message:" +
        message,
    );
    setOpenConfirmOrderDropOffMessageDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
    setSendingEmails(false);
  }
}

export default function ConfirmOrderDropOffMessageDialog({
  openConfirmOrderDropOffMessageDialog,
  setOpenConfirmOrderDropOffMessageDialog,
  stateOfDialog,
  setStateOfDialog,
  userEmails,
  checkList,
  setCheckList,
  greeting,
  message,
  userInfo,
  setSendingEmails,
  communityHubUsers,
  order,
  sendEmails,
}) {
  // This hook will store the user and their custom message {firstName-lastName-userId : customMessage + order}
  const [userDropOffMessages, setUserDropOffMessages] = useState({});

  // This contains the updatePaymentHistory so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  // This is the base delivery fee that is charged for delivery.  The actual delivery
  // fee charged to each user is the base amount divided amongst the users.
  const baseDeliveryFee = userInfo.baseDeliveryFee;

  // If the user hits the back button then it will clear their checkList and set the
  // the current state (value) to "Custom" where they will write their message and
  // choose who to email.
  const handleBack = (value) => {
    setOpenConfirmOrderDropOffMessageDialog(false);
    setStateOfDialog(value);
    setCheckList({});
  };
  // If the user close the dialog then reset the state of this dialog series to
  // "selectAction" and clear the checkList.
  const handleClose = () => {
    setOpenConfirmOrderDropOffMessageDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
  };

  // Since we have the imported orders the userEmails is a dictionary like this
  // {location : {user} : email}.  We don't need the location on this dialog so this
  // is a dictionary with just the {user : email}
  const userEmailsNoLocation = {};

  // Cycle through the userEmails to remove the locations and just have the {user : email}
  Object.keys(userEmails).forEach((location) => {
    Object.keys(userEmails[location]).forEach((user) => {
      userEmailsNoLocation[user] = userEmails[location][user];
    });
  });

  // If the user confirms they wish to send the emails.
  const handleConfirm = async () => {
    // Creates the batch of emails to send off.
    const emailBatch = [];

    // Create an object of the userDropOffMessages to cycle through.
    const users = Object.keys(userDropOffMessages);

    // If the user wants to send the users emails we will do that otherwise we will just finalize
    // the delivery fees.
    if (sendEmails) {
      // Cycle through the user's with a drop off message and all the
      // parameters for each email. Push them to an array to send off the emails.
      for (const user of users) {
        emailBatch.push([
          userEmailsNoLocation,
          user,
          setSendingEmails,
          greeting,
          userDropOffMessages,
          setOpenConfirmOrderDropOffMessageDialog,
          setCheckList,
          setStateOfDialog,
        ]);
      }
      // Call the batch email function that will handle the delays necessary
      // to sending the emails off without overloading the send mail function.
      await batchEmails({
        batchedParamsForEmailFunc: emailBatch,
        singularEmailFunc: OrderDropOffEmails,
      });

      // Close the dialog
      setOpenConfirmOrderDropOffMessageDialog(false);
      // Reset the checklist.
      setCheckList({});
      // reset the state.
      setStateOfDialog("selectAction");
      // Close the sending emails progress dialog
      setSendingEmails(false);
      // Add the delivery fees to the users.
      FinalizeDeliveryFees(
        userInfo,
        communityHubUsers,
        checkList,
        order,
        updatePaymentHistory,
        baseDeliveryFee,
      );
    } else {
      // Close the dialog
      setOpenConfirmOrderDropOffMessageDialog(false);
      // Reset the checklist.
      setCheckList({});
      // reset the state.
      setStateOfDialog("selectAction");
      // Close the sending emails progress dialog
      setSendingEmails(false);
      // Add the delivery fees to the users.
      FinalizeDeliveryFees(
        userInfo,
        communityHubUsers,
        checkList,
        order,
        updatePaymentHistory,
        baseDeliveryFee,
      );
    }
  };

  // When the state of the dialog is set to ConfirmOrderDropOff then we will
  // create the new order drop off message with the custom message included.
  useEffect(() => {
    if (stateOfDialog === "ConfirmOrderDropOff") {
      OrderDropOffMessage(
        message,
        communityHubUsers,
        checkList,
        setUserDropOffMessages,
        baseDeliveryFee,
      );
    }
  }, [stateOfDialog]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openConfirmOrderDropOffMessageDialog}
      >
        <DialogTitle id="simple-dialog-title">Confirm Emails</DialogTitle>
        <DialogContent>
          <List>
            <h6>
              <u>Users being emailed</u>
            </h6>
            {Object.keys(checkList).map((user, i) => (
              <span key={i}>
                {checkList[user] && (
                  <ListItem autoFocus>
                    <ListItemText
                      primary={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmailsNoLocation[user],
                      ].join(" ")}
                    />
                  </ListItem>
                )}
              </span>
            ))}
          </List>

          <h6>
            <u>Custom Message</u>
          </h6>
          <p>
            {greeting} "First Name",
            <br />
            {message}
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleBack("OrderDropOff")}>
            BACK
          </Button>
          {sendEmails ? (
            <Button color="primary" onClick={() => handleConfirm()}>
              SEND
            </Button>
          ) : (
            <Button color="primary" onClick={() => handleConfirm()}>
              CONFIRM
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
