// The component on the individual basket page that includes the user's volunteer
// fee and options on how to pay for the fees with either cash or credits.
import React, { useEffect, useContext, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { NonFoodCostsContext } from "../../Basket.js";

export default function VolunteerFee({
  costTotal,
  pickupLocation,
  userInfo,
  setDonationSubsidy,
  customerContribution,
  suggestedFees,
}) {
  const { cashPortion, setCashPortion, creditPortion, setCreditPortion } =
    useContext(NonFoodCostsContext);

  // This is the dollar amount owed for the volunteer fee.  It can be paid using
  // cash or credits.
  const [volunteerDollarAmount, setVolunteerDollarAmount] = useState(0);

  // Set the user's balance to their credits.
  let creditsAvailable = userInfo.credits;
  // If this key doesn't exist then just set the user's credits to 0
  if (creditsAvailable === undefined) {
    creditsAvailable = 0;
  }

  // Calculates volunteer Fee and sets the cash and credits portions to their defaults.
  useEffect(() => {
    // Calculate the total volunteer fee.
    const volunteerDollarAmountTemp =
      ((costTotal + suggestedFees) * pickupLocation.volunteerPercentage) / 100;
    // set the cash portion to the total volunteer amount owed.
    setCashPortion(parseFloat(volunteerDollarAmountTemp).toFixed(2));
    // Set the credit portion to 0.00
    setCreditPortion(parseFloat(0).toFixed(2));
    // set the volunteer amount in the hook.
    setVolunteerDollarAmount(parseFloat(volunteerDollarAmountTemp));
  }, [costTotal, suggestedFees]);

  // If the user changes either the cash or credit portion we need to sync them up.
  const handleChange = (e) => {
    // The field they are modifying (cash/credit)
    const name = e.target.name;
    // The value of the modification.
    let value = e.target.value;

    // If the value they've entered is nothing then set the value to 0
    if (value === "") {
      value = 0;
    }

    // Turn the value into a float.
    value = parseFloat(value);

    // Check to see if the value the user entered is greater than the volunteer
    // amount owed or the less than 0.  If it is then do nothing as that is not a
    // valid input.
    if (value > volunteerDollarAmount || value < 0) {
      return null;
    }
    // Otherwise the input is valid and we need to calculate.
    else {
      // If the user is using a subsidy then we want to set their amount to 0
      // so we don't have to calculate if the subsidy amount is valid.
      if (customerContribution === "subsidy") {
        setDonationSubsidy(parseFloat(0));
      }
      // If the user is updating the cash portion
      if (name === "cashPortion") {
        // Check that whatever the difference between the cash and total owed is
        // that it's not greater than the total credits the user has available otherwise
        // this won't be valid payment.  We still will update the cash value as the user
        // may just be entering in a number and we don't want to prevent them from typing an
        // in between number.
        if (volunteerDollarAmount - value > creditsAvailable) {
          setCashPortion(parseFloat(value));
          return null;
        }
        // Otherwise the user has enough credits and so we can keep them in sync.
        else {
          setCashPortion(parseFloat(value));
          setCreditPortion(
            parseFloat(volunteerDollarAmount - value).toFixed(2),
          );
        }
      }
      // The user is updating the credit portion.
      else {
        // Makes sure the user isn't entering more credits then they have.
        if (value > creditsAvailable) {
          return null;
        }
        // Update the cash portion and credit portion to keep them in sync.
        else {
          setCashPortion(parseFloat(volunteerDollarAmount - value).toFixed(2));
          setCreditPortion(parseFloat(value));
        }
      }
    }
  };

  // After the user has clicked away from the text field we want to update the values
  // to be fixed to two decimals and make sure that the cash portion didn't go too low that
  // the user doesn't have enough credits to cover their fee.  This is only called by the
  // cash portion being clicked away as the credit portion is simpler and just updates.
  const handleCashBlur = (e) => {
    // The value of current cash amount.
    const value = parseFloat(e.target.value);

    // If the difference between total owed and the cash amount is greater than
    // the available credits the user has then we have to set the cash portion to minimum
    // the cash can be and set the credit portion to the max credits available.
    if (volunteerDollarAmount - value > creditsAvailable) {
      // The minimum cash is total owed minus the available credits.
      const minCash = volunteerDollarAmount - creditsAvailable;
      // Set the cash portion.
      setCashPortion(parseFloat(minCash).toFixed(2));
      // Set the credit portion to all availble credits.
      setCreditPortion(parseFloat(creditsAvailable).toFixed(2));
    }
    // Otherwise the cash portion is fine and we just need to make it have 2 decimals.
    else {
      setCashPortion(parseFloat(e.target.value).toFixed(2));
    }
  };

  // If there is a valid volunteer percentage.
  if (pickupLocation.volunteerPercentage !== undefined) {
    return (
      <div className="Volunteer-Fee-Box">
        <p className="Total-Fees">
          {" "}
          Participation Fee {pickupLocation.volunteerPercentage}% : $
          {volunteerDollarAmount.toFixed(2)}{" "}
        </p>
        How do you want to pay for the participation fee? <br />
        <b>
          Your Credit Balance : {parseFloat(creditsAvailable).toFixed(2)}{" "}
          credits
        </b>
        <br />
        <TextField
          margin="dense"
          label="Cash Portion $"
          name="cashPortion"
          value={cashPortion}
          onChange={(e) => handleChange(e)}
          type="number"
          inputProps={{
            step: "0.1",
          }}
          onBlur={(e) => handleCashBlur(e)}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Credit Portion"
          name="creditPortion"
          value={creditPortion}
          onChange={(e) => handleChange(e)}
          type="number"
          inputProps={{
            step: "0.1",
          }}
          onBlur={() => setCreditPortion(parseFloat(creditPortion).toFixed(2))}
          fullWidth
          required
        />
      </div>
    );
  } else {
    return null;
  }
}
