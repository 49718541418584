// This is the tab on the MyAccount Page for the distribution Location user.  It displays
// the current community hosts that are associated with this location.  It also displays
// the users that have applied to view this distribution location's community hosts.
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import PersonalInfoForm from "../../../components/userForms/PersonalInfoForm.js";
import firebase from "../../../components/Firebase.js";
import CommunityLocationCard from "../Components/CommunityLocationCard.jsx";
// eslint-disable-next-line no-unused-vars
import Tabs from "../../../components/Tabs.js";

export default function CommunityDistributionLocation({ userInfo }) {
  // Community locations of this distribution location to display.
  const [communityLocations, setCommunityLocations] = useState([]);
  // Pending community members for this location.
  // eslint-disable-next-line no-unused-vars
  const [pendingCommunityMembers, setPendingCommunityMembers] = useState([]);
  // Community memebers that have been accepted.
  // eslint-disable-next-line no-unused-vars
  const [acceptedCommunityMembers, setAcceptedCommunityMembers] = useState([]);

  const database = firebase.firestore();

  useEffect(() => {
    // This is used to grab the data of foodItems from the database.  It only grabs
    // the foodItems that the user has in their marketplace.
    const dataTransfer = [];
    // Read the database for all the community locations that exist for this distribution location.
    const docRef = database
      .collection("CommunityLocations")
      .where(
        "distributionLocationuserId",
        "==",
        firebase.auth().currentUser.uid,
      );
    docRef.get().then((collection) => {
      collection.docs.forEach((doc) => {
        // Add these community locations an array.
        dataTransfer.push(doc.data());
      });
      // Update the hook holding all the community locations.
      setCommunityLocations(dataTransfer);
    });

    // Load the pending community memebrs for this location
    const pendingDocRef = database
      .collection("PendingCommunityMembers")
      .doc(userInfo.organisationName);
    pendingDocRef
      .get()
      .then((doc2) => {
        if (doc2.exists) {
          // Set the hook of all the pending community members
          setPendingCommunityMembers(doc2.data());
        } else {
          // doc2.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    // Check the database for all the accepted community members
    const acceptedDocRef = database
      .collection("AcceptedCommunityMembers")
      .doc(userInfo.organisationName);
    acceptedDocRef
      .get()
      .then((doc3) => {
        if (doc3.exists) {
          setAcceptedCommunityMembers(doc3.data());
        } else {
          // doc2.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [database]);

  return (
    <div className="Tab-Box">
      <div label="Community Hosts">
        {communityLocations.map((communityLocation, i) => (
          <CommunityLocationCard
            key={i}
            index={i}
            communityLocation={communityLocation}
            communityLocations={communityLocations}
            setCommunityLocations={setCommunityLocations}
            userInfo={userInfo}
          />
        ))}
      </div>
    </div>
  );
}
