// This is old code that was used before the imported orders was changed and multiple
// orders can be imported.  June 17 2021.

// This is to add a new foodItem to a community order either imported or not from
// a request on the myAccount Page
import firebase from "./../Firebase.js";

// Index is the index of the item in the dictionary of orders,
// userOrder contains: customerContribution: "donation", farmDict: {}, farmerList: [{…}, {…}], foodDict: {}, foodList: [{…}, {…}], orderDate: 202012101607...
// user contains: firstName-lastName-userId-orderDate
// imported contains : boolean
// order contains : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// state contains : description: "1 Litre", distributionQuantity: "1", farmName: "Local Harvest", individualDescription: "1 Litre", individualQuantity: "1", item: "Milk", open: true, price: "4.99", quantity: "6", selectedDate: Thu Dec 10 2020 13:04:57 GMT-0800 (Pacific Standard Time) {}, farmName: "Local Harvest"
// userInfo contains: information about the current user
// orderLog contains: [array with each orderLoaded for the user]
// updateOrderLog contains: a function to update OrderLog
// orderIndex contains: the index of the current order in the orderLog array
export default function AddFoodItemCommunityOrdersOld(
  idx,
  userOrder,
  user,
  imported,
  order,
  state,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // This contains an array of foodItems that the user ordered
  let foodListTemp = [];

  // This contains an array of farmers that the user ordered from
  let farmerListTemp = [];

  // Assume the order is coming from the communityOrders this is used to set the
  // database name
  let userFoodList = "communityOrders";

  // This is to be used to update the farmerList if the farm doesn't exist already.
  // First assume that the communityOrders are being updated
  let userFarmerList = "communityOrders";

  // If the distribution location user is modifiying an imported user then...
  if (imported) {
    // pull the current foodList of the user being modified
    foodListTemp = [...order.importedOrder[user].foodList];

    // pull the current farmerList of the user being modified
    farmerListTemp = [...order.importedOrder[user].farmerList];

    // If the imported Orders are being modified change this to be correct
    userFoodList = "importedOrder";

    // If the imported Orders are being modified change this to be correct
    userFarmerList = "importedOrder";

    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(order.importedDistributionLocation)
      .collection("CommunityOrders")
      .doc(order.importedDistributionDate);
  } else {
    // pull the current foodList of the user being modified
    foodListTemp = [...order.communityOrders[user].foodList];

    // pull the current farmerList of the user being modified
    farmerListTemp = [...order.communityOrders[user].farmerList];

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  // This is used to update nested data of the foodList
  userFoodList = userFoodList.concat(".", user, ".", "foodList");

  // This is used to update nested data of the FarmerList
  userFarmerList = userFarmerList.concat(".", user, ".", "farmerList");

  const foodItem = {
    distributionQuantity: state.distributionQuantity,
    description: state.description,
    individualQuantity: state.individualQuantity,
    individualDescription: state.individualDescription,
    price: state.price,
    quantity: parseFloat(state.quantity),
    farmName: state.farmName,
    item: state.item,
    userId: firebase.auth().currentUser.uid,
    // This is hardcoded to match deprecated code
    userType: "admin",
  };

  // Push the foodItem to the list
  foodListTemp.push(foodItem);

  // Find if the farm exists already and if so the index
  const farmIndex = farmerListTemp.findIndex((farm) => {
    return farm.farmName === state.farmName;
  });

  // If the farm exists
  if (farmIndex !== -1) {
    // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
    // FirstName-LastName-userId-date
    batch.update(docRef, {
      [userFoodList]: [...foodListTemp],
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...foodListTemp],
    });

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...foodListTemp],
    });

    // This is to make sure the page rerenders these items correctly
    if (imported) {
      orderLogTemp[orderIndex].importedOrder[user].foodList = [...foodListTemp];
    } else {
      orderLogTemp[orderIndex].communityOrders[user].foodList = [
        ...foodListTemp,
      ];
    }
    batch.commit();
    // Updates the orderLog to make sure the screen shows the correct information
    updateOrderLog([...orderLogTemp]);
  }
  // If the farm doesn't exist
  else {
    // Find information about the farm from the database to add to the user's order
    const farmDocRef = database.collection("Farmers").doc(state.farmName);
    farmDocRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          farmerListTemp.push(doc.data());
          // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
          // FirstName-LastName-userId-date
          batch.update(docRef, {
            [userFoodList]: [...foodListTemp],
            [userFarmerList]: [...farmerListTemp],
          });
          // Updates the DistributionLocations -> nameofDistributionLocation ->
          // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
          batch.update(communityOrdersDocRef, {
            [user.concat(".foodList")]: [...foodListTemp],
            [user.concat(".farmerList")]: [...farmerListTemp],
          });

          // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
          // FirstName-LastName-userId-date
          batch.update(userDocRef, {
            foodList: [...foodListTemp],
            farmerList: [...farmerListTemp],
          });

          // This is to make sure the page rerenders these items correctly
          if (imported) {
            orderLogTemp[orderIndex].importedOrder[user].foodList = [
              ...foodListTemp,
            ];
            orderLogTemp[orderIndex].importedOrder[user].farmerList = [
              ...farmerListTemp,
            ];
          } else {
            orderLogTemp[orderIndex].communityOrders[user].foodList = [
              ...foodListTemp,
            ];
            orderLogTemp[orderIndex].communityOrders[user].farmerList = [
              ...farmerListTemp,
            ];
          }
          batch.commit();
          // Updates the orderLog to make sure the screen shows the correct information
          updateOrderLog([...orderLogTemp]);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }
}
