// Displays the user's receipts on the my account page for a distribution location.
// This is the information about all the community orders rendered in html.
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import RemoveCustomerOrderDialog from "../../../components/dialogs/RemoveCustomerOrderDialog.jsx";
import RemoveFoodItemCommunityOrders from "../../../components/ModifyOrders/RemoveFoodItemCommunityOrders.js";
import RemoveFoodItemCommunityOrdersOld from "../../../components/ModifyOrders/RemoveFoodItemCommunityOrdersOld.js";
import AddItemToOrderDialog from "../../../components/dialogs/AddItemToOrderDialog.jsx";
import SubsidyDonationChangeDialog from "../../../components/dialogs/SubsidyDonationChangeDialog.jsx";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import EditCommunityOrdersItemDialog from "../Dialogs/EditCommunityOrdersItemDialog.jsx";
import EnterCustomerPaymentDialog from "../Dialogs/EnterCustomerPaymentDialog.jsx";
import { ChangeOrderLogContext } from "../../MyAccount.js";
import CopyOrdersBetweenLocationsDialog from "../Dialogs/CopyOrdersBetweenLocationsDialog.jsx";
import VolunteerFeeChangeDialog from "../../../components/dialogs/VolunteerFeeChangeDialog.jsx";
import DeliveryFeeChangeDialog from "../../../components/dialogs/DeliveryFeeChangeDialog.jsx";
import CommunityMemberInfoDialog from "../Dialogs/CommunityMemberInfoDialog.jsx";

// This is used to print on the webpage the user receipts.  This is exactly the
// same as the orderString but is in html and can be read by the browser instead
// of the docx file.
export default function UserReceiptMyAccount({
  idx,
  userOrder,
  user,
  imported,
  order,
  orderIndex,
  userInfo,
  orderLog,
  updateOrderLog,
  editing,
  location,
  orderPaymentHistory,
  distributionDate,
}) {
  const name = user.split("-");
  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { blank, blank2, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  const listOfLocations = {
    [userInfo.organisationName]: [false, order.selectedDate],
  };

  if (order.importedOrder) {
    Object.keys(order.importedOrder).forEach((distributionLocation) => {
      listOfLocations[distributionLocation] = [
        true,
        order.importedOrder[distributionLocation].date,
      ];
    });
  }

  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);
  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // The total including the donation or subsidy
  let grandTotal = 0;

  // The total packaging fee.
  let packageFeeTotal = 0;

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;
  // The total volunteer Fee Credit portion
  const volunteerFeeCredits = userOrder.creditPortion;
  // The total of the food not including the donation or subsidy
  let costTotal = 0;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
    costTotal = grandTotal;
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
    costTotal += packageTotals[packageTotal];
  });

  // Add in the subdidy and donation amounts
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // If the volunteer cash portion is greater than 0 then we need to add to the
  // grandTotal and costTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
    costTotal += parseFloat(volunteerFeeCash);
  }

  // If the delivery fee is greater than 0 then add it to the grandTotal and the costTotal.
  if (parseFloat(userOrder.deliveryFee) > 0) {
    grandTotal += userOrder.deliveryFee;
    costTotal += userOrder.deliveryFee;
  }

  grandTotal += packageFeeTotal;

  // Make sure that the grandTotal isn't ever negative.
  if (grandTotal < 0 && grandTotal > -0.01) {
    grandTotal = 0;
  }

  // The order date is the date of the selected order which is used to display
  // the distribution date to the user.
  let distributedDate = order.selectedDate;
  // If the order is imported then find it's selectedDate.
  if (imported) {
    distributedDate = order.importedOrder[location].date;
  }

  return (
    <div key={idx}>
      <span className="User-Name-Remove-Button">
        <h5>
          {name[0]} {name[1]}:{" "}
        </h5>
        <CommunityMemberInfoDialog communityMember={name} />
        {editing && (
          <>
            <CopyOrdersBetweenLocationsDialog
              distributionLocation={location}
              distributionLocationUserId={userInfo.userId}
              orderDate={order.orderDate}
              userOrderIdentifier={user}
              imported={imported}
              listOfLocations={listOfLocations}
              distributionDate={distributionDate}
              order={order}
              userInfo={userInfo}
              orderLog={orderLog}
              orderIndex={orderIndex}
              updateOrderLog={updateOrderLog}
              updatePaymentHistory={updatePaymentHistory}
            />
            <RemoveCustomerOrderDialog
              user={user}
              imported={imported}
              order={order}
              orderIndex={orderIndex}
              userInfo={userInfo}
              orderLog={orderLog}
              updateOrderLog={updateOrderLog}
              location={location}
              updatePaymentHistory={updatePaymentHistory}
            />
          </>
        )}
      </span>
      {editing && (
        <AddItemToOrderDialog
          idx={idx}
          userOrder={userOrder}
          user={user}
          imported={imported}
          order={order}
          userInfo={userInfo}
          orderLog={orderLog}
          updateOrderLog={updateOrderLog}
          orderIndex={orderIndex}
          location={location}
          updatePaymentHistory={updatePaymentHistory}
        />
      )}
      {userOrder.foodList.map((food, itemNumber) => {
        // The price of the item for the individual.
        let individualPrice = parseFloat(
          food.price / (food.distributionQuantity / food.individualQuantity),
        );
        // The individual description of the food item for the user to know how
        // much they are purchasing.
        let individualDescription = food.individualDescription;

        // if suggestedPrice is true then we want to set the price to 0
        if (food.suggestedPrice) {
          // First add to the description the suggested Donation.
          individualDescription = individualDescription.concat(
            " - Suggested Donation of $",
            individualPrice,
          );
          // Then reset the prices to 0.
          individualPrice = 0.0;
        }

        // If this item has a packaging fee then add it to the price of item.
        if (food.packageFee > 0) {
          individualPrice += parseFloat(food.packageFee);
        }
        const individualPriceTotal = parseFloat(
          individualPrice * food.quantity,
        ).toFixed(2);
        individualPrice = individualPrice.toFixed(2);

        // The colour of the multiplier that shows the quantity of an item
        // ex. Potatoes x2
        let colourOfMultiplier = "black";

        // If the quantity is greater than 1 then make it red so it stands out more.
        if (food.quantity > 1) {
          colourOfMultiplier = "red";
        }

        return (
          <span key={itemNumber}>
            {/* This is necessary to deal with the old database before the foodItems
            for both individual and distribution location's were combined */}
            {food.individualDescription === undefined ? (
              <p style={{ margin: "0px 0px 0px 20px" }}>
                {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                {food.description}{" "}
                <u style={{ color: colourOfMultiplier }}>x{food.quantity}</u> --
                ${food.individualPrice} (${individualPriceTotal})
              </p>
            ) : (
              <div className="Edit-Community-Items-Distribution-Location">
                <p style={{ margin: "0px 0px 0px 20px" }}>
                  {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                  {individualDescription}{" "}
                  <u style={{ color: colourOfMultiplier }}>x{food.quantity}</u>{" "}
                  -- ${individualPrice} (${individualPriceTotal})
                  {food.packageFee > 0 && <>*</>}
                  {!!food.limit && (
                    <img
                      style={{ marginLeft: "5px", height: "15px" }}
                      src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
                    />
                  )}
                  {food.packageFee > 0 && (
                    <p className="Item-Package-Fee">
                      *includes package fee $
                      {parseFloat(food.packageFee).toFixed(2)}/per item
                    </p>
                  )}
                </p>
                {editing && (
                  <span>
                    {/* With the introduction of the multiple imported orders there is new way to
                    interact with the orders. June 17 2021 */}
                    {order.orderDate > "202106150000000000000" ? (
                      <div>
                        <Button
                          color="secondary"
                          onClick={() =>
                            RemoveFoodItemCommunityOrders(
                              userOrder,
                              user,
                              imported,
                              order,
                              itemNumber,
                              userInfo,
                              orderLog,
                              updateOrderLog,
                              orderIndex,
                              location,
                              updatePaymentHistory,
                            )
                          }
                        >
                          Remove
                        </Button>
                        <EditCommunityOrdersItemDialog
                          item={food.item}
                          distributionQuantity={food.distributionQuantity}
                          description={food.description}
                          individualQuantity={food.individualQuantity}
                          individualDescription={food.individualDescription}
                          suggestedPrice={food.suggestedPrice}
                          price={food.price}
                          category={food.category}
                          displayImage={food.displayImage}
                          quantity={food.quantity}
                          farmName={food.farmName}
                          limit={food.limit}
                          packageFee={food.packageFee}
                          order={order}
                          orderLog={orderLog}
                          updateOrderLog={updateOrderLog}
                          orderIndex={orderIndex}
                          userInfo={userInfo}
                          user={user}
                          imported={imported}
                          location={location}
                        />
                      </div>
                    ) : (
                      <Button
                        color="secondary"
                        onClick={() =>
                          RemoveFoodItemCommunityOrdersOld(
                            userOrder,
                            user,
                            imported,
                            order,
                            itemNumber,
                            userInfo,
                            orderLog,
                            updateOrderLog,
                            orderIndex,
                          )
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </span>
                )}
              </div>
            )}
          </span>
        );
      })}
      <div className="Price-Totals">
        <h5 className="Contact-Method-Title Food-Item-Header">
          {" "}
          Farm Price Totals{" "}
        </h5>
        {Object.keys(farmTotals).map((farmTotal, index) => (
          <p key={farmTotal + index} className="Prices-Per-Farm">
            {farmTotal} : ${farmTotals[farmTotal].toFixed(2)}
            {packageTotals[farmTotal] > 0 && (
              <span
                style={{ verticalAlign: "super" }}
                className="Item-Package-Fee"
              >
                + ${packageTotals[farmTotal].toFixed(2)} pkg fee
              </span>
            )}
          </p>
        ))}

        <span
          style={{
            fontSize: "12px",
            alignItems: "end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {packageFeeTotal > 0 && (
            <p className="Total-Packaging-Fees">
              Total Package Fees : ${packageFeeTotal.toFixed(2)}
            </p>
          )}

          {userOrder.deliveryFee !== undefined && (
            <span className="Customer-Payment-Edit-Row">
              {editing && (
                <DeliveryFeeChangeDialog
                  userOrder={userOrder}
                  user={user}
                  imported={imported}
                  order={order}
                  userInfo={userInfo}
                  orderLog={orderLog}
                  updateOrderLog={updateOrderLog}
                  orderIndex={orderIndex}
                  locationName={location}
                  updatePaymentHistory={updatePaymentHistory}
                />
              )}
              <p className="Total-Packaging-Fees">
                Delivery Fee : ${userOrder.deliveryFee.toFixed(2)}
              </p>
            </span>
          )}

          <span className="Customer-Payment-Edit-Row">
            {editing && (
              <VolunteerFeeChangeDialog
                userOrder={userOrder}
                user={user}
                imported={imported}
                order={order}
                userInfo={userInfo}
                orderLog={orderLog}
                updateOrderLog={updateOrderLog}
                orderIndex={orderIndex}
                locationName={location}
                updatePaymentHistory={updatePaymentHistory}
              />
            )}
            <span>
              {parseFloat(volunteerFeeCash) > 0 && (
                <p className="Total-Packaging-Fees">
                  Participation Fee (Cash) : ${volunteerFeeCash}
                </p>
              )}
              {parseFloat(volunteerFeeCredits) > 0 && (
                <p className="Total-Packaging-Fees">
                  Participation Fee (Credits) : {volunteerFeeCredits}
                </p>
              )}
            </span>
          </span>
        </span>

        <span className="Customer-Payment-Edit-Row">
          {editing && (
            <SubsidyDonationChangeDialog
              idx={idx}
              userOrder={userOrder}
              user={user}
              imported={imported}
              order={order}
              userInfo={userInfo}
              orderLog={orderLog}
              updateOrderLog={updateOrderLog}
              orderIndex={orderIndex}
              costTotal={costTotal}
              location={location}
              updatePaymentHistory={updatePaymentHistory}
              packageFeeTotal={packageFeeTotal}
            />
          )}
          {userOrder.customerContribution === "donation" ? (
            <p className="Subsidy-Donation-Totals" style={{ color: "green" }}>
              Donation : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          ) : (
            <p className="Subsidy-Donation-Totals" style={{ color: "red" }}>
              Subsidy : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          )}
        </span>
        <p className="User-Total-Payment">
          {/* Make sure that there is a payment history for this order. */}
          {!editing && orderPaymentHistory[order.orderDate] && (
            <EnterCustomerPaymentDialog
              orderDate={order.orderDate}
              orderPaymentHistory={orderPaymentHistory}
              distributedDate={distributedDate}
              imported={imported}
              location={location}
              user={user}
            />
          )}
          <b>Total </b>: ${grandTotal.toFixed(2)}
        </p>
      </div>
    </div>
  );
}
