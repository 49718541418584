// This function sends out an email to the farm collecting the order.  If an
// order contains multiple farms then this function will be called for each farm.
// It calls the cloud function sendMail to complete this task.
import firebase from "../../components/Firebase.js";
import { Sema } from "async-sema";

// Create a semaphore to limit concurrent executions to 3
const semaphore = new Sema(1);

export async function OrderEmails2(item) {
  const [
    farm,
    food,
    userInfo,
    deliveryDate,
    deliveryDay,
    grandTotal,
    donationSubsidy,
    customerContribution,
    setPlacingOrder,
    setSnackBarOpen,
    deliveryFee,
    cashPortion,
    creditPortion,
    costTotal,
    handleUserInfoChange,
  ] = item;

  // These are the list of items this farm will be providing
  const farmOrders = [];
  // The stringified version of order
  let orderString = "";
  // The package fees in total for the order.
  let packageFeeTotal = 0;
  // The package fees owed for the order in string format to be put in the email.
  let packageFeeString = "";
  // The delivery fees owed for the order in string format to be put in the email.
  let deliveryFeeString = "";
  // The volunteer fees owed for the order in string format to be put in the email.
  let volunteerFeeString = "";

  // Individual orders should include which farmers are selling which products so we
  // create the list to be a dictionary with farm keys and the food as an array.
  const individalFarmOrders = {};

  // filter out the items for that aren't ordered from the current farm and create
  // a list of these food items.
  if (userInfo.userType !== "individual") {
    food.forEach((orderItem) => {
      if (orderItem.farmName === farm.farmName) {
        farmOrders.push(orderItem);
      }
    });
  } else {
    // cycle through the food and add the item to a dictionary with the farm as the key
    // with the value being an array of the list of items.
    food.forEach((orderItem) => {
      if (individalFarmOrders[orderItem.farmName]) {
        individalFarmOrders[orderItem.farmName].push(orderItem);
      } else {
        individalFarmOrders[orderItem.farmName] = [orderItem];
      }
    });
  }

  // If there are no items for this farm then you don't need to send them an email.
  if (
    farmOrders.length === 0 &&
    Object.keys(individalFarmOrders).length === 0
  ) {
    return null;
  }

  if (userInfo.userType === "individual") {
    // Cycle through the farms that the individal ordered from.
    Object.keys(individalFarmOrders).forEach((farmName) => {
      // Add the name of the farm.
      orderString = orderString.concat(
        '<span style="font-size: 14px;"><u><b>',
        farmName.toString(),
        ":</b></u></span><br />",
      );
      // Cycle through the items from the farm.
      individalFarmOrders[farmName].forEach((orderItem) => {
        // The order item price that will be used it may be reset to 0 if the
        // suggested price is true.
        let orderItemPrice = orderItem.price;
        // The description of the orderItem that is used to let the user know how much they
        // are buying.
        let description = orderItem.individualDescription;

        // Set the price of the individal item
        let individualPrice = parseFloat(
          orderItemPrice /
            (orderItem.distributionQuantity / orderItem.individualQuantity),
        );

        // if suggestedPrice is true then we want to set the price to 0
        if (orderItem.suggestedPrice) {
          // First add to the description the suggested Donation.
          description = description.concat(
            " - Suggested Donation of $",
            individualPrice.toFixed(2),
          );
          // Then reset the prices to 0.
          orderItemPrice = 0.0;
          individualPrice = 0.0;
        }

        // If there is a package fee then add it to the item price and add it to the
        // the total package fee.
        if (orderItem.packageFee > 0) {
          individualPrice += parseFloat(orderItem.packageFee);
          packageFeeTotal +=
            parseFloat(orderItem.packageFee) * parseFloat(orderItem.quantity);
        }
        // Set the price of the individal item multiplied by the amount of that item.
        const individualPriceTotal = parseFloat(
          individualPrice * orderItem.quantity,
        ).toFixed(2);
        // Make the price a 2 decimal float for dollar format.
        individualPrice = individualPrice.toFixed(2);
        // Create the string for the email with the order item.
        orderString = orderString.concat(
          "• ",
          orderItem.item.toString(),
          " ",
          description.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " -- $",
          individualPrice,
          " ($",
          individualPriceTotal.toString(),
          ")",
        );
        // If there is a package fee then add it to the string to let the user know.
        if (orderItem.packageFee > 0) {
          orderString = orderString.concat(
            " *includes pkg fee of ",
            parseFloat(orderItem.packageFee).toFixed(2),
            "/per item.",
          );
        }
        orderString = orderString.concat("<br />");
      });
    });
    // If the packageFee is greater than 0 then we want to print the total fees.
    if (packageFeeTotal > 0) {
      packageFeeString = packageFeeString.concat(
        "+ Package Fees : $",
        packageFeeTotal.toFixed(2),
        "<br />",
      );
    }

    // If the deliveryFee is greater than 0 then we want to print the total fees.
    if (deliveryFee > 0) {
      deliveryFeeString = deliveryFeeString.concat(
        "+ Delivery Fee : $",
        deliveryFee.toFixed(2),
        "**<br />",
      );
    }

    // If the volunteer cash portion is greater than 0 then we want to print the total fees.
    if (cashPortion > 0) {
      volunteerFeeString = volunteerFeeString.concat(
        "+ Participation Fee Cash : $",
        cashPortion,
        "<br />",
      );
    }
    // If the volunteer credit portion is greater than 0 then we want to print the total fees.
    if (creditPortion > 0) {
      volunteerFeeString = volunteerFeeString.concat(
        "Participation Fee Credits : ",
        creditPortion,
        " credits<br />",
      );
    }
  } else {
    // convert the orders in this farms order array into a string to be sent in the email.
    farmOrders.forEach((orderItem) => {
      // The order item price that will be used it may be reset to 0 if the
      // suggested price is true.
      let orderItemPrice = orderItem.price;
      // The description of the orderItem that is used to let the user know how much they
      // are buying.
      let description = orderItem.individualDescription;

      // if suggestedPrice is true then we want to set the price to 0
      if (orderItem.suggestedPrice) {
        // First add to the description the suggested Donation.
        description = description.concat(
          " - Suggested Donation of $",
          orderItemPrice,
        );
        // Then reset the prices to 0.
        orderItemPrice = 0.0;
      }

      if (orderItem.checked) {
        orderString = orderString.concat(
          description.toString(),
          " ",
          orderItem.item.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " ",
          "weekly on ",
          deliveryDay,
          "s",
          "<br />",
        );
      } else {
        // The total of the items multiplied by the quantity the user is purchasing.
        const priceTotal = parseFloat(
          orderItemPrice * orderItem.quantity,
        ).toFixed(2);
        orderString = orderString.concat(
          "• ",
          orderItem.item.toString(),
          " ",
          orderItem.description.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " -- $",
          parseFloat(orderItemPrice).toFixed(2).toString(),
          " ($",
          priceTotal.toString(),
          ") <br />",
        );
      }
    });
  }

  // If this is not an individual than add the farms to the order.  If this is an
  // individual than at their pickupLocation's email to the marketplace.
  const dest = [userInfo.email];
  if (userInfo.userType !== "individual") {
    dest.push(farm.farmerEmail);
  } else {
    dest.push(userInfo.pickupLocation.email);
  }

  setPlacingOrder(true);
  // Acquire semaphore lock

  // Call the sendMail cloud Function on firebase.
  try {
    const sendMail = firebase.functions().httpsCallable("sendMail");
    await sendMail({
      dest,
      userInfo,
      farm,
      order: orderString,
      deliveryDate,
      grandTotal,
      donationSubsidy,
      customerContribution,
      packageFeeString,
      deliveryFeeString,
      volunteerFeeString,
      costTotal,
    });

    if (userInfo.userType === "individual" && parseFloat(creditPortion) > 0) {
      const database = firebase.firestore();
      // The indivdual's credits also needs to be updated.  This is the reference to it
      // in firebase.
      const userDocRef = database.collection("Users").doc(userInfo.userId);
      userDocRef.update({
        credits: firebase.firestore.FieldValue.increment(
          -parseFloat(creditPortion),
        ),
      });
      const userInfoTemp = { ...userInfo };
      userInfoTemp.credits -= parseFloat(creditPortion);
      handleUserInfoChange(userInfoTemp, false, false, true);
    }
    setPlacingOrder(false);
    setSnackBarOpen(true);
  } catch (error) {
    const code = error.code;
    const message = error.message;
    console.error("There was an error when calling the Cloud Function", error);
    window.alert(
      "There was an error when calling the Cloud Function:\n\nError Code: " +
        code +
        "\nError Message:" +
        message,
    );
  }
}

export default async function OrderEmails(
  farm,
  food,
  userInfo,
  deliveryDate,
  deliveryDay,
  grandTotal,
  donationSubsidy,
  customerContribution,
  setPlacingOrder,
  setSnackBarOpen,
  deliveryFee,
  cashPortion,
  creditPortion,
  costTotal,
  handleUserInfoChange,
) {
  // These are the list of items this farm will be providing
  const farmOrders = [];
  // The stringified version of order
  let orderString = "";
  // The package fees in total for the order.
  let packageFeeTotal = 0;
  // The package fees owed for the order in string format to be put in the email.
  let packageFeeString = "";
  // The delivery fees owed for the order in string format to be put in the email.
  let deliveryFeeString = "";
  // The volunteer fees owed for the order in string format to be put in the email.
  let volunteerFeeString = "";

  // Individual orders should include which farmers are selling which products so we
  // create the list to be a dictionary with farm keys and the food as an array.
  const individalFarmOrders = {};

  // filter out the items for that aren't ordered from the current farm and create
  // a list of these food items.
  if (userInfo.userType !== "individual") {
    food.forEach((orderItem) => {
      if (orderItem.farmName === farm.farmName) {
        farmOrders.push(orderItem);
      }
    });
  } else {
    // cycle through the food and add the item to a dictionary with the farm as the key
    // with the value being an array of the list of items.
    food.forEach((orderItem) => {
      if (individalFarmOrders[orderItem.farmName]) {
        individalFarmOrders[orderItem.farmName].push(orderItem);
      } else {
        individalFarmOrders[orderItem.farmName] = [orderItem];
      }
    });
  }

  // If there are no items for this farm then you don't need to send them an email.
  if (
    farmOrders.length === 0 &&
    Object.keys(individalFarmOrders).length === 0
  ) {
    return null;
  }

  if (userInfo.userType === "individual") {
    // Cycle through the farms that the individal ordered from.
    Object.keys(individalFarmOrders).forEach((farmName) => {
      // Add the name of the farm.
      orderString = orderString.concat(
        '<span style="font-size: 14px;"><u><b>',
        farmName.toString(),
        ":</b></u></span><br />",
      );
      // Cycle through the items from the farm.
      individalFarmOrders[farmName].forEach((orderItem) => {
        // The order item price that will be used it may be reset to 0 if the
        // suggested price is true.
        let orderItemPrice = orderItem.price;
        // The description of the orderItem that is used to let the user know how much they
        // are buying.
        let description = orderItem.individualDescription;

        // Set the price of the individal item
        let individualPrice = parseFloat(
          orderItemPrice /
            (orderItem.distributionQuantity / orderItem.individualQuantity),
        );

        // if suggestedPrice is true then we want to set the price to 0
        if (orderItem.suggestedPrice) {
          // First add to the description the suggested Donation.
          description = description.concat(
            " - Suggested Donation of $",
            individualPrice.toFixed(2),
          );
          // Then reset the prices to 0.
          orderItemPrice = 0.0;
          individualPrice = 0.0;
        }

        // If there is a package fee then add it to the item price and add it to the
        // the total package fee.
        if (orderItem.packageFee > 0) {
          individualPrice += parseFloat(orderItem.packageFee);
          packageFeeTotal +=
            parseFloat(orderItem.packageFee) * parseFloat(orderItem.quantity);
        }
        // Set the price of the individal item multiplied by the amount of that item.
        const individualPriceTotal = parseFloat(
          individualPrice * orderItem.quantity,
        ).toFixed(2);
        // Make the price a 2 decimal float for dollar format.
        individualPrice = individualPrice.toFixed(2);
        // Create the string for the email with the order item.
        orderString = orderString.concat(
          "• ",
          orderItem.item.toString(),
          " ",
          description.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " -- $",
          individualPrice,
          " ($",
          individualPriceTotal.toString(),
          ")",
        );
        // If there is a package fee then add it to the string to let the user know.
        if (orderItem.packageFee > 0) {
          orderString = orderString.concat(
            " *includes pkg fee of ",
            parseFloat(orderItem.packageFee).toFixed(2),
            "/per item.",
          );
        }
        orderString = orderString.concat("<br />");
      });
    });
    // If the packageFee is greater than 0 then we want to print the total fees.
    if (packageFeeTotal > 0) {
      packageFeeString = packageFeeString.concat(
        "+ Package Fees : $",
        packageFeeTotal.toFixed(2),
        "<br />",
      );
    }

    // If the deliveryFee is greater than 0 then we want to print the total fees.
    if (deliveryFee > 0) {
      deliveryFeeString = deliveryFeeString.concat(
        "+ Delivery Fee : $",
        deliveryFee.toFixed(2),
        "**<br />",
      );
    }

    // If the volunteer cash portion is greater than 0 then we want to print the total fees.
    if (cashPortion > 0) {
      volunteerFeeString = volunteerFeeString.concat(
        "+ Participation Fee Cash : $",
        cashPortion,
        "<br />",
      );
    }
    // If the volunteer credit portion is greater than 0 then we want to print the total fees.
    if (creditPortion > 0) {
      volunteerFeeString = volunteerFeeString.concat(
        "Participation Fee Credits : ",
        creditPortion,
        " credits<br />",
      );
    }
  } else {
    // convert the orders in this farms order array into a string to be sent in the email.
    farmOrders.forEach((orderItem) => {
      // The order item price that will be used it may be reset to 0 if the
      // suggested price is true.
      let orderItemPrice = orderItem.price;
      // The description of the orderItem that is used to let the user know how much they
      // are buying.
      let description = orderItem.individualDescription;

      // if suggestedPrice is true then we want to set the price to 0
      if (orderItem.suggestedPrice) {
        // First add to the description the suggested Donation.
        description = description.concat(
          " - Suggested Donation of $",
          orderItemPrice,
        );
        // Then reset the prices to 0.
        orderItemPrice = 0.0;
      }

      if (orderItem.checked) {
        orderString = orderString.concat(
          description.toString(),
          " ",
          orderItem.item.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " ",
          "weekly on ",
          deliveryDay,
          "s",
          "<br />",
        );
      } else {
        // The total of the items multiplied by the quantity the user is purchasing.
        const priceTotal = parseFloat(
          orderItemPrice * orderItem.quantity,
        ).toFixed(2);
        orderString = orderString.concat(
          "• ",
          orderItem.item.toString(),
          " ",
          orderItem.description.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " -- $",
          parseFloat(orderItemPrice).toFixed(2).toString(),
          " ($",
          priceTotal.toString(),
          ") <br />",
        );
      }
    });
  }

  // If this is not an individual than add the farms to the order.  If this is an
  // individual than at their pickupLocation's email to the marketplace.
  const dest = [userInfo.email];
  if (userInfo.userType !== "individual") {
    dest.push(farm.farmerEmail);
  } else {
    dest.push(userInfo.pickupLocation.email);
  }

  setPlacingOrder(true);
  // Acquire semaphore lock
  await semaphore.acquire();

  const sendMail = firebase.functions().httpsCallable("sendMail");
  // Call the sendMail cloud Function on firebase.
  sendMail({
    dest,
    userInfo,
    farm,
    order: orderString,
    deliveryDate,
    grandTotal,
    donationSubsidy,
    customerContribution,
    packageFeeString,
    deliveryFeeString,
    volunteerFeeString,
    costTotal,
  })
    .then(function (result) {
      if (userInfo.userType === "individual" && parseFloat(creditPortion) > 0) {
        const database = firebase.firestore();
        // The indivdual's credits also needs to be updated.  This is the reference to it
        // in firebase.
        const userDocRef = database.collection("Users").doc(userInfo.userId);
        userDocRef.update({
          credits: firebase.firestore.FieldValue.increment(
            -parseFloat(creditPortion),
          ),
        });
        const userInfoTemp = { ...userInfo };
        userInfoTemp.credits -= parseFloat(creditPortion);
        handleUserInfoChange(userInfoTemp, false, false, true);
      }
      setPlacingOrder(false);
      setSnackBarOpen(true);
      // Release semaphore lock after executing sendMail function
      semaphore.release();
    })
    .catch(function (error) {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      console.error(
        "There was an error when calling the Cloud Function",
        error,
      );
      window.alert(
        "There was an error when calling the Cloud Function:\n\nError Code: " +
          code +
          "\nError Message:" +
          message,
      );
      // Release semaphore lock after executing sendMail function
      semaphore.release();
    });
}
