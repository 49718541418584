// This handles a change in the quantity from the user.  The third prop is to
// determine if there was an increase or decrease.  Add to basket counts as an
// increase.  Fourth prop will be the documentName and then the fifth prop is
// whether the user added the items to the basket.  First it finds the matching
// foodItem index in the foodItems array and then it decrements,
// increments, or resets them to zero depending on the the third prop.
export default function HandleQuantityChange(
  displayedFoodItems,
  setDisplayedFoodItems,
  incrementItem,
  item,
  addedToBasket,
  userInfo,
) {
  // Sets a temp array of the displayed items
  const arrFood = [...displayedFoodItems];
  // The index of the arrFood that contains the sent in item.
  let index = 0;

  // Finds the index of the arrFood array that matches the sent in item.
  index = arrFood.findIndex(
    (element) =>
      item ===
      (element.item + element.description + element.farmName).replace(
        /\s+/g,
        "",
      ),
  );

  // Checks if it was a positive change in quantities
  if (incrementItem) {
    // Checks if you've added the item to the basket which resets the quantity to 0.
    if (addedToBasket) {
      arrFood[index].quantity = 0;
      setDisplayedFoodItems([...arrFood]);
      return;
    }
    // If the person added to the item
    arrFood[index].quantity += 1;
    // If the user is passing the threshold of guaranteedMaxQuantity then we give them a warning.
    if (
      arrFood[index].quantity === arrFood[index].guaranteedMaxQuantity &&
      arrFood[index].limit == null &&
      userInfo.userType === "individual"
    ) {
      alert(
        "You've reached the maximum guaranteed quantity of " +
          arrFood[index].guaranteedMaxQuantity +
          "x" +
          arrFood[index].individualDescription +
          ". " +
          "To ensure availability for others, any extra quantity will be fulfilled based on final orders. " +
          "Thank you for your order! You will be notified of any changes to your order.",
      );
    }

    setDisplayedFoodItems([...arrFood]);
  }
  // If the person subtracts an item to the item
  else {
    arrFood[index].quantity -= 1;
    setDisplayedFoodItems([...arrFood]);
  }
}
