import React from "react";
// eslint-disable-next-line no-unused-vars
import { Route, Switch } from "react-router-dom";
import Marketplace from "./pages/Marketplace.js";
import AboutUs from "./pages/AboutUs.js";
import HowItWorks from "./pages/HowItWorks.jsx";
import Basket from "./pages/Basket.js";
import MyAccount from "./pages/MyAccount.js";
import HomePage from "./pages/HomePage.js";
import ListOfIssues from "./pages/ListOfIssues.js";
import RestaurantMenu from "./pages/RestaurantMenu.js";
import DeliveryMap from "./pages/DeliveryMap.js";
import EditMenu from "./pages/EditMenu.js";
import SignIn from "./pages/SignIn.js";
import SignUp from "./pages/SignUp.js";
import OrderConfirmation from "./pages/OrderConfirmation.jsx";
import ForgotPassword from "./pages/ForgotPassword.js";
import PageNotFoundError from "./components/PageNotFoundError.js";
// eslint-disable-next-line no-unused-vars
import { AuthProvider } from "./components/authentication/Auth.js";
// eslint-disable-next-line no-unused-vars
import PrivateRoute from "./components/authentication/PrivateRoute.js";
import DistributionLocationSelection from "./pages/DistributionLocationSelection.js";
import OurFarmers from "./pages/OurFarmers.jsx";
import CommunityEngagement from "./pages/CommunityEngagement.jsx";
import { StylesProvider } from "@material-ui/core/styles";

import urls from "./urls.js";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

/**
 * It is necessary to specify the JSS injection order
 * so mui v5 styled component api can target css with the correct precedence.
 * see: https://v4.mui.com/guides/interoperability/#controlling-priority
 */
function MuiV4StylesProvider({ children }) {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
}

export default class App extends React.Component {
  render() {
    return (
      <MuiV4StylesProvider>
        <main>
          {/* Removed the FarmerProfile for now <Route path ="/FarmerProfile/:farmName" component = {FarmerProfile} /> */}
          <AuthProvider>
            <Switch>
              <Route path="/" component={HomePage} exact />
              <Route path="/SignIn" component={SignIn} exact />
              <Route path="/SignUp" component={SignUp} exact />
              <Route path="/ForgotPassword" component={ForgotPassword} exact />
              <Route path={urls.marketPlace} component={Marketplace} />
              <Route path={urls.aboutUs} component={AboutUs} />
              <Route path={urls.howItWorks} component={HowItWorks} />
              <Route path={urls.farms} component={OurFarmers} />
              <Route
                path={urls.communityEngagement}
                component={CommunityEngagement}
              />
              <PrivateRoute
                path="/OrderConfirmation"
                component={OrderConfirmation}
                exact
              />
              <PrivateRoute
                path="/EditMenu/:restaurantName"
                component={EditMenu}
              />
              <PrivateRoute path={urls.deliveryMap} component={DeliveryMap} />
              <PrivateRoute path="/Basket" component={Basket} />
              <Route
                path="/DistributionLocationSelection"
                component={DistributionLocationSelection}
              />
              <PrivateRoute path={urls.myAccount} component={MyAccount} />
              <Route path="/Menu/:restaurantName" component={RestaurantMenu} />
              <Route path="/ListOfIssues" component={ListOfIssues} />
              <Route component={PageNotFoundError} />
            </Switch>
          </AuthProvider>
        </main>
      </MuiV4StylesProvider>
    );
  }
}
