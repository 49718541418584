// This a dialog that pops up with editing a user's delivery fee amount.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UpdateCustomerDeliveryFees from "./../ModifyOrders/UpdateCustomerDeliveryFees.js";
import "date-fns";

export default function DeliveryFeeChangeDialog({
  userOrder,
  user,
  imported,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  locationName,
  updatePaymentHistory,
}) {
  const [open, setOpen] = useState(false);
  // The delivery Fee that the user is inputing to be updated.  It is defaulted
  // to the original amount.
  const [deliveryFee, setDeliveryFee] = useState(userOrder.deliveryFee);
  // This will hold the updated user's order.
  const [userOrderToUpdate, setUserOrderToUpdate] = useState({ ...userOrder });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // When the user saves their changes then this calls the functions to save to
  // the database.
  const handleSave = (e) => {
    e.preventDefault();
    // Update the delivery fees and save the changes to the database.
    UpdateCustomerDeliveryFees(
      user,
      imported,
      order,
      userInfo,
      orderLog,
      updateOrderLog,
      orderIndex,
      userOrderToUpdate,
      updatePaymentHistory,
      locationName,
    );
    setOpen(false);
  };

  // This updates the value that holds the donationSubsidy amount.  This is the
  // actual value.
  const handleChange = (e) => {
    // The user input from the textfield
    let value = e.target.value;
    // The current user Order that we will modify.
    const userOrderTemp = { ...userOrderToUpdate };

    // If the user types in nothing then we will set it 0
    if (value === "") {
      value = 0;
    }

    // If the value is less than 0 then we don't update anything
    if (value < 0) {
      return null;
    }
    // Otherwise the user is updating the fee and we will update the delivery fee
    // for the user.
    else {
      userOrderTemp.deliveryFee = parseFloat(value);
      setDeliveryFee(parseFloat(value));
    }
    // Update the user order
    setUserOrderToUpdate({ ...userOrderTemp });
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        SET DELIVERY FEES
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Change Delivery Fees</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Delivery Fee"
            name="deliveryFee"
            value={deliveryFee}
            onChange={(e) => handleChange(e)}
            type="number"
            inputProps={{
              step: "0.1",
            }}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
