import React from "react";
import firebase from "./../Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SimpleSelect from "./../userInputs/DropDownMenu.js";
import AddCategoryToDistributionLocations from "../../functions/AddCategoryToDistributionLocations.js";
import HandleRefresh from "./../../pages/MarketplacePage/Functions/HandleRefresh.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class MarketplaceAddDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      minimumPurchase: 1,
      suggestedPrice: false,
      price: "",
      category: "",
      farmName: "",
      item: "",
      displayImage: "",
      selectedFarm: this.props.farms[0],
      farms: this.props.farms,
      farmLimit: "",
      guaranteedMaxQuantity: "",
      packageFee: 0,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      minimumPurchase: 1,
      suggestedPrice: false,
      price: "",
      category: "",
      farmName: "",
      item: "",
      displayImage: "",
      farmLimit: null,
      guaranteedMaxQuantity: null,
      packageFee: 0,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    let documentName = "";

    if (this.props.userInfo.userType === "admin") {
      documentName = (
        this.state.item +
        this.state.description +
        this.state.selectedFarm
      ).replace(/\s+/g, "");
      // If the user is an admin user than we also have to add the category to the Distribution
      // locations that use the selected farm.  It will check whether the category already exists
      // in which case it will do nothing.
      AddCategoryToDistributionLocations(
        this.props.farmCategories,
        this.state.selectedFarm,
        this.state.category,
      );
    } else {
      documentName = (
        this.state.item +
        this.state.description +
        this.state.selectedFarm +
        firebase.auth().currentUser.uid
      ).replace(/\s+/g, "");
    }
    const docRef = database.collection("FoodItems").doc(documentName);
    const item = {
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      minimumPurchase: this.state.minimumPurchase,
      suggestedPrice: this.state.suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      farmName: this.state.selectedFarm,
      item: this.state.item,
      packageFee: this.state.packageFee,
      displayImage: this.state.displayImage,
      userId: firebase.auth().currentUser.uid,
      adminVisible: true,
      distributionLocationVisible: [],
      userType: this.props.userInfo.userType,
    };
    // Checks to see if the farmLimit was entered.  If it was then add it to the
    // item to be saved into the database.
    if (this.state.farmLimit) {
      item.farmLimit = parseFloat(this.state.farmLimit);
    }
    // Check if the guaranteedMaxQuantity field exists if it does then
    // we want to add it to the item.
    if (this.state.guaranteedMaxQuantity) {
      item.guaranteedMaxQuantity = parseInt(this.state.guaranteedMaxQuantity);
    }
    batch.set(docRef, item);
    batch.commit();
    console.log("Successfully wrote to database!");
    this.setState({
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      minimumPurchase: 1,
      farmName: "",
      item: "",
      packageFee: 0,
      displayImage: "",
      suggestedPrice: false,
      price: "",
      category: "",
      farmLimit: null,
      guaranteedMaxQuantity: null,
    });

    HandleRefresh(this.props.foodItems, this.props.setFoodItems, item, true);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const type = target.type;
    // If we are changing the switch that says the suggestedPrice is on or off then
    // we will update the suggestedPrice.
    if (type === "checkbox") {
      // Set the suggestedPrice to false.
      let suggestedPriceBool = false;
      // If the suggestedPrice is already true set to true.
      if (this.state.suggestedPrice) {
        suggestedPriceBool = true;
      }
      // Swap the suggestedPrice boolean.
      this.setState({
        suggestedPrice: !suggestedPriceBool,
      });
    }
    // If name is defined then we know that it is any element other than the selectedFarm
    // which is a drop down menu and has no name.
    else if (name !== undefined) {
      if (
        (name === "item" ||
          name === "description" ||
          name === "individualDescription") &&
        (value.includes("-") || value.includes(".") || value.includes("/"))
      ) {
        alert(`Please do not use the symbols "-, . or /"`);
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        selectedFarm: value,
      });
    }
  }

  render() {
    return (
      <div>
        <Button
          style={{
            backgroundColor: "rgb(46, 125, 50)",
            color: "white",
            marginBottom: "10px",
            width: "100%",
          }}
          variant="contained"
          onClick={this.handleClickOpen}
        >
          CREATE A NEW ITEM
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Fill in information about the item
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              {this.props.userInfo.userType === "admin" ? (
                <span>
                  <TextField
                    margin="dense"
                    id="item"
                    label="Item"
                    name="item"
                    value={this.state.item}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="distributionQuantity"
                    label="Distribution Quantity"
                    name="distributionQuantity"
                    type="number"
                    value={this.state.distributionQuantity}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="Distribution Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="individualQuantity"
                    label="Individual Quantity"
                    name="individualQuantity"
                    type="number"
                    value={this.state.individualQuantity}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="individualDescription"
                    label="Individual Description"
                    name="individualDescription"
                    value={this.state.individualDescription}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="minimumPurchase"
                    label="Minimum Purchase Quantity"
                    name="minimumPurchase"
                    type="number"
                    value={this.state.minimumPurchase}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <FormControlLabel
                    label="Suggested Price"
                    control={
                      <Switch
                        checked={this.state.suggestedPrice}
                        name="suggestedPrice"
                        onChange={this.handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                  />
                  <TextField
                    margin="dense"
                    id="price"
                    label="Price ($CAD)"
                    name="price"
                    value={this.state.price}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="category"
                    label="Category"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="displayImage"
                    label="Display Image"
                    name="displayImage"
                    value={this.state.displayImage}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="farmLimit"
                    label="Farm Limit"
                    name="farmLimit"
                    value={this.state.farmLimit}
                    onChange={this.handleChange}
                    type="number"
                    fullWidth
                  />
                  {this.state.farmLimit > 0 && (
                    <TextField
                      margin="dense"
                      id="guaranteedMaxQuantity"
                      label="Guaranteed Max Quantity"
                      name="guaranteedMaxQuantity"
                      value={this.state.guaranteedMaxQuantity}
                      onChange={this.handleChange}
                      type="number"
                      fullWidth
                    />
                  )}
                  <TextField
                    margin="dense"
                    id="packageFee"
                    label="Package Fee"
                    name="packageFee"
                    value={this.state.packageFee}
                    onChange={this.handleChange}
                    type="number"
                    fullWidth
                    required
                  />
                  <SimpleSelect
                    margin="dense"
                    id="farmName"
                    title="Supplying Farm"
                    options={this.state.farms}
                    handleChange={this.handleChange}
                    selected={this.state.selectedFarm}
                    required
                  />
                </span>
              ) : (
                <span>
                  <TextField
                    margin="dense"
                    id="item"
                    label="Item"
                    name="item"
                    value={this.state.item}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    fullWidth
                    required
                  />
                  <SimpleSelect
                    margin="dense"
                    id="farmName"
                    title="Supplying Farm"
                    options={this.state.farms}
                    handleChange={this.handleChange}
                    selected={this.state.selectedFarm}
                    required
                  />
                </span>
              )}

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
