// This is to update an individual user's donation or subsidy amounts and update
// whether they are donating or using a subsidy from the Basket Page
import firebase from "./../Firebase.js";

export default function UpdateCustomerContributionBasket(props, userOrder) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Includes the user's entire order
  const user = props.user;
  const imported = props.imported;
  const userInfo = props.userInfo;
  const importedOrder = props.importedOrder;
  const location = props.location;
  const selectedDate = props.selectedDate;
  const handleCommunityOrderChange = props.handleCommunityOrderChange;

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  if (imported) {
    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(importedOrder[location].date);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(selectedDate);
  }

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrder.foodDict;
  delete userOrder.farmDict;

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  batch.update(communityOrdersDocRef, {
    [user]: { ...userOrder },
  });

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userDocRef, {
    customerContribution: userOrder.customerContribution,
    donationSubsidy: userOrder.donationSubsidy,
  });

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  handleCommunityOrderChange(
    imported,
    null,
    null,
    user,
    userOrder,
    null,
    location,
  );

  console.log("Successfully wrote to database!");
}
