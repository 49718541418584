// This dialog appears when the admin user is adding a volunteer system.
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingContent from "../../../../components/LoadingContent.jsx";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import AddVolunteerButton from "../../Components/VolunteerSchedule/AddVolunteerButton.jsx";

// Search for a user that and their information to add them to the volunteer food system.
function SearchForUser(
  searchName,
  setVolunteersFound,
  setSearchLoading,
  selectedPosition,
) {
  const database = firebase.firestore();
  const names = searchName.split(" ");
  // Volunteer temporary.
  const volunteersFoundTemp = {};
  // Search loading.
  setSearchLoading(false);

  // If the first name is undefined then set it to nothing.
  if (names[0] === undefined) {
    names[0] = "";
  }

  // If the last name is undefined then set it to nothing.
  if (names[1] === undefined) {
    names[1] = "";
  }

  // Set the volunteer doc ref.
  let volunteerDocRef = database
    .collection("Users")
    .where("firstName", "==", names[0]);

  // If the last name is not equal to nothing then search for the first and last name.
  if (names[1] !== "") {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    volunteerDocRef = database
      .collection("Users")
      .where("firstName", "==", names[0])
      .where("lastName", "==", names[1]);
  }

  // Load the user's user doc and then add their information to be used.
  volunteerDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          const dictKey = [
            doc.data().firstName,
            doc.data().lastName,
            doc.data().userId,
          ].join("-");
          volunteersFoundTemp[dictKey] = {
            userId: doc.data().userId,
            email: doc.data().email,
            position: selectedPosition,
            phoneNumber: doc.data().phoneNumber,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
          };
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });

      setVolunteersFound(volunteersFoundTemp);
      setSearchLoading(true);
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      setSearchLoading(true);
    });
}

// This is the dialog box that opens when a user clicks the add button
function AddVolunteerBox({
  open,
  onClose,
  selectedPosition,
  volunteerSchedule,
  setVolunteerSchedule,
  indexOfQuarter,
  date,
  shift,
  volunteerList,
  setVolunteerList,
  volunteerLoading,
}) {
  // The name of the user you are searching for.
  const [searchName, setSearchName] = useState("");
  // The search is loading.
  const [searchLoading, setSearchLoading] = useState(true);
  // The volunteers found from the search
  const [volunteersFound, setVolunteersFound] = useState({});

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = (cancel) => {
    onClose(false);
  };

  // This is when the user saves to the database.
  // eslint-disable-next-line no-unused-vars
  const handleSave = (e) => {
    e.preventDefault();

    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Add Volunteers
      </DialogTitle>
      <DialogContent>
        {volunteerLoading ? (
          <div>
            {Object.keys(volunteerList).map((volunteer, i) => (
              <span key={i}>
                <span style={{ display: "flex" }}>
                  • {volunteerList[volunteer].firstName}{" "}
                  {volunteerList[volunteer].lastName}
                  <AddVolunteerButton
                    volunteer={volunteerList[volunteer]}
                    selectedPosition={selectedPosition}
                    volunteerSchedule={volunteerSchedule}
                    setVolunteerSchedule={setVolunteerSchedule}
                    indexOfQuarter={indexOfQuarter}
                    date={date}
                    shift={shift}
                    newVolunteer={false}
                  />
                </span>
                Email : {volunteerList[volunteer].email}
                <br />
              </span>
            ))}

            <span style={{ display: "flex", gap: "10px" }}>
              <TextField
                margin="dense"
                id="searchName"
                label="Volunteer Search"
                name="searchName"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                fullWidth
                required
              />
              <Button
                onClick={() =>
                  SearchForUser(
                    searchName,
                    setVolunteersFound,
                    setSearchLoading,
                    selectedPosition,
                  )
                }
                variant="contained"
                size="small"
                color="primary"
              >
                ENTER
              </Button>
            </span>
            {searchLoading ? (
              <span style={{ display: "flex", flexDirection: "column" }}>
                {Object.keys(volunteersFound).map((volunteerFound) => (
                  <span
                    key={volunteerFound}
                    style={{
                      borderBottom: "2px solid black",
                      paddingBottom: "5px",
                    }}
                  >
                    {volunteersFound[volunteerFound].firstName}{" "}
                    {volunteersFound[volunteerFound].lastName}
                    <AddVolunteerButton
                      volunteer={volunteersFound[volunteerFound]}
                      selectedPosition={selectedPosition}
                      volunteerSchedule={volunteerSchedule}
                      setVolunteerSchedule={setVolunteerSchedule}
                      indexOfQuarter={indexOfQuarter}
                      date={date}
                      shift={shift}
                      newVolunteer={true}
                      volunteerList={volunteerList}
                      setVolunteerList={setVolunteerList}
                    />
                    <br />
                    Email : {volunteersFound[volunteerFound].email}
                  </span>
                ))}
              </span>
            ) : (
              <LoadingContent inline={true} />
            )}
          </div>
        ) : (
          <LoadingContent inline={true} />
        )}

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddVolunteerBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AddVolunteerDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddCircleIcon />
      </IconButton>

      <AddVolunteerBox
        open={open}
        onClose={handleClose}
        selectedPosition={props.selectedPosition}
        volunteerSchedule={props.volunteerSchedule}
        setVolunteerSchedule={props.setVolunteerSchedule}
        indexOfQuarter={props.indexOfQuarter}
        date={props.date}
        shift={props.shift}
        volunteerList={props.volunteerList}
        setVolunteerList={props.setVolunteerList}
        volunteerLoading={props.volunteerLoading}
      />
    </div>
  );
}
