// This shows the food item in a list format for community location's that
// want to refine their offerings.
import React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function ItemAvailableDetails({ row, visible }) {
  // If the user is a distribution location then we will use the per unit quantity
  // for the distribution locations.  Otherwise we'll use the individual quantity.
  const userPerUnit = row.individualDescription;

  return (
    <div
      style={{ display: "flex", alignItems: "center", borderLeft: "3px solid" }}
    >
      {row.limit != null ? (
        <img
          style={{ margin: "10px", height: "15px" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
        />
      ) : (
        <Checkbox
          checked={visible}
          disabled
          inputProps={{ "aria-label": "controlled" }}
        />
      )}

      <p>
        {row.farmName} {row.item} - {userPerUnit}
      </p>
    </div>
  );
}
