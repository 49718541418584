// This is used to display the row on the myaccount page of a foodItem that was taken
// from the pantry items.
import React from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
// eslint-disable-next-line no-unused-vars
import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import PantryFoodItemPriceChangeDialog from "../Dialogs/PantryFoodItemPriceChangeDialog.jsx";
import EditDistributionLocationItemDialog from "../Dialogs/EditDistributionLocationItemDialog.jsx";

export default function PantryMyAccountFoodRow({
  idx,
  food,
  editing,
  multipleSelection,
  userInfo,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  handleSelectionChange,
}) {
  // The individual description of the food item for the user to know how
  // much they are purchasing.
  let individualDescription = food.individualDescription;
  // Set the price of the individal item
  let individualPrice = parseFloat(
    food.price / (food.distributionQuantity / food.individualQuantity),
  );

  // if suggestedPrice is true then we want to set the price to 0
  if (food.suggestedPrice) {
    // First add to the description the suggested Donation.
    individualDescription = individualDescription.concat(
      " - Suggested Donation of $",
      individualPrice,
    );
    // Then reset the prices to 0.
    individualPrice = 0.0;
  }

  return (
    <div key={idx} className="Editing-Remove-Food-Item">
      <div key={food + idx} className="Pantry-Food-Item">
        <div className="Food-Item-Desc-Quantity">
          <h5 className="Food-Item-Child Food-Item-Header">
            {food.item}, {individualDescription}
          </h5>
          <h5 className="Food-Item-Child Food-Item-Header">x{food.quantity}</h5>
        </div>
        <div className="Order-Bottom-Row">
          <p className="Food-Item-Child">{food.farmName}</p>
          <span>
            <span className="Editing-Price-Change">
              {editing && !multipleSelection && (
                <PantryFoodItemPriceChangeDialog
                  userInfo={userInfo}
                  order={order}
                  foodItemUpdate={food}
                  orderLog={orderLog}
                  updateOrderLog={updateOrderLog}
                  orderIndex={orderIndex}
                />
              )}

              <p className="Food-Item-Child"> ${individualPrice.toFixed(2)} </p>
            </span>
          </span>
        </div>
      </div>
      {editing && multipleSelection && (
        <Checkbox
          name="foodItem"
          onChange={(e) => handleSelectionChange(e, idx, true)}
        />
      )}
      {editing && !multipleSelection && (
        <EditDistributionLocationItemDialog
          item={food.item}
          distributionQuantity={food.distributionQuantity}
          description={food.description}
          individualQuantity={food.individualQuantity}
          individualDescription={food.individualDescription}
          price={food.price}
          category={food.category}
          displayImage={food.displayImage}
          quantity={food.quantity}
          farmName={food.farmName}
          packageFee={food.packageFee}
          order={order}
          foodItemUpdate={food}
          orderLog={orderLog}
          updateOrderLog={updateOrderLog}
          orderIndex={orderIndex}
          userInfo={userInfo}
          limit={food.limit}
          index={idx}
        />
      )}
    </div>
  );
}
