// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
// eslint-disable-next-line no-unused-vars
import { Redirect } from "react-router";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";
// eslint-disable-next-line no-unused-vars
import Avatar from "@material-ui/core/Avatar";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import CssBaseline from "@material-ui/core/CssBaseline";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line no-unused-vars
import Box from "@material-ui/core/Box";
// eslint-disable-next-line no-unused-vars
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// eslint-disable-next-line no-unused-vars
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import Container from "@material-ui/core/Container";
// eslint-disable-next-line no-unused-vars
import Footer from "../components/Footer.jsx";
// eslint-disable-next-line no-unused-vars
import Paper from "@material-ui/core/Paper";

// eslint-disable-next-line no-unused-vars
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/" to="/">
        Farm-To-Plate-Marketplace
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
    const letters = /^[A-Za-z]+$/;

    if (firstName.match(letters) && lastName.match(letters)) {
      // Creates the user and then adds them to the database.
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          // Puts together the dictionary that is added to the database for the new user.
          const userInfoTemp = {
            firstName,
            lastName,
            email,
            phoneNumber,
            userType: "individual",
          };
          // Adds the new userId to the database document created on account creation
          userInfoTemp.userId = user.user.uid;
          // Calls the handleUserInfoCreate to update the userInfo AuthContext and
          // then updates the database.
          handleUserInfoCreate({ ...userInfoTemp }, user.user.uid);
        })
        .catch(function (error) {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // [START_EXCLUDE]
          if (errorCode === "auth/weak-password") {
            // eslint-disable-next-line no-undef
            alert("The password is too weak.");
          } else {
            // eslint-disable-next-line no-undef
            alert(errorMessage);
          }
          console.log(error);
          // [END_EXCLUDE]
        });
    } else {
      // eslint-disable-next-line no-undef
      alert(
        "Names can only include letters.  Please correct this to create an account",
      );
      return false;
    }
  };

  const { currentUser, handleUserInfoCreate } = useContext(AuthContext);

  // If the user has created their account they'll automatically be signed in
  // and the they'll be redirected to the homepage.
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div
      id="home"
      style={{
        background:
          "url(https://lh3.googleusercontent.com/pw/AM-JKLX9R3PirLtTHwOF3XeC06VSb-5mrTxYgcNfh_jQZ8qlXK8AJ1Pnqa8uVDQnOEpP7r0eYFK8qhTOZIrPd3QVDglHsa9CnnHZn99KoPQJIpREEoSuNtxseEaJb76wVKVssqtc3wfrq7PqiIlium2o_suH=w1186-h789-no?authuser=1)",
        backgroundSize: "contain",
      }}
    >
      <Menu />
      <Container
        style={{
          background: "white",
          border: "5px solid rgb(159, 191, 89)",
          margin: "50px auto",
          paddingBottom: "15px",
          borderRadius: "15px",
        }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/SignIn" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
