// This dialog allows the users to view the food items available at their locations
// and modify them in case they want to add or remove specific items.
import React, { useState } from "react";
import firebase from "../../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadMarketplaceItems from "../../../../functions/LoadMarketplaceItems.js";
import LoadingContent from "../../../../components/LoadingContent.jsx";
import MarketplaceBulkAmounts from "../../../../functions/MarketplaceBulkAmounts.js";
import FoodItemAvailability from "../../Components/CommunityLocationLimitations/FoodItemAvailability.jsx";
import SortFilter from "../../Components/SortFilter.jsx";

export default function CommunityLocationFoodVisibilityDialog({
  communityLocation,
}) {
  // Dialog is open
  const [open, setOpen] = useState(false);
  // The food items to display.
  const [foodItems, setFoodItems] = useState([]);
  // Whether the food items are still being loaded.
  const [loading, setLoading] = useState(false);
  // Whether the pantry items are still loading.
  const [pantryLoading, setPantryLoading] = useState(false);

  // When the user closes the dialog reset the food items.
  const handleClose = () => {
    setFoodItems([]);
    setOpen(false);
  };

  // Set the userId of the community location.
  const communityLocationUserId = communityLocation.userId;
  const database = firebase.firestore();
  const batch = database.batch();

  // If the user has selected to switch the visibility then call the update visibility
  // function.
  const handleSwitch = (index, toggle) => {
    // Find the food item they've selected.
    const row = { ...foodItems[index] };
    // Set the temp food items list.
    const foodItemsTemp = [...foodItems];
    // If the community location disable doesn't exist then
    // set it a blank array.
    if (row.communityLocationDisable == null) {
      row.communityLocationDisable = [];
    }
    // If the distribution location visibility doesn't exist then
    // set it a blank array.
    if (row.distributionLocationVisible == null) {
      row.distributionLocationVisible = [];
    }
    // Find the index of the communityLocationUserId of the location for displaying
    // the items.
    const indexOfEnable = row.distributionLocationVisible.indexOf(
      communityLocationUserId,
    );
    // Find the index of the communityLocationUserId of the location for disabling
    // the items.
    const indexOfDisable = row.communityLocationDisable.indexOf(
      communityLocationUserId,
    );
    // If the index exists then remove it.
    if (indexOfEnable !== -1) {
      row.distributionLocationVisible.splice(indexOfEnable, 1);
    }
    // If the index exists then remove it.
    if (indexOfDisable !== -1) {
      row.communityLocationDisable.splice(indexOfDisable, 1);
    }
    // If the toggle is to add it then we push it to the visbility otherwise
    // we add it to the diabled list.
    if (toggle) {
      row.distributionLocationVisible.push(communityLocationUserId);
    } else {
      row.communityLocationDisable.push(communityLocationUserId);
    }

    // This is the name of the item in the database.
    const documentName = (row.item + row.description + row.farmName).replace(
      /\s+/g,
      "",
    );
    let docRef = database.collection("FoodItems").doc(documentName);

    // If this is a pantry item then we have to update the pantry food item.
    if (row.limit != null) {
      docRef = database
        .collection("Pantry")
        .doc(communityLocation.distributionLocationName);
      const locationKey = [
        row.item,
        row.individualDescription,
        row.farmName,
      ].join("");
      const dotKey1 = [locationKey, "distributionLocationVisible"].join(".");
      const dotKey2 = [locationKey, "communityLocationDisable"].join(".");
      // Update the database to include the
      batch.update(docRef, {
        [dotKey1]: row.distributionLocationVisible,
        [dotKey2]: row.communityLocationDisable,
      });
    }
    // Update the food item.
    else {
      // Update the database to include the
      batch.update(docRef, {
        distributionLocationVisible: row.distributionLocationVisible,
        communityLocationDisable: row.communityLocationDisable,
      });
    }

    // Commits the changes to the database
    batch.commit();

    // Updates the temp Food Items.
    foodItemsTemp[index] = { ...row };
    // Sets the hook to include the changes.
    setFoodItems([...foodItemsTemp]);
  };

  // On open we need to load all the food items and the pantry items.
  const handleOpen = () => {
    // Stores an array of the farm names that need to be loaded to show pantry
    // items from them.
    const loadLeftovers = [];
    // Creates a list of all the farms that this location has displaying.
    const farms = Object.keys(
      communityLocation.pickupLocation.farmsPickupDates,
    );
    // the userInfo is only used for the userType.
    const userInfoTemp = {};

    // We want to load the marketplace items like a distribution location.
    userInfoTemp.userType = "distributionLocation";

    // Cycle through each farm sorting the data so that it can be paginated
    farms.forEach((farm, index) => {
      // Flag that says if we're on the last farm.
      let lastFarm = false;
      // If this is the last farm the set it to true.
      if (index === farms.length - 1) {
        lastFarm = true;
      }

      // Adds the farm to the leftover list so we can check them later.
      loadLeftovers.push(farm);

      // Load the items from the foodItems collection
      LoadMarketplaceItems(
        farm,
        100,
        lastFarm,
        null,
        setFoodItems,
        null,
        null,
        setLoading,
        userInfoTemp,
        communityLocation,
      );
      // On the last farm load the leftover items from the pantry
      if (lastFarm) {
        setLoading(true);
        MarketplaceBulkAmounts(
          communityLocation.pickupLocation,
          loadLeftovers,
          setFoodItems,
          null,
          setPantryLoading,
          [],
          null,
          userInfoTemp,
        );
      }
    });
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={() => handleOpen()}>MODIFY FOOD VISIBILITIES</Button>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Adjust the Visibility of Items at Your Community Location
        </DialogTitle>
        <DialogContent>
          {loading && pantryLoading ? (
            <div>
              <SortFilter
                foodList={foodItems}
                setFoodList={setFoodItems}
                originalFoodList={[foodItems]}
                pantryFoodItems={[]}
                noSortByOrdered={true}
              />
              <span style={{ display: "flex" }}>
                <u style={{ width: "100px" }}>
                  <h5>Your Hub's Visibility</h5>
                </u>
                <u style={{ paddingLeft: "10px", borderLeft: "3px solid" }}>
                  <h5>Default Visibility</h5>
                </u>
              </span>

              {foodItems.map((row, index) => (
                <FoodItemAvailability
                  key={index}
                  index={index}
                  row={row}
                  handleSwitch={handleSwitch}
                  communityLocation={communityLocation}
                />
              ))}
            </div>
          ) : (
            <LoadingContent />
          )}
          <DialogActions>
            <Button onClick={() => handleClose()} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
