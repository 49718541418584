// This updates a distribution Location's Pantry to include the sent in item.  IT
// then updates the hooks to correctly display the items in the pantry
import firebase from "./../../Firebase.js";

export default function AddItemToPantry(
  leftovers,
  setLeftovers,
  userInfo,
  foodItem,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const leftoversTemp = [...leftovers];

  leftoversTemp.push({ ...foodItem });

  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  batch.update(pantryDocRef, {
    [[foodItem.item, foodItem.individualDescription, foodItem.farmName].join(
      "",
    )]: { ...foodItem },
  });
  batch.commit();
  setLeftovers([...leftoversTemp]);
}
