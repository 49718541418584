// This a dialog that allows the user to change the price of an item on the order
// history page.  This will then call a function that modifies all orders affected
// by this price change.
import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CommunityOrdersAffected from "../../functions/CommunityOrdersAffected.js";
import UpdatePriceCommunityOrdersOld from "../../components/ModifyOrders/UpdatePriceCommunityOrdersOld.js";
import UpdatePriceCommunityOrders from "../../components/ModifyOrders/UpdatePriceCommunityOrders.js";
import { ChangeOrderLogContext } from "../../pages/MyAccount.js";

export default function FoodItemPriceChangeDialog(props) {
  const [open, setOpen] = useState(false);
  const [newPrice, setNewPrice] = useState(props.foodItemUpdate.price);
  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // When the user saves their changes then this calls the functions to save to
  // the database.
  const handleSave = (e) => {
    e.preventDefault();

    // This sends in the order and the food item that needs to be updated.  It returns
    // an array with the FirstName-LastName-userid-orderDate of all the orders that
    // have this item that is being changed.
    const [ordersToChange, importedOrdersToChange] = CommunityOrdersAffected(
      props.order,
      props.foodItemUpdate,
    );
    // With the introduction of the multiple imported orders there is new way to
    // interact with the orders. June 17 2021
    if (props.order.orderDate > "202106150000000000000") {
      // This sends in the array of orders needing to be updated and updated the database.
      UpdatePriceCommunityOrders(
        props,
        ordersToChange,
        importedOrdersToChange,
        newPrice,
        updatePaymentHistory,
      );
    } else {
      UpdatePriceCommunityOrdersOld(
        props,
        ordersToChange,
        importedOrdersToChange,
        newPrice,
      );
    }

    setOpen(false);
  };

  // Updates the userOrder that came in but creates a temp one to change.  This is
  // used to change the radio button from subsidy to donation or vice versa
  const handleChange = (value) => {
    setNewPrice(value);
  };

  return (
    <div>
      <Button size="small" color="secondary" onClick={handleClickOpen}>
        Change
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Change Item Price</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave}>
            <p>
              This will also update orders of individuals who ordered this item.
            </p>
            <span className="Change-Item-Price">
              <p>Set the new price to $</p>
              <TextField
                value={newPrice}
                style={{ maxWidth: "60px" }}
                type="number"
                required
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
            </span>

            <DialogActions>
              <Button color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
