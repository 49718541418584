// The distribution location's basket is called by the main Basket.js function.
// This renders the basket page for a distribution location user.
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import CommunityOrdersDialogBasket from "../Components/Dialogs/CommunityOrdersDialogBasket.jsx";
import MaterialUIPickers from "../../../components/userInputs/DatePicker.js";
import SelectImportLocation from "../../../components/userInputs/SelectImportLocation.jsx";
import ConfirmOrderDialog from "../../../components/dialogs/ConfirmOrder.js";
import PlacingOrderDialog from "../../../components/dialogs/PlacingOrderDialog.jsx";
import PlacingOrderBatchesDialog from "../../../components/dialogs/PlacingOrderBatchesDialog.jsx";
import AddingToPantryDialog from "../../../components/dialogs/AddingToPantryDialog.jsx";
import CustomizedSnackbars from "../../../components/snackBars/Snackbar.js";
import { formatDollars } from "../../../components/OrderDetails/util.js";
import FarmsProvidingChips from "../FarmsProvidingChips.jsx";
import BasketTableHeader from "../Components/HeadersAndRows/BasketTableHeader.jsx";
import CommunityBasketFoodRow from "../Components/HeadersAndRows/CommunityBasketFoodRow.jsx";
import PantryBasketFoodRow from "../Components/HeadersAndRows/PantryBasketFoodRow.jsx";
import BasketFoodRow from "../Components/HeadersAndRows/BasketFoodRow.jsx";
import DistributionFarmTableHeader from "../Components/HeadersAndRows/DistributionFarmTableHeader.jsx";
import BasketFarmRow from "../Components/BasketFarmRow.jsx";
import FarmTotalLine from "../Components/FarmTotalLine.jsx";
import DistributionLocationFarmTotalsLine from "../Components/DistributionLocationFarmTotalsLine.jsx";

export default function DistributionLocationBasket({
  foodItems,
  setFoodItems,
  communityFoodItems,
  setCommunityFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  communityOrders,
  selectedDate,
  importedSelectedDate,
  setImportedSelectedDate,
  importedOrder,
  importingLocation,
  setImportingLocation,
  communityListChanged,
  setCommunityListChanged,
  communityListReset,
  setCommunityListReset,
  farmsRemoved,
  setFarmsRemoved,
  userInfo,
  combinedFarmList,
  farmerInfo,
  setFarmerInfo,
  communityFarmerInfo,
  setCommunityFarmerInfo,
  communityFoodRemove,
  contactMethods,
  customerContribution,
  donationSubsidy,
  modifiedUserPickups,
  setModifiedUserPickups,
  farmTotals,
  pantryTotals,
  pantryCostTotal,
  grandTotal,
  communityOrdersTotal,
  donationAmount,
  communityDonations,
  communitySubsidies,
  netAmountTextFieldColour,
  placingOrder,
  placingBatchesOrder,
  addingToPantry,
  importedAddingToPantry,
  snackBarOpen,
  handleRemoveImportedOrder,
  handleCommunityOrderChange,
  handleDateChange,
  handleImportOrder,
  handleRefresh,
  handleQuantityChange,
  handleMethodSelection,
  handlePlaceOrder,
  handleSnackBarClose,
}) {
  // This contains the order of the imported order
  const [order, setOrder] = useState(null);

  // This is used to update the locations of all the importing locations.  It will
  // add whichever new imported location was added.
  function updateLocation(location, idx) {
    const importingLocationTemp = [...importingLocation];
    importingLocationTemp[idx] = location;
    setImportingLocation([...importingLocationTemp]);
  }

  // This allows a distribution location to add an additional imported location.
  // It presets the location to "Choose a Distribution Location"
  function addImportingLocation() {
    const importingLocationTemp = [...importingLocation];
    importingLocationTemp.push("Choose a Distribution Location");
    setImportingLocation([...importingLocationTemp]);
  }

  // This updates the imported location's selected date.
  function updateImportedSelectedDate(location, date) {
    const importedSelectedDateTemp = { ...importedSelectedDate };
    importedSelectedDateTemp[location] = date;
    setImportedSelectedDate({ ...importedSelectedDateTemp });
  }

  return (
    <div className="Page-Market">
      {/* If your basket is empty or if the user is a distribution Location and
          the community orders or if the pantryFoodItem are empty then tell the user it's empty otherwise
          render the basket items and farms */}
      {foodItems.length !== 0 ||
      communityFoodItems.length !== 0 ||
      pantryFoodItems.length !== 0 ? (
        <div>
          <div>
            <div>
              <span className="Customer-Info-Distribution-Location-Header">
                <h5 style={{ textDecoration: "underline" }}>
                  Community's Orders
                </h5>
                {/* <Button onClick = {() => handleCopy()} >Copy Over</Button> */}
                {Object.keys(communityOrders).length !== 0 && (
                  <CommunityOrdersDialogBasket
                    communityOrders={communityOrders}
                    order={order}
                    location={userInfo.organisationName}
                    handleCommunityOrderChange={handleCommunityOrderChange}
                    selectedDate={selectedDate}
                    communityListReset={communityListReset}
                    setCommunityListReset={setCommunityListReset}
                    modifiedUserPickups={modifiedUserPickups}
                    setModifiedUserPickups={setModifiedUserPickups}
                  />
                )}
                {Object.keys(importedOrder).length !== 0 && (
                  <>
                    {Object.keys(importedOrder).map((location, idx) => (
                      <CommunityOrdersDialogBasket
                        key={idx}
                        communityOrders={
                          importedOrder[location].communityOrders
                        }
                        order={order}
                        location={location}
                        handleCommunityOrderChange={handleCommunityOrderChange}
                        selectedDate={selectedDate}
                        imported={true}
                        importedOrder={importedOrder}
                        importedSelectedDate={importedSelectedDate[location]}
                        communityListReset={communityListReset}
                        setCommunityListReset={setCommunityListReset}
                        handleImportOrder={handleImportOrder}
                        modifiedUserPickups={modifiedUserPickups}
                        setModifiedUserPickups={setModifiedUserPickups}
                      />
                    ))}
                  </>
                )}
              </span>
              <span className="Select-Distribution-Date-Row">
                <p>Select the date of order distribution : </p>
                <MaterialUIPickers
                  required
                  date={selectedDate}
                  handleDateChange={handleDateChange}
                  label="Distribution Date"
                />
              </span>
              <span>
                {importingLocation.map((location, idx) => (
                  <React.Fragment key={idx}>
                    <SelectImportLocation
                      idx={idx}
                      location={location}
                      order={order}
                      updateImportedSelectedDate={updateImportedSelectedDate}
                      updateLocation={updateLocation}
                      updateOrder={setOrder}
                      handleImportOrder={handleImportOrder}
                    />
                    {importingLocation.length > 1 && (
                      <Button
                        onClick={() => handleRemoveImportedOrder(location, idx)}
                      >
                        Remove
                      </Button>
                    )}
                  </React.Fragment>
                ))}
                <Button onClick={() => addImportingLocation()}>
                  Add Imported Location
                </Button>
              </span>
              {communityListChanged && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setCommunityListReset(!communityListReset);
                    setCommunityListChanged(false);
                  }}
                >
                  Reset Customer Orders
                </Button>
              )}
              {farmsRemoved && (
                <Button
                  style={{ margin: "5px" }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setCommunityListReset(!communityListReset);
                    setCommunityListChanged(false);
                    setFoodItems([...userInfo.basketItems]);
                    setFarmsRemoved(false);
                  }}
                >
                  Reset Farms
                </Button>
              )}
              {FarmsProvidingChips(
                combinedFarmList,
                foodItems,
                communityFoodItems,
                setFoodItems,
                pantryFoodItems,
                setPantryFoodItems,
                setCommunityFoodItems,
                setFarmsRemoved,
                setCommunityListChanged,
                farmerInfo,
                communityFarmerInfo,
                setFarmerInfo,
                setCommunityFarmerInfo,
              )}
            </div>
            <BasketTableHeader />
            {/* Cycle through the list of food initialised at the beginning and
                  create the rows */}
            <div>
              {communityFoodItems.length !== 0 ||
              pantryFoodItems.length !== 0 ? (
                <div>
                  {communityFoodItems.map((row, idx) => (
                    <CommunityBasketFoodRow
                      key={row + idx}
                      row={row}
                      checked={row.checked}
                      idx={idx}
                      communityFoodRemove={communityFoodRemove}
                      userType={userInfo.userType}
                      hideButtons={true}
                    />
                  ))}
                  {pantryFoodItems.length !== 0 && (
                    <>
                      <h5 className="Add-Items-Header">
                        Items Taken From the Pantry
                      </h5>
                      {pantryFoodItems.map((row, idx) => (
                        <PantryBasketFoodRow
                          key={row + idx}
                          row={row}
                          checked={row.checked}
                          idx={idx}
                          communityFoodRemove={communityFoodRemove}
                          userType={userInfo.userType}
                          hideButtons={true}
                        />
                      ))}
                    </>
                  )}

                  <h5 className="Add-Items-Header">Add Items to this Order</h5>
                  {/* Cycle through the list of food initialised at the beginning and
                       create the rows */}
                </div>
              ) : (
                <div>
                  <h3>No Community Orders</h3>
                  <h5 className="Add-Items-Header">Add Items to this Order</h5>
                </div>
              )}
            </div>
            {foodItems.length !== 0 ? (
              <span>
                {foodItems.map((row, idx) => (
                  <BasketFoodRow
                    key={row + idx}
                    row={row}
                    checked={row.checked}
                    idx={idx}
                    handleRefresh={handleRefresh}
                    handleQuantityChange={handleQuantityChange}
                    userType={userInfo.userType}
                  />
                ))}
              </span>
            ) : (
              <h3>Nothing added to this order</h3>
            )}

            <h3
              className="Header-3"
              style={{ textDecoration: "underline", marginBottom: "0px" }}
            >
              Farms Providing this Order
            </h3>
            <span className="Farm-Pickup-Location-Info">
              <span className="Farm-Headers-Farm-Rows">
                <DistributionFarmTableHeader />

                {combinedFarmList.length !== 0 ? (
                  <div>
                    {/* Cycle through the list of Farms and create the rows */}
                    {combinedFarmList.map((farm, index) =>
                      BasketFarmRow(
                        farm,
                        handleMethodSelection,
                        contactMethods[farm.farmName],
                        index,
                        userInfo,
                      ),
                    )}
                  </div>
                ) : (
                  <h3 className="Header-3"> No farm data available! </h3>
                )}
              </span>
            </span>
          </div>

          {/* Checkout, Basket total amount and place order button */}

          <div className="Checkout">
            <div className="Totals-And-Button">
              {userInfo.userType === "distributionLocation" ? (
                <DistributionLocationFarmTotalsLine
                  farmTotals={farmTotals}
                  combinedFarmList={combinedFarmList}
                  userType={userInfo.userType}
                  grandTotal={grandTotal}
                  pantryTotals={pantryTotals}
                />
              ) : (
                <>
                  {Object.keys(farmTotals).map((farmTotal, index) =>
                    FarmTotalLine(
                      farmTotals,
                      farmTotal,
                      combinedFarmList,
                      userInfo.userType,
                    ),
                  )}

                  <p>
                    <b>Total </b>: ${grandTotal.toFixed(2)}
                  </p>
                </>
              )}

              <ConfirmOrderDialog
                message="Place Order"
                placeOrder={handlePlaceOrder}
                userInfo={userInfo}
                farmTotals={farmTotals}
                farmerInfo={combinedFarmList}
                grandTotal={grandTotal}
                customerContribution={customerContribution}
                donationSubsidy={donationSubsidy}
              />
            </div>
            <div className="Distribution-Donation-Subsidy-Totals">
              <p>
                <b>Customer Orders </b>: ${communityOrdersTotal}
              </p>
              <p>
                <b>Sold Pantry Goods </b>: ${pantryCostTotal.toFixed(2)}
              </p>
              <p>
                <b>Added to Pantry </b>: ${donationAmount}
              </p>
            </div>
            <div className="Distribution-Donation-Subsidy-Totals">
              <p style={{ color: "green" }}>
                <b>Donations Contributed </b>:{" "}
                {formatDollars(communityDonations)}
              </p>
              <p style={{ color: "red" }}>
                <b>Subsidies Used </b>: {formatDollars(communitySubsidies)}
              </p>
              <p style={{ color: netAmountTextFieldColour }}>
                <b>Net Amount </b>:{" "}
                {formatDollars(communityDonations - communitySubsidies)}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span>
            <span className="Select-Distribution-Date-Row">
              <p>Select the date of order distribution : </p>
              <MaterialUIPickers
                required
                date={selectedDate}
                handleDateChange={handleDateChange}
                label="Distribution Date"
              />
            </span>
            <span>
              {importingLocation.map((location, idx) => (
                <SelectImportLocation
                  key={idx}
                  idx={idx}
                  location={location}
                  order={order}
                  updateImportedSelectedDate={updateImportedSelectedDate}
                  updateLocation={updateLocation}
                  updateOrder={setOrder}
                  handleImportOrder={handleImportOrder}
                />
              ))}
              <Button onClick={() => addImportingLocation()}>
                Add Imported Location
              </Button>
            </span>
          </span>
          {communityListChanged && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setCommunityListReset(!communityListReset);
                setCommunityListChanged(false);
              }}
            >
              Reset Customer Orders
            </Button>
          )}
          {farmsRemoved && (
            <Button
              style={{ margin: "5px" }}
              color="primary"
              variant="contained"
              onClick={() => {
                setCommunityListReset(!communityListReset);
                setCommunityListChanged(false);
                setFoodItems([...userInfo.basketItems]);
                setFarmsRemoved(false);
              }}
            >
              Reset Farms
            </Button>
          )}
          <h3 className="Header-3"> No items in your Basket yet! </h3>
        </div>
      )}
      <PlacingOrderDialog open={placingOrder} />
      <PlacingOrderBatchesDialog open={placingBatchesOrder} />
      <AddingToPantryDialog open={addingToPantry && importedAddingToPantry} />
      <CustomizedSnackbars
        message="Your order has been placed"
        open={snackBarOpen}
        handleClose={handleSnackBarClose}
      />
    </div>
  );
}
