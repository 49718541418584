// This is to remove a foodItem from a community or imported order.  It is done
// from the Basket Page.
import firebase from "./../Firebase.js";
import VolunteerFeeAdjustment from "./VolunteerFeeAdjustment.js";

export default function RemoveFoodItemCommunityOrdersBasket(
  userOrder,
  handleCommunityOrderChange,
  user,
  imported,
  importedOrder,
  location,
  selectedDate,
  indexOfItem,
  userInfo,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // Since user has the date removed it needs to be added back so we can find
  // the user in the database for the specific order
  // let userKey = user.concat("-" + userOrder.orderDate)

  // This contains an array of foodItems that the user ordered
  const foodListTemp = userOrder.foodList;

  // This contains an array of farmers that the user ordered from
  const farmerListTemp = userOrder.farmerList;

  const foodItemToRemove = foodListTemp[indexOfItem];

  // We use this to calculate the new cash and credit portions of the volunteer fee after the
  // adjustments.
  // eslint-disable-next-line no-unused-vars
  const [userCashPortion, userCreditPortion, volunteerFeeChange] =
    VolunteerFeeAdjustment(
      userOrder,
      userId[2],
      userInfo,
      foodItemToRemove,
      database,
      batch,
      true,
    );

  // If the distribution location user is modifiying an imported user then...
  if (imported) {
    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(importedOrder[location].date);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(selectedDate);
  }

  // Remove the food Item from the foodList
  foodListTemp.splice(indexOfItem, 1);

  // Check to see if removing this item also means the farm is no longer included
  // in this user's order and splice them out if so.
  farmerListTemp.forEach((farm, indexOfFarm) => {
    const indexExists = foodListTemp.findIndex((foodItem) => {
      return foodItem.farmName === farm.farmName;
    });
    if (indexExists === -1) {
      farmerListTemp.splice(indexOfFarm, 1);
    }
  });

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  batch.update(communityOrdersDocRef, {
    [user.concat(".foodList")]: [...foodListTemp],
    [user.concat(".farmerList")]: [...farmerListTemp],
    [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
    [user.concat(".creditPortion")]: userCreditPortion.toFixed(2),
  });

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userDocRef, {
    foodList: [...foodListTemp],
    farmerList: [...farmerListTemp],
    cashPortion: userCashPortion.toFixed(2),
    creditPortion: userCreditPortion.toFixed(2),
  });
  batch.commit();

  // Update the user order so that we can rerender it.
  const userOrderTemp = { ...userOrder };
  userOrderTemp.foodList = [...foodListTemp];
  userOrderTemp.farmerList = [...farmerListTemp];
  userOrderTemp.cashPortion = userCashPortion.toFixed(2);
  userOrderTemp.creditPortion = userCreditPortion.toFixed(2);

  // Sends updated values to rerender the screen with the correct information
  handleCommunityOrderChange(
    imported,
    [...foodListTemp],
    [...farmerListTemp],
    user,
    null,
    null,
    location,
    null,
    userOrderTemp,
  );

  console.log("Successfully wrote to database!");
}
