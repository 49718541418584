// This is used to add an item to either an order placed by a distribution location,
// change the community or imported orders on the MyAccount Page or on the Basket
// Page.
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddFoodItem from "./../ModifyOrders/AddFoodItem.js";
import AddFoodItemCommunityOrdersBasket from "./../ModifyOrders/AddFoodItemCommunityOrdersBasket.js";
import AddFoodItemCommunityOrders from "./../ModifyOrders/AddFoodItemCommunityOrders.js";
import AddFoodItemCommunityOrdersOld from "./../ModifyOrders/AddFoodItemCommunityOrdersOld.js";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoadingContent from "./../LoadingContent.jsx";
import LoadMarketplaceItems from "./../../functions/LoadMarketplaceItems.js";
import FoodItemSelect from "./../userInputs/FoodItemSelect.jsx";
import FarmCheckboxes from "./../userInputs/FarmCheckboxes.jsx";

export default function AddItemToOrderDialog(props) {
  const [open, setOpen] = useState(false);
  const [distributionQuantity, setDistributionQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [individualQuantity, setIndividualQuantity] = useState("");
  const [individualDescription, setIndividualDescription] = useState("");
  const [suggestedPrice, setSuggestedPrice] = useState(false);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [farmName, setFarmName] = useState("");
  const [item, setItem] = useState("");
  const [limit, setLimit] = useState("");
  const [packageFee, setPackageFee] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  // The list of all the food Items available.
  const [foodItemsToSelect, setFoodItemsToSelect] = useState([]);
  // The foodItems that are being shown in case the user has selected
  // a farm to be removed.
  const [displayedFoodItemsToSelect, setDisplayedFoodItemsToSelect] = useState(
    [],
  );

  // The farmers that exist and provide the foodItems.
  const [farmersAvailable, setFarmersAvailable] = useState([]);
  // The index of the selected FoodItem that the user is adding.  The index
  // is of the displayedFoodItems.
  const [indexOfSelectedItem, setIndexOfSelectedItem] = useState("");

  // Load the foodItems that are available for the user to select
  // from to add if they would like to autofill.
  useEffect(() => {
    // If the length is 0 then we haven't loaded the foodItems yet.
    // We want it to be open so that we don't end up with a bunch
    // of loads when we only need to do it once when the user opens.
    if (foodItemsToSelect.length === 0 && open) {
      // Load foodItemsToSelect
      LoadMarketplaceItems(
        null,
        1000,
        true,
        {},
        setFoodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        null,
        setLoading,
        props.userInfo,
        {},
      );
    }
    // If the length is greater than 0 then we have already loaded
    // the foodItems and can just set loading to true.
    else if (foodItemsToSelect.length > 0 && open) {
      setLoading(true);
    }
  }, [open]);

  // Once we have the list of foodItems then we have to set the list
  // of farmers available so the user can toggle the items from each farm
  // on or off.
  useEffect(() => {
    //  Make sure the foodItems exist and that the user has opened
    // the dialog.
    if (foodItemsToSelect.length > 0 && open) {
      const farmersAvailableTemp = [];
      // Cycle through all the foodItems and add the farms
      // to the list.  Some items will come from the same
      // farm so check if it's already been added.
      foodItemsToSelect.forEach((foodItem) => {
        if (!farmersAvailableTemp.includes(foodItem.farmName)) {
          farmersAvailableTemp.push(foodItem.farmName);
        }
      });
      farmersAvailableTemp.sort();
      setFarmersAvailable([...farmersAvailableTemp]);
    }
  }, [foodItemsToSelect, open]);

  const handleClickOpen = () => {
    setOpen(true);
    setIndexOfSelectedItem("");
    // Set the displayed foodItems to nothing so that the user
    // has to select which farmers to show otherwise it's just too
    // many items to show the user at once.
    setDisplayedFoodItemsToSelect([]);
  };

  const handleClose = () => {
    setOpen(false);
    setDistributionQuantity("");
    setDescription("");
    setIndividualQuantity("");
    setIndividualDescription("");
    setSuggestedPrice(false);
    setPrice("");
    setFarmName("");
    setItem("");
    setLimit("");
    setPackageFee(0);
    setIndexOfSelectedItem("");
    setDisplayedFoodItemsToSelect([]);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // the state of this dialog is all the fields of the foodItem.
    const state = {
      open,
      distributionQuantity,
      description,
      individualQuantity,
      individualDescription,
      suggestedPrice,
      price,
      quantity,
      farmName,
      item,
      limit,
      packageFee,
      selectedDate,
    };

    // If the userOrder is undefined then we know that we are adding to the distribution
    // location's order.  If handleCommunityOrderChange is defined we know the call came
    // from Basket and so handle it from there otherwise it's coming from a community or
    // imported order.
    if (props.userOrder === undefined) {
      AddFoodItem(
        state,
        props.order,
        props.userInfo,
        props.orderLog,
        props.updateOrderLog,
        props.orderIndex,
      );
    } else if (props.handleCommunityOrderChange !== undefined) {
      // With the introduction of the multiple imported orders there is new way to
      // interact with the orders.
      AddFoodItemCommunityOrdersBasket(
        props.idx,
        props.userOrder,
        props.user,
        props.imported,
        props.importedOrder,
        props.location,
        props.selectedDate,
        state,
        props.userInfo,
        props.handleCommunityOrderChange,
      );
    } else {
      // With the introduction of the multiple imported orders there is new way to
      // interact with the orders.
      if (props.order.orderDate > "202106150000000000000") {
        AddFoodItemCommunityOrders(
          props.idx,
          props.userOrder,
          props.user,
          props.imported,
          props.order,
          state,
          props.userInfo,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
          props.location,
          props.updatePaymentHistory,
        );
      } else {
        AddFoodItemCommunityOrdersOld(
          props.idx,
          props.userOrder,
          props.user,
          props.imported,
          props.order,
          state,
          props.userInfo,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
        );
      }
    }

    setOpen(false);
    setDistributionQuantity("");
    setDescription("");
    setIndividualQuantity("");
    setIndividualDescription("");
    setSuggestedPrice(false);
    setPrice("");
    setFarmName("");
    setItem("");
    setLimit("");
    setPackageFee(0);
    setQuantity("");
    setSelectedDate(new Date());
  };

  const handleChange = (e) => {
    const target = e.target;
    const type = target.type;

    // If we are changing the switch that says the suggestedPrice is on or off then
    // we will update the suggestedPrice.
    if (type === "checkbox") {
      // Set the suggestedPrice to false.
      let suggestedPriceBool = false;
      // If the suggestedPrice is already true set to true.
      if (suggestedPrice) {
        suggestedPriceBool = true;
      }
      // Swap the suggestedPrice boolean.
      setSuggestedPrice(!suggestedPriceBool);
    }
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        ADD ITEM
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Fill in information about the item
        </DialogTitle>

        {!loading ? (
          <LoadingContent inline={true} />
        ) : (
          <DialogContent>
            <form onSubmit={handleSave}>
              <FoodItemSelect
                displayedFoodItemsToSelect={displayedFoodItemsToSelect}
                setDistributionQuantity={setDistributionQuantity}
                setDescription={setDescription}
                setIndividualQuantity={setIndividualQuantity}
                setIndividualDescription={setIndividualDescription}
                setSuggestedPrice={setSuggestedPrice}
                setPrice={setPrice}
                setQuantity={setQuantity}
                setFarmName={setFarmName}
                setItem={setItem}
                setLimit={setLimit}
                setPackageFee={setPackageFee}
                indexOfSelectedItem={indexOfSelectedItem}
                setIndexOfSelectedItem={setIndexOfSelectedItem}
              />
              <FarmCheckboxes
                farmersAvailable={farmersAvailable}
                foodItemsToSelect={foodItemsToSelect}
                displayedFoodItemsToSelect={displayedFoodItemsToSelect}
                setDisplayedFoodItemsToSelect={setDisplayedFoodItemsToSelect}
                setIndexOfSelectedItem={setIndexOfSelectedItem}
              />
              <span>
                <TextField
                  margin="dense"
                  id="item"
                  label="Item"
                  name="item"
                  value={item}
                  onChange={(e) => setItem(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="distributionQuantity"
                  label="Distribution Quantity"
                  name="distributionQuantity"
                  type="number"
                  value={distributionQuantity}
                  onChange={(e) => setDistributionQuantity(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="description"
                  label="Distribution Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualQuantity"
                  label="Individual Quantity"
                  name="individualQuantity"
                  type="number"
                  value={individualQuantity}
                  onChange={(e) => setIndividualQuantity(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualDescription"
                  label="Individual Description"
                  name="individualDescription"
                  value={individualDescription}
                  onChange={(e) => setIndividualDescription(e.target.value)}
                  fullWidth
                  required
                />
                <FormControlLabel
                  label="Suggested Price"
                  control={
                    <Switch
                      checked={suggestedPrice}
                      name="suggestedPrice"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <TextField
                  margin="dense"
                  id="price"
                  label="Price ($CAD)"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="farmName"
                  name="farmName"
                  label="Supplying Farm"
                  value={farmName}
                  onChange={(e) => setFarmName(e.target.value)}
                  fullWidth
                  required
                />
                {props.userOrder !== undefined && (
                  <TextField
                    margin="dense"
                    id="limit"
                    name="limit"
                    label="Remaining in Pantry"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    type="number"
                    fullWidth
                  />
                )}
                <TextField
                  margin="dense"
                  id="packageFee"
                  label="Package Fee"
                  name="packageFee"
                  value={packageFee}
                  onChange={(e) => setPackageFee(e.target.value)}
                  type="number"
                  fullWidth
                  required
                />
                {props.userOrder === undefined && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </span>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
