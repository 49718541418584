// This class is used to update the a marketplace item from the admin's marketplace.
import React from "react";
import firebase from "./../Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SimpleSelect from "./../userInputs/DropDownMenu.js";
import ModifyCategoryAtDistributionLocation from "../../functions/ModifyCategoryAtDistributionLocation.js";
import HandleRefresh from "./../../pages/MarketplacePage/Functions/HandleRefresh.js";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CreateFarmCategories from "../../functions/CreateFarmCategories.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class MarketplaceEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      distributionQuantity: this.props.distributionQuantity,
      description: this.props.description,
      individualQuantity: this.props.individualQuantity,
      individualDescription: this.props.individualDescription,
      minimumPurchase: this.props.minimumPurchase,
      suggestedPrice: this.props.suggestedPrice,
      price: this.props.price,
      category: this.props.category,
      item: this.props.item,
      displayImage: this.props.displayImage,
      selectedFarm: this.props.farmName,
      farms: this.props.farms,
      farmLimit: this.props.farmLimit,
      guaranteedMaxQuantity: this.props.guaranteedMaxQuantity,
      packageFee: this.props.packageFee,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
      distributionQuantity: this.props.distributionQuantity,
      description: this.props.description,
      individualQuantity: this.props.individualQuantity,
      individualDescription: this.props.individualDescription,
      minimumPurchase: this.props.minimumPurchase,
      suggestedPrice: this.props.suggestedPrice,
      price: this.props.price,
      category: this.props.category,
      item: this.props.item,
      displayImage: this.props.displayImage,
      selectedFarm: this.props.farmName,
      farmLimit: this.props.farmLimit,
      guaranteedMaxQuantity: this.props.guaranteedMaxQuantity,
      packageFee: this.props.packageFee,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      minimumPurchase: this.state.minimumPurchase,
      item: this.state.item,
      packageFee: this.state.packageFee,
      displayImage: this.state.displayImage,
      selectedFarm: this.state.selectedFarm,
      suggestedPrice: this.state.suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      farmLimit: this.state.farmLimit,
      guaranteedMaxQuantity: this.state.guaranteedMaxQuantity,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    let documentName = "";

    documentName = (
      this.state.item +
      this.state.description +
      this.state.selectedFarm
    ).replace(/\s+/g, "");

    // Checks to see if the user gave an image link.  If there isn't one than
    // make it an empty string so it can be saved in the database.
    let displayImage = this.state.displayImage;
    if (displayImage === undefined) {
      displayImage = "";
    }
    // In case this field isn't set then we will set it to false.
    let suggestedPrice = this.state.suggestedPrice;
    if (suggestedPrice === undefined) {
      suggestedPrice = false;
    }

    // Updates the foodItem document.
    const docRef = database.collection("FoodItems").doc(documentName);
    const item = {
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      minimumPurchase: this.state.minimumPurchase,
      suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      farmName: this.state.selectedFarm,
      item: this.state.item,
      packageFee: this.state.packageFee,
      displayImage,
      userId: firebase.auth().currentUser.uid,
      userType: this.props.userInfo.userType,
    };
    // Checks to see if farmLimit exists.  If it does then add it to the item
    // otherwise this item won't have a farmLimit.
    if (this.state.farmLimit) {
      item.farmLimit = parseFloat(this.state.farmLimit);
    }
    // Check if the guaranteedMax Quantity field exists if it does then
    // we want to add it to the item.
    if (this.state.guaranteedMaxQuantity > 0 && this.state.farmLimit > 0) {
      item.guaranteedMaxQuantity = parseInt(this.state.guaranteedMaxQuantity);
    }

    batch.set(docRef, item);
    batch.commit();

    // Checks to see if the name, description or farmName have changed.  Since we
    // save items in the database using these properties we'll have to delete the item
    // and make a new one.
    if (
      this.state.item !== this.props.item ||
      this.state.description !== this.props.description ||
      this.state.selectedFarm !== this.props.farmName
    ) {
      const deleteDocumentName = (
        this.props.item +
        this.props.description +
        this.props.farmName
      ).replace(/\s+/g, "");

      database
        .collection("FoodItems")
        .doc(deleteDocumentName)
        .delete()
        .then(function () {})
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    }

    // Resets the state of the dialog.
    this.setState({
      open: false,
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      minimumPurchase: this.state.minimumPurchase,
      item: this.state.item,
      packageFee: this.state.packageFee,
      displayImage: this.state.displayImage,
      suggestedPrice: this.state.suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      farmLimit: this.state.farmLimit,
      guaranteedMaxQuantity: this.state.guaranteedMaxQuantity,
    });

    // Finds the index of the item to allow for HandleRefresh to modify the correct
    // foodItem.  It uses the props instead of the state in case the user changed
    // those values and it can still be found.
    const index = this.props.foodItems.findIndex((foodItem) => {
      return (
        foodItem.description === this.props.description &&
        foodItem.farmName === this.props.farmName &&
        foodItem.item === this.props.item
      );
    });

    // HandleRefresh returns an updated foodItems list.
    const foodItemsNew = HandleRefresh(
      this.props.foodItems,
      this.props.setFoodItems,
      item,
      false,
      true,
      index,
    );

    // Checks if the user changed the category or the farm because either could
    // trigger a farmer category array change.
    if (
      this.state.category !== this.props.category ||
      this.state.selectedFarm !== this.props.farmName
    ) {
      // sends in the newFoodItems list creates the new farm categories.
      const newFarmCategories = CreateFarmCategories(foodItemsNew);

      // Sends in the updated farmCategories, the farm new farm and the old farm.
      // If the user changed the category and not the farm then both farms will
      // be the same.
      ModifyCategoryAtDistributionLocation(
        newFarmCategories,
        this.state.selectedFarm,
        this.props.farmName,
      );
    }

    console.log("Successfully wrote to database!");
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const type = target.type;

    // If we are changing the switch that says the suggestedPrice is on or off then
    // we will update the suggestedPrice.
    if (type === "checkbox") {
      // Set the suggestedPrice to false.
      let suggestedPriceBool = false;
      // If the suggestedPrice is already true set to true.
      if (this.state.suggestedPrice) {
        suggestedPriceBool = true;
      }
      // Swap the suggestedPrice boolean.
      this.setState({
        suggestedPrice: !suggestedPriceBool,
      });
    }
    // If name is defined then we know that it is any element other than the selectedFarm
    // which is a drop down menu and has no name.
    else if (name !== undefined) {
      if (
        (name === "item" ||
          name === "description" ||
          name === "individualDescription") &&
        (value.includes("-") || value.includes(".") || value.includes("/"))
      ) {
        alert(`Please do not use the symbols "-, . or /"`);
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        selectedFarm: value,
      });
    }
  }

  render() {
    return (
      <div>
        <IconButton onClick={this.handleClickOpen}>
          <EditIcon />
        </IconButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Fill in information about the item
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              <span>
                <TextField
                  margin="dense"
                  id="item"
                  label="Item"
                  name="item"
                  value={this.state.item}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="distributionQuantity"
                  label="Distribution Quantity"
                  name="distributionQuantity"
                  type="number"
                  value={this.state.distributionQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="description"
                  label="Distribution Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualQuantity"
                  label="Individual Quantity"
                  name="individualQuantity"
                  type="number"
                  value={this.state.individualQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualDescription"
                  label="Individual Description"
                  name="individualDescription"
                  value={this.state.individualDescription}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="minimumPurchase"
                  label="Minimum Purchase Quantity"
                  name="minimumPurchase"
                  type="number"
                  value={this.state.minimumPurchase}
                  onChange={this.handleChange}
                  fullWidth
                />
                <FormControlLabel
                  label="Suggested Price"
                  control={
                    <Switch
                      checked={this.state.suggestedPrice}
                      name="suggestedPrice"
                      onChange={this.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <TextField
                  margin="dense"
                  id="price"
                  label="Price ($CAD)"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="category"
                  label="Category"
                  name="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="displayImage"
                  label="Display Image"
                  name="displayImage"
                  value={this.state.displayImage}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="farmLimit"
                  label="Farm Limit"
                  name="farmLimit"
                  type="number"
                  value={this.state.farmLimit}
                  onChange={this.handleChange}
                  fullWidth
                />
                {this.state.farmLimit > 0 && (
                  <TextField
                    margin="dense"
                    id="guaranteedMaxQuantity"
                    label="Guaranteed Max Quantity"
                    name="guaranteedMaxQuantity"
                    type="number"
                    value={this.state.guaranteedMaxQuantity}
                    onChange={this.handleChange}
                    fullWidth
                  />
                )}
                <TextField
                  margin="dense"
                  id="packageFee"
                  label="Package Fee"
                  name="packageFee"
                  value={this.state.packageFee}
                  onChange={this.handleChange}
                  type="number"
                  fullWidth
                />
                <SimpleSelect
                  margin="dense"
                  id="farmName"
                  title="Supplying Farm"
                  options={this.state.farms}
                  handleChange={this.handleChange}
                  selected={this.state.selectedFarm}
                  required
                />
              </span>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
