// The card that holds the food item's information on the marketplace page.
import React from "react";
import ItemDetails from "./ItemDetails";
import MarketplaceIncrCounter from "./FoodElement/MarketplaceIncrCounter.jsx";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MarketplaceEditForm from "../../../components/userForms/MarketplaceEditForm.jsx";
import RemoveFoodItemDialog from "./FoodElement/RemoveFoodItemDialog.jsx";

export default function FoodCard({
  displayImage,
  index,
  row,
  currentUser,
  userInfo,
  handleUserInfoChange,
  displayedFoodItems,
  setDisplayedFoodItems,
  incrementItem,
  decreaseItem,
  handleAddToBasket,
  updateButton,
  visible,
  handleSwitch,
  handleDelete,
  foodItems,
  setFoodItems,
  farmCategories,
}) {
  // Says that there is a limit on this item or not.  Default to no limit
  let limited = false;
  // The quantity of items left in the pantry.
  let limitQuantity = 0;
  // Says that there is a limit set by the farmer on this item or not.  Default to no limit
  let farmLimited = false;
  // The quantity of items that the farmer has.
  let farmLimitQuantity = 0;
  // If the item has a limit then set it to true.  If there is no limit then check
  // if there is a limit set by the farmer.  If the farmLimit exists or if it is 0 then we know that
  // the item is limited.
  if (row.limit) {
    limited = true;
    // If the user is an individual then we set the limit based off the individual
    // quantities.
    if (userInfo.userType === "individual") {
      limitQuantity = Math.floor(row.limit - row.quantity);
    }
    // Otherwise we set to false because the non individual users don't have pantry
    // limits on items.
    else {
      limited = false;
    }
  }
  // If there are no pantry limits check to see if there is a farm limit or if it
  // is 0.
  else if (!!row.farmLimit || row.farmLimit === 0) {
    farmLimited = true;
    // If the user is an individual then we set the limit based off the individual
    // quantities.
    if (userInfo.userType === "individual") {
      farmLimitQuantity = Math.floor(row.farmLimit - row.quantity);
    }
    // If the user is an distributionLocation then we set the limit based off the
    // distributionLocation quantities.
    else {
      farmLimitQuantity =
        (row.farmLimit * row.individualQuantity) / row.distributionQuantity -
        row.quantity;
    }
  }

  // The index of the foodItem in the user's basket. Which is used to show the user
  // how many items they have in their basket.
  let basketFoodItemQuantity = 0;

  // Checks is the user is signed in and if they have any items in their basket.
  if (!!currentUser && userInfo.basketItems.length > 0) {
    // Looks for the current foodItem in the user's basket.
    const basketFoodItemIndex = userInfo.basketItems.findIndex(
      (basketFoodItem) => {
        return (
          basketFoodItem.item === row.item &&
          basketFoodItem.individualDescription === row.individualDescription &&
          basketFoodItem.farmName === row.farmName
        );
      },
    );
    // If the item exists then set the quantity to the amount in the user's basket.
    if (basketFoodItemIndex !== -1) {
      basketFoodItemQuantity =
        userInfo.basketItems[basketFoodItemIndex].quantity;
    }
    // If the index doesn't exist then set the value to 0.
    else {
      basketFoodItemQuantity = 0;
    }
  }

  return (
    <div className="Food-Element">
      {(userInfo.userType === "admin" ||
        userInfo.userType === "distributionLocation") && (
        <div style={{ display: "flex" }}>
          <FormControlLabel
            label="Visible"
            control={
              <Switch
                checked={visible}
                onChange={(e) => handleSwitch(e)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
          />
          {userInfo.userType === "admin" && (
            <>
              <RemoveFoodItemDialog handleDelete={handleDelete} />
              <MarketplaceEditForm
                foodItems={foodItems}
                setFoodItems={setFoodItems}
                farms={userInfo.farms}
                userInfo={userInfo}
                farmCategories={farmCategories}
                distributionQuantity={row.distributionQuantity}
                description={row.description}
                individualQuantity={row.individualQuantity}
                individualDescription={row.individualDescription}
                minimumPurchase={row.minimumPurchase}
                suggestedPrice={row.suggestedPrice}
                price={row.price}
                packageFee={row.packageFee}
                category={row.category}
                farmName={row.farmName}
                item={row.item}
                displayImage={row.displayImage}
                farmLimit={row.farmLimit}
                guaranteedMaxQuantity={row.guaranteedMaxQuantity}
              />
            </>
          )}
        </div>
      )}

      <div className="Image-Limited-Box">
        {limited && (
          <p className="Marketplace-Limited-Remaining">{limitQuantity} left</p>
        )}
        {farmLimited && (
          <p className="Marketplace-Limited-Remaining">
            {farmLimitQuantity} left
          </p>
        )}
        <div>
          {farmLimited && farmLimitQuantity <= 0 && row.quantity === 0 && (
            <img
              className="Sold-Out-Sign"
              alt="Sold Out"
              src="https://lh3.googleusercontent.com/pw/AIL4fc8wDO53_3hHMvf-w3LmonU0IiJ7wIN6E13KrEIxyQGIKIaK8LR2KlJX-IUdtv0QQMqUDN-vj0avD16iWvp0DRHJjqbgzwM6Nj8kF5FiTjSqJKK19zdVQpU0P-mCWSOpPBFXiZggM2aMVbDAfGlhV0LE=w512-h512-s-no?authuser=1"
              referrerPolicy="no-referrer"
            />
          )}
          <img
            className="Food-Image"
            alt="Food"
            src={displayImage}
            referrerPolicy="no-referrer"
          />
        </div>
      </div>

      <ItemDetails
        key={index}
        index={index}
        row={row}
        currentUser={currentUser}
        userInfo={userInfo}
        handleUserInfoChange={handleUserInfoChange}
        displayedFoodItems={displayedFoodItems}
        setDisplayedFoodItems={setDisplayedFoodItems}
      />
      {!(farmLimited && farmLimitQuantity <= 0 && row.quantity === 0) && (
        <>
          {currentUser ? (
            <>
              <MarketplaceIncrCounter
                quantity={row.quantity}
                incrementItem={incrementItem}
                decreaseItem={decreaseItem}
              />
              <p className="Quantities-In-Basket">
                {basketFoodItemQuantity} in cart
              </p>
              {row.quantity > 0 && (
                <button className="Add-To-Basket" onClick={handleAddToBasket}>
                  {updateButton}
                </button>
              )}
            </>
          ) : (
            <>
              <span className="Sign-In-Sign-Out-Buttons">
                <Link style={{ textDecoration: "none" }} to="/SignIn">
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(225 119 245)",
                      color: "white",
                      width: "90px",
                      margin: "8px 5px",
                    }}
                  >
                    Sign In
                  </Button>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/SignUp">
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(159, 191, 89)",
                      color: "white",
                      width: "90px",
                      margin: "8px 5px",
                    }}
                  >
                    Sign Up
                  </Button>
                </Link>
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
}
