// This is to show an entire distribution location's order.
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SortWholeCommunityOrders from "../Functions/SortWholeCommunityOrders.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import WholeOrderAccordion from "../Components/Accordions/WholeOrderAccordion.jsx";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function ViewCommunityOrdersBox({
  open,
  onClose,
  customerFoodListPerLocation,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // This is to show the button or not.  It was implemented to allow for copy and
  // pasting to not have the big button in it.
  const [showCustomersButton, setShowCustomersButton] = useState(true);

  // We want to sort the distribution locations alphabetically so they appear in the same order.
  const sortedDistributionLocations = Object.keys(
    customerFoodListPerLocation,
  ).sort();

  // We want to sort the pickupLocations alphabetically but with the Onsite appearing first.
  const sortedPickupLocations = {};

  // Cycle through the distribution locations.
  sortedDistributionLocations.forEach((distributionLocation) => {
    // Sort the pickupLocations
    sortedPickupLocations[distributionLocation] = Object.keys(
      customerFoodListPerLocation[distributionLocation],
    ).sort();
    // Find the index of the Onsite pickupLocation in the array.
    const indexOfOnsite =
      sortedPickupLocations[distributionLocation].indexOf("Onsite");
    // If it exists then
    if (indexOfOnsite !== -1) {
      // remove it
      sortedPickupLocations[distributionLocation].splice(indexOfOnsite, 1);
      // and add it back to the start of the array.
      sortedPickupLocations[distributionLocation].unshift("Onsite");
    }
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Whole Community Orders By Location
        <FormControlLabel
          label="Toggle Customer Buttons"
          control={
            <Switch
              checked={showCustomersButton}
              onChange={() => setShowCustomersButton(!showCustomersButton)}
            />
          }
        />
      </DialogTitle>
      <DialogContent>
        {sortedDistributionLocations.map((distributionLocation, i) => (
          <>
            {sortedPickupLocations[distributionLocation].map(
              (pickupLocation, i) => (
                <WholeOrderAccordion
                  key={pickupLocation}
                  distributionLocation={distributionLocation}
                  pickupLocation={pickupLocation}
                  customerFoodListPerLocation={customerFoodListPerLocation}
                  showCustomersButton={showCustomersButton}
                />
              ),
            )}
          </>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ViewCommunityOrdersDialog({
  communityOrders,
  importedOrder,
  organisationName,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Send the community orders and the imported orders to be put into one list where
  // the food items are per location and the pantry items are per location.
  const customerFoodListPerLocation = SortWholeCommunityOrders(
    communityOrders,
    importedOrder,
    organisationName,
  );

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        VIEW THE COMMUNITY&quot;S ORDER AS A WHOLE
      </Button>

      <ViewCommunityOrdersBox
        open={open}
        onClose={handleClose}
        customerFoodListPerLocation={customerFoodListPerLocation}
      />
    </div>
  );
}
