// This is C2/3 dialogs used by distribution locations to send their customers a
// order payment reminder email.   It displays all the users that can be emailed,
// select a greeting, and allows for the message to be customized.
// The user can't move to the final state until they've selected users to email and
// written a message.
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

export default function OrderPaymentReminderDialog({
  userEmails,
  openOrderPaymentReminderDialog,
  setOpenOrderPaymentReminderDialog,
  stateOfDialog,
  setStateOfDialog,
  checkList,
  setCheckList,
  greeting,
  setGreeting,
  message,
  setMessage,
}) {
  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenOrderPaymentReminderDialog(false);
    setStateOfDialog("selectAction");
    setCheckList({});
  };

  // If the user moves to the next state update it.
  const handleNext = (value) => {
    setOpenOrderPaymentReminderDialog(false);
    setStateOfDialog(value);
  };

  // When the user changes the checklist
  const handleChange = (e) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
  };

  // When the user changes the greeting option.
  const handleGreetingChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    const target = e.target;
    setGreeting(e.target.value);
  };

  // When the user changes the customized message.
  const handleMessageChange = (e) => {
    let value = e.target.value;
    if (value.slice(-1) === "\n") {
      value = value.slice(0, -1);
      value = value.concat("<br/>");
    }
    setMessage(value);
  };

  // This use effect sets the message to a message about the order payment reminder.
  useEffect(() => {
    if (stateOfDialog === "OrderPaymentReminder") {
      setMessage(`Thank you for placing your order and we hope that everything you received was as you expected!
        We are just connecting as our records show we are missing payment from you.<br /><br />
        No worries about any delay but, please let us know if you have any concerns about your payment.  Thank
        you again for ordering!<br />`);
    }
  }, [stateOfDialog]);

  // Since we don't want the user moving to the next state until they've selected
  // users and created a message then we just check to make sure those conditions are
  // met and then set this variable to true which displays the next button.
  let nextButtonVisible = false;
  if (message !== "") {
    Object.keys(checkList).forEach((user) => {
      if (checkList[user]) {
        nextButtonVisible = true;
      }
    });
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openOrderPaymentReminderDialog}
      >
        <DialogTitle id="simple-dialog-title">
          What Would You Like To Do?
        </DialogTitle>
        <DialogContent>
          <List>
            <FormLabel component="legend">
              Select Users to Email Payment Reminders
            </FormLabel>
            <FormGroup>
              {Object.keys(userEmails).map((location, ind) => (
                <React.Fragment key={ind}>
                  <h6>
                    <u>{location}</u>
                  </h6>
                  {Object.keys(userEmails[location]).map((user, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={user}
                          value={checkList[user]}
                          onChange={handleChange}
                        />
                      }
                      label={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmails[location][user],
                      ].join(" ")}
                    />
                  ))}
                </React.Fragment>
              ))}
            </FormGroup>
          </List>
          <FormLabel component="legend">Select a Greeting</FormLabel>
          <RadioGroup
            aria-label="greeting"
            name="greeting"
            value={greeting}
            onChange={handleGreetingChange}
          >
            <FormControlLabel
              value="Dear"
              control={<Radio />}
              label="Dear <First Name>"
            />
            <FormControlLabel
              value="Hi"
              control={<Radio />}
              label="Hi <First Name>"
            />
            <FormControlLabel
              value="Hello"
              control={<Radio />}
              label="Hello <First Name>"
            />
          </RadioGroup>
          <TextField
            id="outlined-multiline-static"
            label="Custom Message"
            multiline
            rows={4}
            placeholder="Put your custom message here."
            variant="outlined"
            value={message}
            onChange={handleMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          {nextButtonVisible && (
            <Button
              color="primary"
              onClick={() => handleNext("ConfirmOrderPaymentReminder")}
            >
              NEXT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
