import React from "react";
import PersonalInfoForm from "../../../components/userForms/PersonalInfoForm.js";

// This is the Personal Info Tab which contains the Restaurant's Name, the owner's
// first name, last name, email, and phone number
export default function PersonalInfo(props) {
  const userType = props.userInfo.userType;
  return (
    <div className="Tab-Box">
      <div className="Tab-Item-Row">
        {userType === "restaurant" ? (
          <h5 className="Row-Title"> Restaurant Name: </h5>
        ) : (
          <div>
            {(userType === "distributionLocation" || userType === "admin") && (
              <h5 className="Row-Title"> Organisation Name: </h5>
            )}
          </div>
        )}
        <p className="My-Account-Item"> {props.restaurantName} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> First Name: </h5>
        <p className="My-Account-Item"> {props.firstName} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Last Name: </h5>
        <p className="My-Account-Item"> {props.lastName} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Email: </h5>
        <p className="My-Account-Item"> {props.email} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Phone Number: </h5>
        <p className="My-Account-Item"> {props.phoneNumber} </p>
      </div>
      <PersonalInfoForm
        restaurantName={props.restaurantName}
        firstName={props.firstName}
        lastName={props.lastName}
        email={props.email}
        phoneNumber={props.phoneNumber}
        handleInfoUpdate={props.handleInfoUpdate}
        userInfo={props.userInfo}
        currentUser={props.currentUser}
      />
    </div>
  );
}
