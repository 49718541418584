// This is called when a distribution location updates the price of an item
// and it needs to update the database and all the users' orders.
import UpdatePriceAndCommunity from "../../pages/MyAccountPage/Functions/SyncFoodDistributionCommunityOrders/UpdatePriceAndCommunity.js";

// props comtains:
// userInfo : The distribution location's userInfo
// order : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// foodItemUpdate : foodItem to update quantity, price, distributionQuantity, etc.
// userOrder contains: customerContribution: "donation", farmDict: {}, farmerList: [{…}, {…}], foodDict: {}, foodList: [{…}, {…}], orderDate: 202012101607...
// orderLog contains: [array with each orderLoaded for the user]
// updateOrderLog contains: a function to update OrderLog
// orderIndex contains: the index of the current order in the orderLog array
// ordersToChange : Array with firstName-lastName-userId-orderDate of all communityOrders to be changed
// importedOrdersToChange : Array with firstName-lastName-userId-orderDate of all importedOrder to be changed
// newPrice : new price of the food
export default function UpdatePriceCommunityOrders(
  props,
  ordersToChange,
  importedOrdersToChange,
  newPrice,
  updatePaymentHistory,
) {
  const userInfo = props.userInfo;
  const order = { ...props.order };
  const foodItemUpdate = props.foodItemUpdate;
  const updateOrderLog = props.updateOrderLog;
  const orderIndex = props.orderIndex;

  // Finds when then user ordered to help find the individual's order in the database
  // eslint-disable-next-line no-unused-vars
  const orderDate = order.orderDate;

  // Find the index of the foodItem in the overall order for the distribution location
  let foodListIndex = -1;
  // Create a temporary foodList to modify
  let foodListTemp = [];

  // The field to update in the database.
  let fieldToUpdate = "";

  // If the limit doesn't  exist then we know this foodItem is from the foodList.
  // Otherwise it is a pantryFoodItems.
  if (!foodItemUpdate.limit) {
    // Find the index of this item in the foodList.
    foodListIndex = order.foodList.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );
    // Set the foodListTemp to the foodList
    foodListTemp = [...order.foodList];
    // Update the field that will be used to reference the database to foodList
    fieldToUpdate = "foodList";
  } else {
    // Find the index of this item in the pantryFoodItems.
    foodListIndex = order.pantryFoodItems.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );
    // Set the foodListTemp to the pantryFoodItems
    foodListTemp = [...order.pantryFoodItems];
    // Update the field that will be used to reference the database to pantryFoodItems.
    fieldToUpdate = "pantryFoodItems";
  }

  // Save the old price so that we can record the price change on the user's order.
  const originalPriceIndividual =
    (foodListTemp[foodListIndex].price /
      foodListTemp[foodListIndex].distributionQuantity) *
    foodListTemp[foodListIndex].individualQuantity;

  // Save the new price so that we can record the price change on the user's order.
  const newPriceIndividual =
    (newPrice / foodListTemp[foodListIndex].distributionQuantity) *
    foodListTemp[foodListIndex].individualQuantity;

  // Set the price of the foodItem of the foodList order
  foodListTemp[foodListIndex].price = newPrice;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...props.orderLog];

  // Update the community orders that were changed and their payment owed.
  UpdatePriceAndCommunity(
    userInfo,
    order,
    ordersToChange,
    importedOrdersToChange,
    updateOrderLog,
    updatePaymentHistory,
    orderIndex,
    foodListTemp,
    fieldToUpdate,
    foodItemUpdate,
    orderLogTemp,
    true,
    "priceUpdate",
    newPrice,
    true,
    originalPriceIndividual,
    newPriceIndividual,
  );
}
