// This is called to update the community orders or the imported orders when a
// distribution location is updating a single community members order
import firebase from "../../../components/Firebase.js";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import IndividualPriceChangesPaymentHistory from "./PriceHistoryChanges/IndividualPriceChangesPaymentHistory.js";
import ModifyIndividualCommunityOrder from "./ModifyCommunityOrders/ModifyIndividualCommunityOrder.js";

export default function UpdateFoodItemsCommunityOrders(
  props,
  item,
  distributionQuantity,
  description,
  individualQuantity,
  individualDescription,
  suggestedPrice,
  price,
  category,
  displayImage,
  quantity,
  farmName,
  limit,
  packageFee,
  updatePaymentHistory,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // The distribution location's userInfo.
  const userInfo = props.userInfo;
  // The order that the distribution location has selected to change.
  const order = props.order;
  // A function to update the orderLog.
  const updateOrderLog = props.updateOrderLog;
  // The index of the order out of al their orders.
  const orderIndex = props.orderIndex;
  // The indivdual user that is having their order modified
  const user = props.user;
  // Was this user's order imported or not.
  const imported = props.imported;
  // The location of the distribution location in case it was imported.
  const location = props.location;

  // Save the old price so that we can record the price change on the user's order.
  const originalPriceIndividual =
    (props.price / props.distributionQuantity) * props.individualQuantity;

  // Save the new price so that we can record the price change on the user's order.
  const newPriceIndividual =
    (price / distributionQuantity) * individualQuantity;

  // Since the user doesn't have to insert an image we just give an empty string
  // value so that it isn't undefined which can't be saved in the database.
  if (displayImage === undefined) {
    displayImage = "";
  }
  // Finds when then user ordered to help find the individual's order in the database
  const orderDate = order.orderDate;

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(orderDate);

  // Create a temporary communityOrders list
  const communityOrdersTemp = { ...order.communityOrders };

  // The imported order set a temporary dictionary to modify
  const importedOrdersTemp = { ...order.importedOrder };

  // This is the total cost of the order with all the farm's owed added up.
  let originalOrderCost = 0;

  // The updated cost owed to each farm in a dictionary after the price changes.
  let updatedOrderFarmsCost = {};

  // The updated cost owed to for the packaging after the changes.
  let updatedPackageCost = 0;

  // The updated total cost of the order with all the farm's owed added up.
  let updatedOrderCost = 0;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...props.orderLog];

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Since suggestedPrice was added later on we have to make sure
  // that is defined otherwise it will trigger a bug.
  let suggestedPriceProps = props.suggestedPrice;
  if (props.suggestedPrice === undefined) {
    suggestedPriceProps = false;
  }

  // This is the old foodItem that we are updating.
  const foodItemUpdate = {
    item: props.item,
    distributionQuantity: props.distributionQuantity,
    description: props.description,
    individualQuantity: props.individualQuantity,
    individualDescription: props.individualDescription,
    suggestedPrice: suggestedPriceProps,
    price: props.price,
    category: props.category,
    packageFee: props.packageFee,
    displayImage: props.displayImage,
    quantity: props.quantity,
    farmName: props.farmName,
    limit: props.limit,
  };

  // This is the new and updated food Item.
  const updatedFoodItem = {
    item,
    distributionQuantity,
    description,
    individualQuantity,
    individualDescription,
    suggestedPrice,
    price,
    category,
    packageFee,
    displayImage,
    quantity,
    farmName,
    limit,
  };
  // If the change to the order will affect the price of the food.
  let priceUpdate = false;
  // If any of the following stats are changed then the price will be effected.
  if (
    !(
      props.distributionQuantity === distributionQuantity &&
      props.individualQuantity === individualQuantity &&
      props.quantity === quantity &&
      props.packageFee === packageFee &&
      suggestedPriceProps === suggestedPrice &&
      props.price === price
    )
  ) {
    priceUpdate = true;
  }
  // Checks to see if the order was imported.  If not then it will adjust the community
  // order of this location.
  if (!imported) {
    // Modifies the community orders and tallies out the original order cost.
    originalOrderCost = ModifyIndividualCommunityOrder(
      user,
      communityOrdersTemp,
      userId,
      userInfo,
      order,
      foodItemUpdate,
      updatedFoodItem,
      priceUpdate,
      database,
      batch,
    );

    // // The indivdual's order also needs to be updated.  This is the reference to it
    // // in firebase.
    const userDocRef = database
      .collection("Users")
      .doc(userId[2])
      .collection("Orders")
      .doc(userId[3]);
    //
    // // This is used to update the document in the DistributionLocations collection
    let communityOrdersDocRef = null;
    //
    // // Update the current distribution locations orders in the DistributionLocations
    // // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...communityOrdersTemp[user].foodList],
      donationSubsidy: communityOrdersTemp[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...communityOrdersTemp[user].foodList],
      [user.concat(".donationSubsidy")]:
        communityOrdersTemp[user].donationSubsidy,
    });
  }
  // Otherwise it was an imported order and needs to be modified differently.
  else {
    // The imported order set a temporary dictionary to modify
    const importedOrdersTemp = { ...order.importedOrder };
    // Modifies the community orders and tallies out the original order cost.
    originalOrderCost = ModifyIndividualCommunityOrder(
      user,
      importedOrdersTemp[location].communityOrders,
      userId,
      userInfo,
      order,
      foodItemUpdate,
      updatedFoodItem,
      priceUpdate,
      database,
      batch,
    );

    // The indivdual's order also needs to be updated.  This is the reference to it
    // in firebase.
    const importedUserDocRef = database
      .collection("Users")
      .doc(userId[2])
      .collection("Orders")
      .doc(userId[3]);

    // This is used to update the document in the DistributionLocations collection
    let importedCommunityOrdersDocRef = null;

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    importedCommunityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(importedOrdersTemp[location].date);

    // Updates Users -> userId -> Orders -> date;
    batch.update(importedUserDocRef, {
      foodList: [
        ...importedOrdersTemp[location].communityOrders[user].foodList,
      ],
      donationSubsidy:
        importedOrdersTemp[location].communityOrders[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(importedCommunityOrdersDocRef, {
      [user.concat(".foodList")]: [
        ...importedOrdersTemp[location].communityOrders[user].foodList,
      ],
      [user.concat(".donationSubsidy")]:
        importedOrdersTemp[location].communityOrders[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(docRef, {
      importedOrder: { ...importedOrdersTemp },
    });

    // This is to make sure the page rerenders these items correctly for importedOrders
    orderLogTemp[orderIndex].importedOrder = { ...importedOrdersTemp };
  }

  // Checks to see if the distributionQuantity, individualQuantity, price, packageFee or quantity
  // were changed as that means that the total price would have also changed.
  if (priceUpdate) {
    // Checks if the order was imported or not.
    if (!imported) {
      // Sums up the totals owed to each farm.
      updatedOrderFarmsCost = PriceTotals(
        communityOrdersTemp[user].foodList,
        true,
      );
      // Calculate the packaging costs of the orders.
      updatedPackageCost = PackageTotals(communityOrdersTemp[user].foodList);

      // Cycle through the totals owed to each farm and add them up.
      Object.keys(updatedOrderFarmsCost).forEach((farmName) => {
        // Add the order cost from the food.
        updatedOrderCost += updatedOrderFarmsCost[farmName];
        // If the packaging cost is more than 0 then add that too.
        if (updatedPackageCost[farmName] > 0) {
          updatedOrderCost += updatedPackageCost[farmName];
        }
      });
      // If the cash portion of the volunteer fee is greater than 0 then add that
      // to the total as well.
      if (parseFloat(communityOrdersTemp[user].cashPortion) > 0) {
        updatedOrderCost += parseFloat(communityOrdersTemp[user].cashPortion);
      }

      // Add or subtract the subsidy and donations to the total order cost.
      if (communityOrdersTemp[user].customerContribution === "donation") {
        updatedOrderCost += communityOrdersTemp[user].donationSubsidy;
      } else {
        updatedOrderCost -= communityOrdersTemp[user].donationSubsidy;
      }
    } else {
      // Sums up the totals owed to each farm.
      updatedOrderFarmsCost = PriceTotals(
        importedOrdersTemp[location].communityOrders[user].foodList,
        true,
      );
      // Calculate the packaging costs of the orders.
      updatedPackageCost = PackageTotals(
        importedOrdersTemp[location].communityOrders[user].foodList,
      );

      // Cycle through the totals owed to each farm and add them up.
      Object.keys(updatedOrderFarmsCost).forEach((farmName) => {
        // Add the order cost from the food.
        updatedOrderCost += updatedOrderFarmsCost[farmName];
        // If the packaging cost is more than 0 then add that too.
        if (updatedPackageCost[farmName] > 0) {
          updatedOrderCost += updatedPackageCost[farmName];
        }
      });

      // If the cash portion of the volunteer fee is greater than 0 then add that
      // to the total as well.
      if (
        parseFloat(
          importedOrdersTemp[location].communityOrders[user].cashPortion,
        ) > 0
      ) {
        updatedOrderCost += parseFloat(
          importedOrdersTemp[location].communityOrders[user].cashPortion,
        );
      }

      // Add or subtract the subsidy and donations to the total order cost.
      if (
        importedOrdersTemp[location].communityOrders[user]
          .customerContribution === "donation"
      ) {
        updatedOrderCost +=
          importedOrdersTemp[location].communityOrders[user].donationSubsidy;
      } else {
        updatedOrderCost -=
          importedOrdersTemp[location].communityOrders[user].donationSubsidy;
      }
    }

    // Since this could be called from the Basket Page we don't want to update the
    // payment history.
    if (updatePaymentHistory) {
      // Once the original and the updated costs are calculated then compare the change
      // in price and update the payment history that each customer owes.
      IndividualPriceChangesPaymentHistory(
        orderDate,
        originalOrderCost,
        updatedOrderCost,
        props.item,
        originalPriceIndividual,
        newPriceIndividual,
        imported,
        location,
        user,
        false,
        updatePaymentHistory,
      );
    }
  }

  // Users -> userId -> Orders -> date
  batch.update(docRef, {
    communityOrders: { ...communityOrdersTemp },
  });

  // This updates the communityOrders and the foodList
  orderLogTemp[orderIndex].communityOrders = { ...communityOrdersTemp };

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);
}
