// This actually isn't a dialog but calls all the dialogs in the process of sending
// out messages to a distribution location's users.  It has a three states process
// where the user first selects the type of mass email to send, selects the users and
// creates the customized message and then confirms and sends the message.
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import SelectActionDialog from "./Components/SendUserEmails/SelectActionDialog.jsx";
import UserSelectionCustomMessageDialog from "./Components/SendUserEmails/UserSelectionCustomMessageDialog.jsx";
import OrderDropOffMessageDialog from "./Components/SendUserEmails/OrderDropOffMessageDialog.jsx";
import ConfirmCustomMessageDialog from "./Components/SendUserEmails/ConfirmCustomMessageDialog.jsx";
import ConfirmOrderDropOffMessageDialog from "./Components/SendUserEmails/ConfirmOrderDropOffMessageDialog.jsx";
import OrderPaymentReminderDialog from "./Components/SendUserEmails/OrderPaymentReminderDialog.jsx";
import ConfirmOrderPaymentReminderDialog from "./Components/SendUserEmails/ConfirmOrderPaymentReminderDialog.jsx";
import LoadingEmailsDialog from "./Components/SendUserEmails/LoadingEmailsDialog.jsx";
import SendingEmailsDialog from "./Components/SendUserEmails/SendingEmailsDialog.jsx";
import LoadUserEmails from "./Components/SendUserEmails/LoadUserEmails.js";
import "date-fns";
import CreateVisibleCommunityOrders from "./functions/CreateVisibleCommunityOrders.js";

export default function SendUsersEmailsDialog({
  communityOrders,
  importedOrder,
  userInfo,
  order,
}) {
  // This is to say that the first dialog in 1/3 states is open and in process
  const [openSelectAction, setOpenSelectAction] = useState(false);
  // This is to say that the first dialog in 2/3 states is open and in process
  const [
    openUserSelectionCustomMessageDialog,
    setOpenUserSelectionCustomMessageDialog,
  ] = useState(false);
  // This is to say that the first dialog in 3/3 states is open and in process
  const [openConfirmCustomMessageDialog, setOpenConfirmCustomMessageDialog] =
    useState(false);
  // This is to say that the first dialog in B2/3 states is open and in process.
  // This state is when a user is sending an order drop off message and is choosing the users
  // and the custom message to start.
  const [openOrderDropOffMessageDialog, setOpenOrderDropOffMessageDialog] =
    useState(false);
  // This is to say that the first dialog in B3/3 states is open and in process.
  // This state is when a user is confirming their order drop off message and the users
  // to send it to.
  const [
    openConfirmOrderDropOffMessageDialog,
    setOpenConfirmOrderDropOffMessageDialog,
  ] = useState(false);
  // This is to say that the first dialog in C2/3 states is open and in process.
  // This state is when a user is sending an order payment reminder
  const [openOrderPaymentReminderDialog, setOpenOrderPaymentReminderDialog] =
    useState(false);
  // This is to say that the first dialog in C3/3 states is open and in process.
  // This state is when a user is confirming their order payment reminder.
  const [
    openConfirmOrderPaymentReminderDialog,
    setOpenConfirmOrderPaymentReminderDialog,
  ] = useState(false);
  // This is the dictionary that contains the user's location, the user and their email
  // {location : {firstName-lastName-userId : email}}
  const [userEmails, setUserEmails] = useState({});
  // This sets what state in the 3 state process the user is in.
  const [stateOfDialog, setStateOfDialog] = useState("selectAction");
  // The checkList of customers that the user has selected to message. {firstName-lastName-userId : true}
  const [checkList, setCheckList] = useState({});
  // Which greeting message the user has selected from the radio buttons in 2nd state.
  const [greeting, setGreeting] = useState("Hi");
  // The custom message the user has written to be send to users.
  const [message, setMessage] = useState("");
  // This is set to true while the program loads the customers' emails and set a
  // progress dialog.
  const [loadingEmails, setLoadingEmails] = useState(false);
  // This is set to true while the program sends the customers' emails and set a
  // progress dialog.
  const [sendingEmails, setSendingEmails] = useState(false);
  // Refined list of users with order details of users that are picking up from
  // community hubs.  This includes their donations, foodList, etc. straight from
  // communityOrders.
  const [communityHubUsers, setCommunityHubUsers] = useState({});

  // List of users with their order details.  This includes their donations,
  // foodList, etc. straight from communityOrders.
  const [communityMembers, setCommunityMembers] = useState({});

  // Order Drop off emails are used to also set the delivery fees in stone for the users
  // but the user may want to do this without actually sending an email so this is used
  // to determined if the user wants to send the emails or not.
  const [sendEmails, setSendEmails] = useState(true);

  // This use effect checks if the state is in the 2/3 state and if it is then
  // we load the emails of all the customers.
  useEffect(() => {
    if (stateOfDialog === "Custom") {
      // Loads all the users emails and controls the loading hooks.
      LoadUserEmails(
        communityOrders,
        importedOrder,
        setLoadingEmails,
        setUserEmails,
        {},
      );
    } else if (stateOfDialog === "OrderPaymentReminder") {
      // Loads all the users emails and controls the loading hooks.
      LoadUserEmails(
        communityOrders,
        importedOrder,
        setLoadingEmails,
        setUserEmails,
        {},
      );

      // Create a dictionary with each distribution location as the key and then
      // all the users and their order details as the values.  {communityLocation : {user : order}}
      let communityMembersTemp = CreateVisibleCommunityOrders(
        communityOrders,
        null,
        null,
        false,
        [],
        order.selectedDate,
      );

      // If there are imported orders then add those locations to the list as well.
      Object.keys(importedOrder).forEach((location) => {
        // Since imported orders may also have pickup on site orders we need to make
        // sure that they don't overwrite the ones that are other locations.
        const pickupOnSiteTemp = communityMembersTemp["Pickup On Site"];

        // Combine the imported orders with the existing orders.
        communityMembersTemp = Object.assign(
          {},
          communityMembersTemp,
          CreateVisibleCommunityOrders(
            importedOrder[location].communityOrders,
            null,
            null,
            false,
            [],
            importedOrder[location].date,
          ),
        );
        // Add back the orders that were picked up on site from the previous locations.
        communityMembersTemp["Pickup On Site"] = Object.assign(
          {},
          communityMembersTemp["Pickup On Site"],
          pickupOnSiteTemp,
        );
      });

      // Set the community members order.
      setCommunityMembers({ ...communityMembersTemp });
    } else if (stateOfDialog === "OrderDropOff") {
      // Create a dictionary with each distribution location as the key and then
      // all the users and their order details as the values.  {communityLocation : {user : order}}
      let communityHubUsersTemp = CreateVisibleCommunityOrders(
        communityOrders,
        null,
        null,
        false,
        [],
      );
      // If there are imported orders then add those locations to the list as well.
      Object.keys(importedOrder).forEach((location) => {
        communityHubUsersTemp = Object.assign(
          {},
          communityHubUsersTemp,
          CreateVisibleCommunityOrders(
            importedOrder[location].communityOrders,
            null,
            null,
            false,
            [],
          ),
        );
      });
      // There will be the pickup on site locations but they may not be correct
      // since there are multiple pickup on sites and they have been overwritten
      // by imported orders.  We don't need them though so just remove them.
      delete communityHubUsersTemp["Pickup On Site"];

      // Set the community hubs.
      setCommunityHubUsers({ ...communityHubUsersTemp });
      // Loads all the users emails and controls the loading hooks.
      LoadUserEmails(
        {},
        {},
        setLoadingEmails,
        setUserEmails,
        communityHubUsersTemp,
      );
    }
  }, [stateOfDialog]);

  // This use effect checks the state of the user and makes sure that the correct
  // dialogs are being displayed.
  useEffect(() => {
    // C2/3 state
    if (stateOfDialog === "OrderPaymentReminder") {
      setOpenOrderPaymentReminderDialog(true);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenOrderDropOffMessageDialog(false);
      setOpenConfirmOrderDropOffMessageDialog(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
    }
    // C2/3 state
    else if (stateOfDialog === "ConfirmOrderPaymentReminder") {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(true);
      setOpenConfirmOrderDropOffMessageDialog(false);
      setOpenOrderDropOffMessageDialog(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
    }
    // B2/3 state
    else if (stateOfDialog === "OrderDropOff") {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenOrderDropOffMessageDialog(true);
      setOpenConfirmOrderDropOffMessageDialog(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
    }
    // B2/3 state
    else if (stateOfDialog === "ConfirmOrderDropOff") {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenConfirmOrderDropOffMessageDialog(true);
      setOpenOrderDropOffMessageDialog(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
    }
    // 2/3 State
    else if (stateOfDialog === "Custom") {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenUserSelectionCustomMessageDialog(true);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
      setOpenConfirmOrderDropOffMessageDialog(false);
      setOpenOrderDropOffMessageDialog(false);
    }
    // 3/3 state
    else if (stateOfDialog === "ConfirmCustom") {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenConfirmCustomMessageDialog(true);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenOrderDropOffMessageDialog(false);
      setOpenConfirmOrderDropOffMessageDialog(false);
    }
    // 1/3 state
    else {
      setOpenOrderPaymentReminderDialog(false);
      setOpenConfirmOrderPaymentReminderDialog(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenSelectAction(false);
      setOpenConfirmCustomMessageDialog(false);
      setOpenOrderDropOffMessageDialog(false);
      setOpenConfirmOrderDropOffMessageDialog(false);
    }
  }, [stateOfDialog]);

  // If the user opens the first dialog and is in state 1/3
  const handleClickOpen = () => {
    setOpenSelectAction(true);
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        EMAIL USERS
      </Button>

      <SelectActionDialog
        openSelectAction={openSelectAction}
        setOpenSelectAction={setOpenSelectAction}
        setStateOfDialog={setStateOfDialog}
      />

      <UserSelectionCustomMessageDialog
        userEmails={userEmails}
        openUserSelectionCustomMessageDialog={
          openUserSelectionCustomMessageDialog
        }
        setOpenUserSelectionCustomMessageDialog={
          setOpenUserSelectionCustomMessageDialog
        }
        setStateOfDialog={setStateOfDialog}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        message={message}
        setMessage={setMessage}
        nextState="ConfirmCustom"
      />

      <OrderDropOffMessageDialog
        userEmails={userEmails}
        openOrderDropOffMessageDialog={openOrderDropOffMessageDialog}
        setOpenOrderDropOffMessageDialog={setOpenOrderDropOffMessageDialog}
        stateOfDialog={stateOfDialog}
        setStateOfDialog={setStateOfDialog}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        message={message}
        setMessage={setMessage}
        sendEmails={sendEmails}
        setSendEmails={setSendEmails}
      />

      <OrderPaymentReminderDialog
        userEmails={userEmails}
        openOrderPaymentReminderDialog={openOrderPaymentReminderDialog}
        setOpenOrderPaymentReminderDialog={setOpenOrderPaymentReminderDialog}
        stateOfDialog={stateOfDialog}
        setStateOfDialog={setStateOfDialog}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        message={message}
        setMessage={setMessage}
      />

      <ConfirmCustomMessageDialog
        openConfirmCustomMessageDialog={openConfirmCustomMessageDialog}
        setOpenConfirmCustomMessageDialog={setOpenConfirmCustomMessageDialog}
        setStateOfDialog={setStateOfDialog}
        userEmails={userEmails}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        message={message}
        setSendingEmails={setSendingEmails}
        nextState="Custom"
      />

      <ConfirmOrderDropOffMessageDialog
        openConfirmOrderDropOffMessageDialog={
          openConfirmOrderDropOffMessageDialog
        }
        setOpenConfirmOrderDropOffMessageDialog={
          setOpenConfirmOrderDropOffMessageDialog
        }
        stateOfDialog={stateOfDialog}
        setStateOfDialog={setStateOfDialog}
        userEmails={userEmails}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        message={message}
        userInfo={userInfo}
        setSendingEmails={setSendingEmails}
        communityHubUsers={communityHubUsers}
        order={order}
        sendEmails={sendEmails}
      />

      <ConfirmOrderPaymentReminderDialog
        openConfirmOrderPaymentReminderDialog={
          openConfirmOrderPaymentReminderDialog
        }
        setOpenConfirmOrderPaymentReminderDialog={
          setOpenConfirmOrderPaymentReminderDialog
        }
        stateOfDialog={stateOfDialog}
        setStateOfDialog={setStateOfDialog}
        userEmails={userEmails}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        message={message}
        setMessage={setMessage}
        userInfo={userInfo}
        setSendingEmails={setSendingEmails}
        communityMembers={communityMembers}
      />

      <LoadingEmailsDialog open={loadingEmails} />
      <SendingEmailsDialog open={sendingEmails} />
    </div>
  );
}
