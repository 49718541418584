// This function will reorder the list of foodItems and pantryItems depending on
// what the user has requested. If the user wants alphabetically the items will be sorted
// alphatically by their name.  For the By Farm the items will sorted alphatically by the farm's
// name.  For the By order it will be sorted in the order that user ordered the items in.  If the user
// wants the program to sort the items by farmName and item name alphatically they'll have to run the sort
// function twice.  For the By Category it will sort the items by category and put the Dairy and Eggs and then
// the Frozen Foods first.

export function ReorderFoodItems(
  foodList,
  setFoodList,
  originalOrder,
  pantryFoodItems,
  setPantryFoodItems,
  originalPantryFoodItems,
  alphabetically,
  byFarm,
  byOrdered,
  byCategory,
) {
  // Create a temporary food list to modify.
  let foodListTemp = [...foodList];
  // Create a temporary pantry food list to modify.
  let pantryFoodItemsTemp = [...pantryFoodItems];

  // If the user chose to sort alphatically sort by the item name.
  if (alphabetically) {
    foodListTemp.sort((a, b) => {
      if (a.item < b.item) {
        return -1;
      }
      if (a.item > b.item) {
        return 1;
      }
      return 0;
    });

    pantryFoodItemsTemp.sort((a, b) => {
      if (a.item < b.item) {
        return -1;
      }
      if (a.item > b.item) {
        return 1;
      }
      return 0;
    });
  }

  // If the user chose to sort by farm then we'll sort alphatically sort by farmName.
  if (byFarm) {
    foodListTemp.sort((a, b) => {
      if (a.farmName < b.farmName) {
        return -1;
      }
      if (a.farmName > b.farmName) {
        return 1;
      }
      return 0;
    });

    pantryFoodItemsTemp.sort((a, b) => {
      if (a.farmName < b.farmName) {
        return -1;
      }
      if (a.farmName > b.farmName) {
        return 1;
      }
      return 0;
    });
  }

  // If the user wants to sort it back to the way the order was originally ordered
  // then we just need to reset the foodItems list.
  if (byOrdered) {
    foodListTemp = [...originalOrder];
    if (originalPantryFoodItems) {
      pantryFoodItemsTemp = originalPantryFoodItems;
    }
  }

  // If the user chose to sort by category then we'll sort by category with Dairy and Eggs and
  // Frozen at the top together.
  if (byCategory) {
    foodListTemp.sort((a, b) => {
      if (a.category === undefined) {
        return 1;
      } else if (b.category === undefined) {
        return -1;
      } else if (
        a.category.toLowerCase().includes("dairy") ||
        a.category.toLowerCase().includes("eggs")
      ) {
        return -1;
      } else if (
        b.category.toLowerCase().includes("dairy") ||
        b.category.toLowerCase().includes("eggs")
      ) {
        return 1;
      } else if (a.category.toLowerCase().includes("frozen")) {
        return -1;
      } else if (b.category.toLowerCase().includes("frozen")) {
        return 1;
      } else if (a.category < b.category) {
        return -1;
      } else if (a.category > b.category) {
        return 1;
      }
      return 0;
    });

    pantryFoodItemsTemp.sort((a, b) => {
      if (a.category === "Dairy and Eggs") {
        return -1;
      } else if (b.category === "Dairy and Eggs") {
        return 1;
      } else if (a.category === "Frozen Foods") {
        return -1;
      } else if (b.category === "Frozen Foods") {
        return 1;
      } else if (a.category < b.category) {
        return -1;
      } else if (a.category > b.category) {
        return 1;
      }
      return 0;
    });
  }

  // Set the foodList.
  setFoodList(foodListTemp);
  // If pantry items exist then set them as well.
  if (originalPantryFoodItems) {
    setPantryFoodItems(pantryFoodItemsTemp);
  }
}
