// This is used to add an item to the pantry food list.
import React, { useState } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddPantryFoodItem from "../../../components/ModifyOrders/AddPantryFoodItem.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AddItemToPantryOrderDialog({
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  userInfo,
}) {
  const [open, setOpen] = useState(false);
  const [distributionQuantity, setDistributionQuantity] = useState();
  const [description, setDescription] = useState("");
  const [individualQuantity, setIndividualQuantity] = useState();
  const [individualDescription, setIndividualDescription] = useState("");
  const [suggestedPrice, setSuggestedPrice] = useState(false);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState();
  const [farmName, setFarmName] = useState("");
  const [category, setCategory] = useState("");
  const [item, setItem] = useState("");
  const [limit, setLimit] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDistributionQuantity(0);
    setDescription("");
    setIndividualQuantity(0);
    setIndividualDescription("");
    setSuggestedPrice("");
    setPrice("");
    setQuantity(0);
    setFarmName("");
    setCategory("");
    setItem("");
    setLimit(0);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // Sets the pantryFoodItem so that it includes all the items it needs to
    // be added.
    const pantryFoodItem = {
      distributionQuantity,
      description,
      individualQuantity,
      individualDescription,
      suggestedPrice,
      price,
      quantity: parseFloat(quantity),
      farmName,
      category,
      item,
      limit,
      pantryLocation: userInfo.organisationName,
      userId: firebase.auth().currentUser.uid,
      // This is hardcoded to match deprecated code
      userType: "admin",
    };
    // Add the foodItem to the pantryFoodList.
    AddPantryFoodItem(
      pantryFoodItem,
      order,
      orderLog,
      updateOrderLog,
      orderIndex,
    );
    setOpen(false);
    setDistributionQuantity(0);
    setDescription("");
    setIndividualQuantity(0);
    setIndividualDescription("");
    setSuggestedPrice(false);
    setPrice("");
    setQuantity(0);
    setFarmName("");
    setCategory("");
    setItem("");
    setLimit(0);
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        ADD ITEM
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Fill in information about the item
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave}>
            <span>
              <TextField
                margin="dense"
                id="item"
                label="Item"
                name="item"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="distributionQuantity"
                label="Distribution Quantity"
                name="distributionQuantity"
                value={distributionQuantity}
                type="number"
                onChange={(e) =>
                  setDistributionQuantity(parseFloat(e.target.value))
                }
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="description"
                label="Distribution Description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="individualQuantity"
                label="Individual Quantity"
                name="individualQuantity"
                value={individualQuantity}
                type="number"
                onChange={(e) =>
                  setIndividualQuantity(parseFloat(e.target.value))
                }
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="individualDescription"
                label="Individual Description"
                name="individualDescription"
                value={individualDescription}
                onChange={(e) => setIndividualDescription(e.target.value)}
                fullWidth
                required
              />
              <FormControlLabel
                label="Suggested Price"
                control={
                  <Switch
                    checked={suggestedPrice}
                    name="suggestedPrice"
                    onChange={() => setSuggestedPrice(!suggestedPrice)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
              <TextField
                margin="dense"
                id="price"
                label="Price ($CAD)"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="quantity"
                label="Quantity"
                name="quantity"
                value={quantity}
                type="number"
                onChange={(e) => setQuantity(parseFloat(e.target.value))}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="farmName"
                name="farmName"
                label="Supplying Farm"
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="category"
                name="category"
                label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                fullWidth
              />
              <TextField
                margin="dense"
                id="limit"
                name="limit"
                label="Pantry Remaining"
                value={limit}
                type="number"
                onChange={(e) => setLimit(parseFloat(e.target.value))}
                fullWidth
                required
              />
            </span>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
