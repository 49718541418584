// This is the to display the totals paid to each vendor and allows the user to
// toggle between seeing how much food was sold from each vendor from items taken
// from the pantry.
import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { Divider, Typography } from "@material-ui/core";

// Toggle between showing the totals each vendor was paid for the order's purchased items
// and the totals of food taken from the pantry to each vendor
// eslint-disable-next-line no-unused-vars
function toggleBetweenPriceTotals(e, showPantryTotals, setShowPantryTotals) {
  setShowPantryTotals(!showPantryTotals);
}

export default function DistributionLocationMyAccountFarmTotalsLine({
  farmTotals,
  grandTotal,
  pantryTotals,
  packageTotals,
  farmSuggestedTotals,
}) {
  // Show the pantry totals or the purchased totals when false.
  const [showPantryTotals, setShowPantryTotals] = useState(false);
  // The totals paid to each vendor {vendor : $total}
  let totals = farmTotals;
  // Text to display on the button.
  let displayTotals = "Click To Show Pantry Totals";
  // Calculate based on the distribution location costs or the individual costs.
  let userTypeTotals = "distributionLocation";
  // The header to display on the price totals.
  let totalsHeader = "Total Ordered From Farms:";
  // The total cost paid to the vendors.
  let totalCost = grandTotal;
  // The total cost of the food taken from the pantry.
  let pantryCostTotal = 0;
  // Cycle through the pantryTotals  and add them all up.
  Object.keys(pantryTotals).forEach((farmTotal) => {
    pantryCostTotal += pantryTotals[farmTotal];
  });

  // If the user is showing the pantryTotals then set the variables for the pantry
  // showing.
  if (showPantryTotals) {
    totals = pantryTotals;
    displayTotals = "Click To Show Ordered Totals";
    totalsHeader = "Total Taken From Pantry";
    // eslint-disable-next-line no-unused-vars
    userTypeTotals = "individual";
    totalCost = pantryCostTotal;
  }

  // Checks to make sure that if the user removed the pantry items and they were
  // looking at the pantry then switch it back or if the user hasn't got any items in
  // pantry to start then set the variables back to the purchase amounts.
  useEffect(() => {
    if (Object.keys(pantryTotals).length === 0) {
      setShowPantryTotals(false);
      totals = farmTotals;
      displayTotals = "Click To Show Pantry Totals";
      userTypeTotals = "distributionLocation";
      totalsHeader = "Total Ordered From Farms";
      totalCost = grandTotal;
    }
  }, [pantryTotals]);

  return (
    <div>
      {Object.keys(pantryTotals).length > 0 && (
        <ToggleButton
          value="check"
          color="secondary"
          selected={showPantryTotals}
          onChange={() => {
            setShowPantryTotals(!showPantryTotals);
          }}
        >
          {displayTotals}
        </ToggleButton>
      )}

      <Typography variant="h6" gutterBottom>
        {totalsHeader}
      </Typography>

      {Object.keys(totals).map((farmTotal, i) => (
        <Typography key={i}>
          <div key={farmTotal + i} className="Prices-Per-Farm">
            <b>{farmTotal}:</b> ${parseFloat(totals[farmTotal]).toFixed(2)}
            {packageTotals[farmTotal] > 0 && (
              <span
                style={{ verticalAlign: "super" }}
                className="Item-Package-Fee"
              >
                + ${packageTotals[farmTotal].toFixed(2)} pkg fee
              </span>
            )}
            {farmSuggestedTotals[farmTotal] > 0 && (
              <i style={{ fontSize: "12px" }}>
                {" "}
                + ${farmSuggestedTotals[farmTotal]} Value of Donated Food
              </i>
            )}
          </div>
        </Typography>
      ))}

      <Divider />
      <Typography>
        <b>Total:</b> ${parseFloat(totalCost).toFixed(2)}
      </Typography>
    </div>
  );
}
