// Once the individual user has placed an order they may have items that were taken
// from the pantry.  If that is the case then they need to update the pantry to
// ensure it stays up to date.
import firebase from "../../../components/Firebase.js";
import OrderIncompleteRestoreItems from "./OrderIncompleteRestoreItems.js";
import Swal from "sweetalert2";

export default function UpdatePantryAfterOrder(
  pantryLocation,
  foodItems,
  OrderEmails,
  userInfo,
  costTotal,
  grandTotal,
  donationSubsidy,
  customerContribution,
  setPlacingOrder,
  setSnackBarOpen,
  basketOrder,
  handleUserInfoChange,
  setFoodItems,
  batch,
  foodItemsUpdated,
  deliveryFee,
  cashPortion,
  creditPortion,
) {
  const database = firebase.firestore();

  // Create a document reference to the pantry being used.
  const pantryDocRef = database.collection("Pantry").doc(pantryLocation);
  // We run a transaction as we want to make sure that the user is reading the
  // most up to date data from the document we want to make sure that if another
  // user took an item that there is still enough in the pantry for this user's order.
  return database
    .runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(pantryDocRef).then((pantryDoc) => {
        if (!pantryDoc.exists) {
          // eslint-disable-next-line no-throw-literal
          throw "Document does not exist!";
        }

        // Create an updatedPantry document that we will use to update the
        // database with the changes made.
        const updatedPantryDoc = { ...pantryDoc.data() };
        // The itemName is how it is referenced in the database item + individualDescription + farmName
        let itemName = "";
        // The newLimit is the amount of the item in the pantry that is left
        // after the user takes their amount from it.
        let newLimit = 0;
        // The limitMax is how much is left in the pantry of this item and
        // therefore the maximum the user can order.
        let limitMax = 0;
        // Cancel holds the string that will be returned if there is not enough
        // of an item when placing the order.
        let cancel = "";

        // Cycle through all the foodItems to make sure none of them are
        // over the amount of items allowed.
        foodItems.forEach((item) => {
          // Check to see if this item has a limit on it and is in the pantry.
          if (item.limit) {
            // Find the items reference in the firebase document.
            itemName = [
              item.item,
              item.individualDescription,
              item.farmName,
            ].join("");
            // Calculate the newLimit after the user's order is subtracted from
            // the current limit on the item.
            newLimit = pantryDoc.data()[itemName].limit - item.quantity;
            // Make sure the limit isn't less than zero.  If it is then we'll
            // have to end the order and notify the user to update their quantities.
            if (newLimit < 0) {
              // Calculate what the maximum limit is on this item now so the
              // user knows how many they can order.  Since newLimit is a negative
              // number we just have to subtract it from the quantity the user wanted.
              limitMax = item.quantity + newLimit;
              // Create a string to send to the user and reject this transaction.
              cancel =
                "There are only " +
                limitMax +
                " " +
                item.item +
                " remaining please reduce your order.";
            }
            // If there are enough items in the pantry then update the dictionary
            // with the updated pantry document.
            else {
              updatedPantryDoc[itemName].limit = newLimit;
            }
          }
        });

        // Check to see if the cancel string was ever created and if so then
        // reject the transaction.
        if (cancel !== "") {
          return Promise.reject(cancel);
        }
        // Otherwise accept the transaction and update the pantry.
        else {
          transaction.update(pantryDocRef, { ...updatedPantryDoc });
        }
      });
    })
    .then(() => {
      // Testing Remove
      // Send the user their confirmation email.
      OrderEmails(
        userInfo.pickupLocation.name,
        foodItems,
        userInfo,
        null,
        null,
        grandTotal,
        donationSubsidy,
        customerContribution,
        setPlacingOrder,
        setSnackBarOpen,
        deliveryFee,
        cashPortion,
        creditPortion,
        costTotal,
        handleUserInfoChange,
      );

      // Set all the quantities that were in the basket back to 0 remove them from
      // the basket since they've been ordered.
      if (basketOrder) {
        userInfo.basketItems = [];
        // Testing Remove
        handleUserInfoChange(userInfo, true);
      }
      // Reset the foodItems to empty
      setFoodItems([]);
      // commit the changes to the database.
      batch.commit();
      // Return true that the transaction was successful.
      return true;
    })
    .catch((err) => {
      // If the order fails to complete then we have to restore the items that
      // were taken by the order from the farmLimits.
      OrderIncompleteRestoreItems(foodItemsUpdated);
      // Send an alert to the user to tell them of the error.
      Swal.fire(err);
      // stop placing the order so the user can fix their order.
      setPlacingOrder(false);
      // Return false that the transaction failed.
      return false;
    });
}
