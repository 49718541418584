// Displays the details of each foodItem on the marketplace.  It's just the section
// of the foodItem that is the item name, the price, the farmName and the description.
import React from "react";
import FarmPopover from "./FarmPopover.jsx";

export default function ItemDetails({ row, userInfo }) {
  // The price of the foodItem for the distribution location
  let price = parseFloat(row.price).toFixed(2);
  // Check to see if the suggestedPrice is true then we will set the price to $0.00.
  if (row.suggestedPrice) {
    price = parseFloat(0).toFixed(2);
  }
  // The price of the foodItem for the individual
  const individualPrice = parseFloat(
    price / (row.distributionQuantity / row.individualQuantity),
  ).toFixed(2);
  // Says that there is a limit on this item or not.  Default to no limit
  let limited = false;
  // Says that there is a limit set by the farmer on this item or not.  Default to no limit
  let farmLimited = false;
  // If the item has a limit then set it to true.  If there is no limit then check
  // if there is a limit set by the farmer.  If the farmLimit or if it is 0 then we know that
  // the item is limited.
  if (row.limit) {
    // eslint-disable-next-line no-unused-vars
    limited = true;
  } else if (!!row.farmLimit || row.farmLimit === 0) {
    // eslint-disable-next-line no-unused-vars
    farmLimited = true;
  }

  // If the user is a distribution location then we will use the price
  // for the distribution locations.  Otherwise we'll use the individual price.
  let userPrice = price;
  // If the user is a distribution location then we will use the per unit quantity
  // for the distribution locations.  Otherwise we'll use the individual quantity.
  let userPerUnit = row.description;

  // Change the price and description to fit the individual user.
  if (userInfo.userType === "individual") {
    userPrice = parseFloat(individualPrice);
    userPerUnit = row.individualDescription;
    // If there is a package fee then add it to the user price.
    if (row.packageFee > 0) {
      userPrice += parseFloat(row.packageFee);
    }
    // Put the price to 2 decimals so it is in dollar format.
    userPrice = userPrice.toFixed(2);
  }
  // Add the suggested price to the description so the user knows what they should
  // be paying.
  if (row.suggestedPrice) {
    if (userInfo.userType === "individual") {
      userPerUnit = userPerUnit.concat(
        " Suggested Donation of $",
        parseFloat(
          row.price / (row.distributionQuantity / row.individualQuantity),
        ).toFixed(2),
      );
    } else {
      userPerUnit = userPerUnit.concat(
        " Suggested Donation of $",
        parseFloat(row.price).toFixed(2),
      );
    }
  }

  return (
    <div className="Item-Details">
      <>
        {/* The popover launches when a user clicks the farm's name. See comments in FarmPopover component for additional information */}
        <FarmPopover row={row} />
      </>
      <p className="Item-Name-Marketplace">{row.item}</p>
      {parseFloat(row.minimumPurchase) > 1 &&
        (userInfo.userType === "distributionLocation" ||
          userInfo.userType === "admin") && (
          <p className="Minimum-Purchase">Min Qty x {row.minimumPurchase}</p>
        )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="Item-Price-Marketplace">
          ${userPrice}
          {row.packageFee > 0 && <>*</>}
        </div>
        /<p className="Item-Description-Marketplace">{userPerUnit}</p>
      </div>
      {row.packageFee > 0 && userInfo.userType === "individual" && (
        <p className="Item-Package-Fee">
          *includes package fee ${parseFloat(row.packageFee).toFixed(2)}/per
          item
        </p>
      )}
      {row.packageFee > 0 && userInfo.userType === "distributionLocation" && (
        <p className="Item-Package-Fee">
          * plus package fee ${parseFloat(row.packageFee).toFixed(2)}/per item
        </p>
      )}
      {userInfo.userType === "admin" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className="Item-Name-Marketplace">Individual&apos;s Info</p>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <div className="Item-Price-Marketplace">
              ${individualPrice}
              {row.packageFee > 0 && <>*</>}
            </div>
            /
            <p className="Item-Description-Marketplace">
              {row.individualDescription}
            </p>
          </div>
          {row.packageFee > 0 && (
            <p className="Item-Package-Fee">
              *package fee ${parseFloat(row.packageFee).toFixed(2)}/per item
            </p>
          )}
        </div>
      )}
    </div>
  );
}
