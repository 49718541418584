// This component creates a column on the marketplace that displays filters for the
// user to search by category and by farm.
import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import FilterDisplayedItems from "./../Functions/FilterDisplayedItems.js";

const useStyles = makeStyles({
  active: {
    backgroundColor: "#CCF0E6",
    "&.Mui-selected": { backgroundColor: "#CCF0E6" },
    "&.Mui-selected:hover": { backgroundColor: "#CCF0E6" },
  },
});

export default function FilterColumn({
  marketplaceCategories,
  foodItems,
  setDisplayedFoodItems,
  checkedFarms,
  setCheckedFarms,
  selectedCategory,
  setSelectedCategory,
}) {
  const classes = useStyles();

  // If the user selects a farm checkbox
  const handleChange = (event) => {
    // Find the current checked farms dictionary
    const checkedFarmsTemp = { ...checkedFarms };
    // Changed the selected farms key to whatever the checkbox shows. (true or false)
    checkedFarmsTemp[event.target.name] = event.target.checked;
    // update the checkedFarms hook.
    setCheckedFarms((prevState) => {
      return { ...prevState, [event.target.name]: event.target.checked };
    });
    // Call the FilterDisplayedItems function to properly display the user's choices.
    FilterDisplayedItems(
      foodItems,
      setDisplayedFoodItems,
      selectedCategory,
      checkedFarmsTemp,
    );
    // After clicking one of the checkboxes scroll to the top of the food items list.
    document.getElementById("pageMarket").scrollIntoView();
  };

  // This is called when the user selects a category to filter by.
  const handleClick = (selection) => {
    // Updates the hook that stores the selected filter
    setSelectedCategory(selection);
    // Call the FilterDisplayedItems function to properly display the user's choices.
    FilterDisplayedItems(
      foodItems,
      setDisplayedFoodItems,
      selection,
      checkedFarms,
    );
  };

  return (
    <div
      style={{
        height: "65vh",
        overflowY: "auto",
        overflowX: "auto",
        background: "rgb(202 221 158)",
        border: "1px solid lightgrey",
        borderRadius: "10px",
        padding: "10px",
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
      }}
    >
      <List>
        <a href="#pageMarket" style={{ textDecoration: "none" }}>
          <ListItem
            classes={{ selected: classes.active }}
            button={true}
            onClick={() => handleClick("All Products")}
            selected={selectedCategory === "All Products"}
          >
            All Products
          </ListItem>
        </a>
        {marketplaceCategories.map((category, i) => (
          <a key={i} href="#pageMarket" style={{ textDecoration: "none" }}>
            <ListItem
              classes={{ selected: classes.active }}
              name={category}
              button={true}
              onClick={() => handleClick(category)}
              selected={selectedCategory === category}
            >
              {category}
            </ListItem>
          </a>
        ))}
      </List>
      <FormControl component="fieldset">
        <FormLabel component="legend">Farms</FormLabel>
        <FormGroup>
          {Object.keys(checkedFarms).map((farm, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={checkedFarms[farm]}
                  onChange={handleChange}
                  name={farm}
                />
              }
              label={farm}
            />
          ))}
        </FormGroup>
        <FormHelperText>Filter Items By Farm</FormHelperText>
      </FormControl>
    </div>
  );
}
