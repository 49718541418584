// This is to help throttle the emails that are going out to make
// sure that we're not sending so many at once that the emailer
// blocks any emails. Outlook will only send 30 per minute.
import { OrderEmails2 } from "../pages/BasketPage/OrderEmails.js";
import batchEmails from "./Email/BatchEmailer.js";

// Function to send emails in batches with a delay between batches
export default async function OrderEmailsInBatches(
  emailBatches,
  setPlacingBatchesOrder,
) {
  // If there is more than one batch of emails to send then set
  // the flag so that we let the user know that they have to wait.
  if (emailBatches.length > 1) {
    setPlacingBatchesOrder(true);
  }

  for (const batch of emailBatches) {
    await batchEmails({
      batchedParamsForEmailFunc: batch,
      singularEmailFunc: OrderEmails2,
    });
  }
}
