// Create a order payment reminder email that writes the message to include
// the user's order.  It is sent to the user with instrutions on how to pay.
export default function OrderPaymentReminder(
  message,
  communityMembers,
  checkList,
  setPaymentReminderMessages,
  setUserPaymentReminderSubjectLines,
  userInfo,
) {
  // Add the delivery fee per location.
  const deliveryFeesByLocation = {};

  // The userEmailMessages that will be unique for each user that is being emailed.
  // {firstName-lastName-userId : "message + order"}
  const userEmailMessages = {};

  // The userPaymentReminderSubjectLines that will be unique for each user that is being emailed.
  // {firstName-lastName-userId : "subject line + date"}
  const userEmaiSubjectLines = {};

  // Cycle through the communityMembers to add up the order amounts and put together
  // the order string. ex. communityMembers = {Debbie's Location : {}, Barbara location : {}...}
  Object.keys(communityMembers).forEach((distributionLocation) => {
    // Checks the first user in this distributionlocation.  We just need to check
    // one user and they should all be the same so if the first user doesn't have the
    // correct values then there is something wrong.
    const firstKey = Object.keys(communityMembers[distributionLocation]);

    // Make sure that the first user exists, has a communityPickup and that has
    // a pickupLocation.  If it does then we can proceed to calculating the delivery fee.
    // We want to use the baseDeliveryFee at the time they ordered as the distribution location's
    // current base delivery fee may have changed.
    if (communityMembers[distributionLocation][firstKey[0]] !== undefined) {
      if (
        communityMembers[distributionLocation][firstKey[0]].communityPickup !==
        undefined
      ) {
        if (
          communityMembers[distributionLocation][firstKey[0]].communityPickup
            .pickupLocation !== undefined
        ) {
          // Calculate the number of users to be sharing the delivery fee.
          const numberOfUsers = Object.keys(
            communityMembers[distributionLocation],
          ).length;
          // The base delivery fee of the location at the time the user placed the order.
          const baseDeliveryFee =
            communityMembers[distributionLocation][firstKey[0]].communityPickup
              .pickupLocation.baseDeliveryFee;
          // Set the delivery fee of this location.
          deliveryFeesByLocation[distributionLocation] =
            baseDeliveryFee / numberOfUsers;
        }
      }
    }
    // Cycle through each user that exists at this location.
    Object.keys(communityMembers[distributionLocation]).forEach((user) => {
      // Check to see if this user was checked to be emailed.
      if (checkList[user]) {
        // The community Pickup details.
        let communityPickup = {};
        // The distribution location details of this order.
        let pickupLocation = userInfo;

        // If the user is picking up for a community hub then set the right
        // pickupLocation.
        if (distributionLocation !== "Pickup On Site") {
          // The community Pickup details.
          communityPickup =
            communityMembers[distributionLocation][user].communityPickup;
          // The distribution location details of this order.
          pickupLocation = communityPickup.pickupLocation;
        }
        // The etransfer message with the question and answer.
        let etransferMessage =
          " with the question: farmtoplate and answer: marketplace.";
        // The etranfer message when it is auto deposit so no question and answer is needed.
        if (pickupLocation.etransferType === "Auto-Deposit") {
          etransferMessage = ".";
        }
        // This is the cost of the food itself.
        let orderTotal = 0;
        // The stringified version of order
        let orderString = "";
        // The package fees in total for the order.
        let packageFeeTotal = 0;
        // The package fees owed for the order in string format to be put in the email.
        let packageFeeString = "";
        // The delivery fees owed for the order in string format to be put in the email.
        let deliveryFeeString = "";
        // The volunteer Fees owed for the order in string format to be put in the email.
        let volunteerFeeString = "";

        // Individual orders should include which farmers are selling which products so we
        // create the list to be a dictionary with farm keys and the food as an array.
        const individalFarmOrders = {};

        // Cycle through each foodList item and add these items to a dictionary
        // sorted by farm.  That way we have the items sorted by farm.
        // and add the string to the message.
        communityMembers[distributionLocation][user].foodList.forEach(
          (orderItem) => {
            if (individalFarmOrders[orderItem.farmName]) {
              individalFarmOrders[orderItem.farmName].push(orderItem);
            } else {
              individalFarmOrders[orderItem.farmName] = [orderItem];
            }
          },
        );

        // Cycle through the farms that are providing this order.
        Object.keys(individalFarmOrders).forEach((farmName) => {
          // Add the name of the farm.
          orderString = orderString.concat(
            '<span style="font-size: 14px;"><u><b>',
            farmName.toString(),
            ":</b></u></span><br />",
          );
          // Cycle through the items from the farm.
          individalFarmOrders[farmName].forEach((orderItem) => {
            // Set the price of the individal item
            let individualPrice = parseFloat(
              orderItem.price /
                (orderItem.distributionQuantity / orderItem.individualQuantity),
            );
            // The individual description of the food item for the user to know how
            // much they are purchasing.
            let individualDescription = orderItem.individualDescription;

            // if suggestedPrice is true then we want to set the price to 0
            if (orderItem.suggestedPrice) {
              // First add to the description the suggested Donation.
              // eslint-disable-next-line no-unused-vars
              individualDescription = individualDescription.concat(
                " - Suggested Donation of $",
                individualPrice,
              );
              // Then reset the prices to 0.
              individualPrice = 0.0;
            }

            // If there is a package fee then add it to the item price and add it to the
            // the total package fee.
            if (orderItem.packageFee > 0) {
              individualPrice += parseFloat(orderItem.packageFee);
              packageFeeTotal +=
                parseFloat(orderItem.packageFee) *
                parseFloat(orderItem.quantity);
            }
            // Set the price of the individal item multiplied by the amount of that item.
            const individualPriceTotal = parseFloat(
              individualPrice * orderItem.quantity,
            ).toFixed(2);
            // Make the price a 2 decimal float for dollar format.
            individualPrice = individualPrice.toFixed(2);
            // Add this item to the order string for them email the order item.
            orderString = orderString.concat(
              "• ",
              orderItem.item.toString(),
              " ",
              orderItem.individualDescription.toString(),
              " ",
              " x",
              orderItem.quantity.toString(),
              " -- $",
              individualPrice.toString(),
              " ($",
              individualPriceTotal.toString(),
              ")",
            );
            // If there is a package fee then add it to the string to let the user know.
            if (orderItem.packageFee > 0) {
              orderString = orderString.concat(
                " *includes pkg fee of ",
                parseFloat(orderItem.packageFee).toFixed(2),
                "/per item.",
              );
            }
            orderString = orderString.concat("<br />");
            // Add to the cost total of the order.
            orderTotal += parseFloat(individualPrice * orderItem.quantity);
          });
        });

        // If the packageFee is greater than 0 then we want to print the total fees.
        if (packageFeeTotal > 0) {
          packageFeeString = packageFeeString.concat(
            "Included Package Fees : $",
            packageFeeTotal.toFixed(2),
            "<br />",
          );
        }

        // This the donation or subsidy amount.
        const donationSubsidy =
          communityMembers[distributionLocation][user].donationSubsidy;
        // This is a string that says "donation" or "subsidy" depending on the order
        const customerContribution =
          communityMembers[distributionLocation][user].customerContribution;

        // This is a string that is included in the email that says subsidy or donation
        let donationSubsidyString = "- Subsidy Amount : $";

        // This the order total after the donation or subsidy have been added in
        let grandTotal = orderTotal;

        // If the user gave a donation calculate the ordertotal by subtracting the donation
        // amount from the grandTotal this is the cost of the order.  If it is a subsidy
        // then add the subsidy amount to the grandTotal to get the cost of the order.
        // If this is a donation change the donationSubsidyString to say "Donation Amount : "
        if (customerContribution === "donation") {
          grandTotal = orderTotal + donationSubsidy;
          donationSubsidyString = "+ Donation Amount : $";
        } else {
          grandTotal = orderTotal - donationSubsidy;
        }

        // Check to see if the delivery fee is greater than 0 and then add it
        // to the grandTotal and update the string.
        if (deliveryFeesByLocation[distributionLocation] > 0) {
          let deliveryFee =
            communityMembers[distributionLocation][user].deliveryFee;
          if (deliveryFee == null) {
            deliveryFee = deliveryFeesByLocation[distributionLocation];
          }
          deliveryFeeString = deliveryFeeString.concat(
            "+ Delivery Fee : $",
            parseFloat(deliveryFee).toFixed(2),
            "<br />",
          );
          grandTotal += deliveryFee;
        }

        // If the credit portion is greater than 0 then we want to print the total fees.
        if (communityMembers[distributionLocation][user].creditPortion > 0) {
          volunteerFeeString = volunteerFeeString.concat(
            "Participation Fee Credits : ",
            communityMembers[distributionLocation][user].creditPortion,
            " credits<br />",
          );
        }

        // If the deliveryFee is greater than 0 then we want to print the total fees and
        // add it to the grandTotal.
        if (communityMembers[distributionLocation][user].cashPortion > 0) {
          volunteerFeeString = volunteerFeeString.concat(
            "+ Participation Fee Cash : $",
            communityMembers[distributionLocation][user].cashPortion,
            "<br />",
          );
          grandTotal += parseFloat(
            communityMembers[distributionLocation][user].cashPortion,
          );
        }

        // Create a temporary message that includes the user's order.
        let messageTemp =
          '<p style="font-size: 16px;">Your order on ' +
          communityMembers[distributionLocation][user].distributionDate +
          ` was as follows:</p>
                  <p style="font-size: 22px;">` +
          orderString +
          `</p>
                  <p style="font-size: 16px;">
                    <i style="font-size: 14px;">
                      ` +
          packageFeeString +
          volunteerFeeString +
          deliveryFeeString +
          `
                      + Food Cost : $` +
          orderTotal.toFixed(2) +
          `
                      <br />
                      ` +
          donationSubsidyString +
          donationSubsidy.toFixed(2) +
          `
                    </i>
                    <br />
                    Total Cost : $` +
          grandTotal.toFixed(2) +
          `
                    <br />
                    <br />
                    Next Steps
                    <br />
                    1. Please bring cash to your next pickup if it is accepted or pay by e-transfer sent to ` +
          pickupLocation.etransferEmail +
          etransferMessage +
          `<br />
                    <br />
                  Please reply to this email for any feedback, questions or concerns you may have. You can also reach Farm-to-Plate Marketplace by phone at 778-896-6754.
                    <br />
                    <br />
                    Thank you for supporting your community and local farmers,
                    <br />
                    Farm-to-Plate Marketplace </p>`; // email content in HTML

        // Add the original custom message to the beginning of the order message.
        messageTemp = message.concat(messageTemp);

        // Add the user with their attached message in a dictionary.
        userEmailMessages[user] = messageTemp;
        // Add the user's subject line to the list.
        userEmaiSubjectLines[
          user
        ] = `Order Payment reminder for ${communityMembers[distributionLocation][user].distributionDate}`;
      }
    });
  });
  // Set the hook with the user email messages.
  setPaymentReminderMessages(userEmailMessages);
  setUserPaymentReminderSubjectLines(userEmaiSubjectLines);
}
