// This class is used to update the a marketplace item from the admin's marketplace.
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ModifyItemInPantry from "./../../Pantry/Functions/ModifyItemInPantry.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class PantryEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: this.props.foodItem.quantity,
      limit: this.props.foodItem.limit,
      pantryLocation: this.props.userInfo.organisationName,
      distributionQuantity: this.props.foodItem.distributionQuantity,
      description: this.props.foodItem.description,
      individualQuantity: this.props.foodItem.individualQuantity,
      individualDescription: this.props.foodItem.individualDescription,
      suggestedPrice: this.props.foodItem.suggestedPrice,
      price: this.props.foodItem.price,
      category: this.props.foodItem.category,
      item: this.props.foodItem.item,
      displayImage: this.props.foodItem.displayImage,
      farmName: this.props.foodItem.farmName,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
      quantity: this.props.foodItem.quantity,
      limit: this.props.foodItem.limit,
      distributionQuantity: this.props.foodItem.distributionQuantity,
      description: this.props.foodItem.description,
      individualQuantity: this.props.foodItem.individualQuantity,
      individualDescription: this.props.foodItem.individualDescription,
      suggestedPrice: this.props.foodItem.suggestedPrice,
      price: this.props.foodItem.price,
      category: this.props.foodItem.category,
      item: this.props.foodItem.item,
      displayImage: this.props.foodItem.displayImage,
      farmName: this.props.foodItem.farmName,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      quantity: this.state.quantity,
      limit: this.state.limit,
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      suggestedPrice: this.state.suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      item: this.state.item,
      displayImage: this.state.displayImage,
      farmName: this.state.farmName,
    });
  }

  handleSave(e) {
    e.preventDefault();

    // This will update the item in the pantry and updates the datbase.
    ModifyItemInPantry(
      this.props.leftovers,
      this.props.setLeftovers,
      this.props.userInfo,
      this.state,
      this.props.foodItem,
      this.props.index,
    );

    // Make sure that suggestedPrice exists. It was added in later and so may not
    // have a value.  It will cause a bug if it is undefined because you can't save
    // that to the database.
    let suggestedPrice = this.state.suggestedPrice;
    if (suggestedPrice === undefined) {
      suggestedPrice = false;
    }

    // Resets the state of the dialog.
    this.setState({
      open: false,
      quantity: this.state.quantity,
      limit: this.state.limit,
      distributionQuantity: this.state.distributionQuantity,
      description: this.state.description,
      individualQuantity: this.state.individualQuantity,
      individualDescription: this.state.individualDescription,
      suggestedPrice,
      price: this.state.price,
      category: this.state.category,
      item: this.state.item,
      displayImage: this.state.displayImage,
      farmName: this.state.farmName,
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const type = target.type;

    // If we are changing the switch that says the suggestedPrice is on or off then
    // we will update the suggestedPrice.
    if (type === "checkbox") {
      // Set the suggestedPrice to false.
      let suggestedPriceBool = false;
      // If the suggestedPrice is already true set to true.
      if (this.state.suggestedPrice) {
        suggestedPriceBool = true;
      }
      // Swap the suggestedPrice boolean.
      this.setState({
        suggestedPrice: !suggestedPriceBool,
      });
    } else if (name === "limit") {
      this.setState({
        [name]: parseInt(value),
      });
    } else {
      if (
        (name === "item" ||
          name === "description" ||
          name === "individualDescription") &&
        (value.includes("-") || value.includes(".") || value.includes("/"))
      ) {
        alert(`Please do not use the symbols "-, . or /"`);
      } else {
        this.setState({
          [name]: value,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <IconButton onClick={this.handleClickOpen}>
          <EditIcon />
        </IconButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Fill in information about the item
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              <span>
                <TextField
                  margin="dense"
                  id="item"
                  label="Item"
                  name="item"
                  value={this.state.item}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="distributionQuantity"
                  label="Distribution Quantity"
                  name="distributionQuantity"
                  type="number"
                  value={this.state.distributionQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="description"
                  label="Distribution Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualQuantity"
                  label="Individual Quantity"
                  name="individualQuantity"
                  type="number"
                  value={this.state.individualQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualDescription"
                  label="Individual Description"
                  name="individualDescription"
                  value={this.state.individualDescription}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <FormControlLabel
                  label="Suggested Price"
                  control={
                    <Switch
                      checked={this.state.suggestedPrice}
                      name="suggestedPrice"
                      onChange={this.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <TextField
                  margin="dense"
                  id="price"
                  label="Price ($CAD)"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="category"
                  label="Category"
                  name="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="displayImage"
                  label="Display Image"
                  name="displayImage"
                  value={this.state.displayImage}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="limit"
                  name="limit"
                  label="Limit"
                  type="number"
                  value={this.state.limit}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="farmName"
                  name="farmName"
                  label="Supplying Farm"
                  value={this.state.farmName}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
              </span>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
