// Called by FarmLimitsCompletePlacingOrder.js when an individual places an order on the marketplace.
// Updates the datebase in the Pantry if items were limited, the user orders and the
// distributionLocation collection.
import FindNextDay from "../../../functions/FindNextDay.js";
import YYYYMMDDConverter from "../Functions/YYYYMMDDConverter.js";
import firebase from "../../../components/Firebase.js";
import UpdatePantryAfterOrder from "./UpdatePantryAfterOrder.js";
import CompareItemNames from "../../../functions/CompareItemNames.js";

export default function IndividualOrder(
  today,
  daysOfTheWeek,
  userInfo,
  foodItems,
  farmerInfo,
  contactMethods,
  donationSubsidy,
  customerContribution,
  batch,
  ordersDocRef,
  now,
  setFoodItems,
  OrderEmails,
  costTotal,
  grandTotal,
  setPlacingOrder,
  setSnackBarOpen,
  handleUserInfoChange,
  basketOrder,
  foodItemsUpdated,
  deliveryFee,
  cashPortion,
  creditPortion,
) {
  const database = firebase.firestore();
  // Sets the pickup location to the user's pickup location
  let pickupLocation = userInfo.pickupLocation;
  // Sets the communityPickup to an empty array.
  let communityPickup = [];
  // Checks if the user has selected a community location.
  if (pickupLocation.pickupLocation !== undefined) {
    // If it has then update the community Pickup
    communityPickup = pickupLocation;
    // If it has then update the distibution location.
    pickupLocation = pickupLocation.pickupLocation;
  }

  // Sort the items alphabetically
  foodItems.sort((a, b) => {
    return CompareItemNames(a, b, "item");
  });

  // First find the next orderCutoff day.  This will be stored in a new Date() format.
  const distributionLocationCutoffDate = FindNextDay(
    today,
    daysOfTheWeek[pickupLocation.orderCutoff],
  );
  // Second find the next pickup day.  Start looking from the next orderCutoff.
  let distributionLocationPickupDate = FindNextDay(
    distributionLocationCutoffDate,
    daysOfTheWeek[pickupLocation.pickupDay],
  );
  // Change the format of this date to yyyymmddtime
  distributionLocationPickupDate = YYYYMMDDConverter(
    distributionLocationPickupDate,
  );
  // Change the formate of this date to MM-DD-YYYY
  distributionLocationPickupDate =
    distributionLocationPickupDate.substring(4, 6) +
    "-" +
    distributionLocationPickupDate.substring(6, 8) +
    "-" +
    distributionLocationPickupDate.substring(0, 4);

  const distributionLocationOrdersDocRef = database
    .collection("DistributionLocations")
    .doc(pickupLocation.name)
    .collection("CommunityOrders")
    .doc(distributionLocationPickupDate);

  // If the delivery Fee is 0 then the user is ordering from a community hub after
  // already ordering from this hub.  This order delivery fee is free so we can set it 0.
  if (deliveryFee === 0.0) {
    batch.set(ordersDocRef, {
      orderDate: now,
      foodList: foodItems,
      farmerList: farmerInfo,
      contactMethods,
      pickupLocation,
      donationSubsidy,
      customerContribution,
      communityPickup,
      cashPortion,
      creditPortion,
      deliveryFee,
      phoneNumber: userInfo.phoneNumber,
    });
    batch.set(
      distributionLocationOrdersDocRef,
      {
        [userInfo.firstName +
        "-" +
        userInfo.lastName +
        "-" +
        firebase.auth().currentUser.uid +
        "-" +
        now]: {
          orderDate: now,
          foodList: foodItems,
          farmerList: farmerInfo,
          donationSubsidy,
          customerContribution,
          communityPickup,
          cashPortion,
          creditPortion,
          deliveryFee,
          phoneNumber: userInfo.phoneNumber,
        },
      },
      { merge: true },
    );
  }
  // The delivery fee isn't 0 so we won't include it as it will be added later if at
  // all.
  else {
    batch.set(ordersDocRef, {
      orderDate: now,
      foodList: foodItems,
      farmerList: farmerInfo,
      contactMethods,
      pickupLocation,
      donationSubsidy,
      customerContribution,
      communityPickup,
      cashPortion,
      creditPortion,
      phoneNumber: userInfo.phoneNumber,
    });
    batch.set(
      distributionLocationOrdersDocRef,
      {
        [userInfo.firstName +
        "-" +
        userInfo.lastName +
        "-" +
        firebase.auth().currentUser.uid +
        "-" +
        now]: {
          orderDate: now,
          foodList: foodItems,
          farmerList: farmerInfo,
          donationSubsidy,
          customerContribution,
          communityPickup,
          cashPortion,
          creditPortion,
          phoneNumber: userInfo.phoneNumber,
        },
      },
      { merge: true },
    );
  }

  // Find the pantry location that this distribution location uses.
  let pantryLocation = "";
  // Cycle through all the user's foodItems.  If there are multiple foodItems that
  // are from the pantry they should all be from the same pantry and so they'll just
  // write over with the same location each time.
  foodItems.forEach((item) => {
    // See if any of their food items have a limit to them indicating that they're
    // from the pantry.
    if (item.limit) {
      // Make the pantryLocation equal to this item.
      pantryLocation = item.pantryLocation;
    }
  });

  // Checks to see if there are any foodItems from the pantry if not then skip this
  // part.
  if (pantryLocation !== "") {
    // Updates the pantry to reflect the items taken from it from this user.
    UpdatePantryAfterOrder(
      pantryLocation,
      foodItems,
      OrderEmails,
      userInfo,
      costTotal,
      grandTotal,
      donationSubsidy,
      customerContribution,
      setPlacingOrder,
      setSnackBarOpen,
      basketOrder,
      handleUserInfoChange,
      setFoodItems,
      batch,
      foodItemsUpdated,
      deliveryFee,
      cashPortion,
      creditPortion,
    );
  }
  // If there are no pantry items then there is nothing that needs to be checked
  // and the emails and basket clearing can occur.
  else {
    // Testing Remove
    OrderEmails(
      userInfo.pickupLocation.name,
      foodItems,
      userInfo,
      null,
      null,
      grandTotal,
      donationSubsidy,
      customerContribution,
      setPlacingOrder,
      setSnackBarOpen,
      deliveryFee,
      cashPortion,
      creditPortion,
      costTotal,
      handleUserInfoChange,
    );

    // Set all the quantities that were in the basket back to 0 remove them from
    // the basket since they've been ordered.
    if (basketOrder) {
      userInfo.basketItems = [];
      // Testing Remove
      handleUserInfoChange(userInfo, true);
    }
    // Reset the foodItems to empty
    setFoodItems([]);
    // commit the changes to the database.
    batch.commit();
  }
}
