import React, { useContext } from "react";
import cn from "classnames";
import urls from "../urls";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { AuthContext } from "./authentication/Auth.js";
import firebase from "./Firebase.js";
import BasicMenu from "./userInputs/BasicMenu.jsx";

function MenuTab({ path, title }) {
  const location = useLocation();
  const classes = cn("Menu-Font-Style", {
    "Menu-Font-Style-Selected": location.pathname === path,
  });
  return (
    <Link className={classes} to={path}>
      {title}
    </Link>
  );
}

// This the top Menu Box that shows links around the website and the basket
export default function Menu() {
  const { currentUser, userInfo } = useContext(AuthContext);

  // Looks at what page the user is currently on so that the selected page is
  // highlighted.
  const currentPage = useLocation();

  // Calculates how many items the user has in their basket by the quanities not
  // just the items themselves
  let totalBasketItems = 0;
  if (userInfo.basketItems === undefined) {
    userInfo.basketItems = [];
  } else {
    userInfo.basketItems.forEach((item) => {
      totalBasketItems += item.quantity;
    });
  }

  // There's a lot of logic here but it needs to check which page the user is on
  // and whether the user is logged in or not.  Depending on those states what is
  // displayed will be different
  return (
    <span className="Menu">
      <Link className="Menu-Logo-Style" to="/">
        <img
          referrerPolicy="no-referrer"
          style={{
            height: "60px",
            border: "1px solid #c7c7c7",
            borderRadius: "50%",
          }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLXj1A0JBCwtz49sMvteUQlZx1BiHQ0SbiWHgm230f6lHICeuSpJ6gI0g-O4IgT18j8FqooOuapK7vfXJeceR7pqZjO2eKLQzLfP_ax-yvA2Ui5WdR55vtbTshNIF12XbAqbGkWpLgLGA3o7vXi7zQO-=s789-no?authuser=1"
        />
      </Link>
      {currentUser ? (
        <span className="Header-Links">
          {currentPage.pathname === "/Basket" ? (
            <Link className="Menu-Basket-Style-Selected" to="/Basket">
              {totalBasketItems !== 0 && <sup> {totalBasketItems} </sup>}
              <ShoppingCartOutlinedIcon />
            </Link>
          ) : (
            <Link className="Menu-Basket-Style" to="/Basket">
              {totalBasketItems !== 0 && <sup> {totalBasketItems} </sup>}
              <ShoppingCartOutlinedIcon />
            </Link>
          )}
          <Link
            className="Menu-Font-Style"
            to="/"
            onClick={() => firebase.auth().signOut()}
          >
            Log Out
          </Link>
          <MenuTab title="My Account" path={urls.myAccount} />
          <MenuTab title="Community" path={urls.communityEngagement} />
          <MenuTab title="Farms" path={urls.farms} />
          <MenuTab title="Marketplace" path={urls.marketPlace} />
          <BasicMenu urls={urls} />
        </span>
      ) : (
        <span className="Header-Links">
          <MenuTab title="Join" path={urls.signUp} />
          <MenuTab title="Sign In" path={urls.signIn} />
          <MenuTab title="Community" path={urls.communityEngagement} />
          <MenuTab title="Farms" path={urls.farms} />
          <MenuTab title="Marketplace" path={urls.marketPlace} />
          <BasicMenu urls={urls} />
        </span>
      )}
    </span>
  );
}
