// This is to add an item the the pantry of a distribution location

import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddItemToPantry from "./../../Pantry/Functions/AddItemToPantry.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class AddItemToPantryDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      suggestedPrice: false,
      price: "",
      category: "",
      quantity: "",
      limit: "",
      farmName: "",
      item: "",
      displayImage: "",
      pantryLocation: this.props.userInfo.organisationName,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      suggestedPrice: false,
      price: "",
      category: "",
      farmName: "",
      item: "",
      displayImage: "",
      quantity: "",
      limit: "",
    });
  }

  handleSave(e) {
    e.preventDefault();

    // This commits to the pantry in the database and updates the page.  Leftovers
    // is the current list of leftovers and setLeftovers updates the page's hook.
    // The userInfo and state send the information needed to update the datavase.
    AddItemToPantry(
      this.props.leftovers,
      this.props.setLeftovers,
      this.props.userInfo,
      this.state,
    );

    this.setState({
      open: false,
      distributionQuantity: "",
      description: "",
      individualQuantity: "",
      individualDescription: "",
      suggestedPrice: false,
      farmName: "",
      item: "",
      displayImage: "",
      price: "",
      category: "",
      quantity: "",
      limit: "",
    });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const type = target.type;

    // If we are changing the switch that says the suggestedPrice is on or off then
    // we will update the suggestedPrice.
    if (type === "checkbox") {
      // Set the suggestedPrice to false.
      let suggestedPriceBool = false;
      // If the suggestedPrice is already true set to true.
      if (this.state.suggestedPrice) {
        suggestedPriceBool = true;
      }
      // Swap the suggestedPrice boolean.
      this.setState({
        suggestedPrice: !suggestedPriceBool,
      });
    } else if (name === "limit") {
      this.setState({
        [name]: parseInt(value),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  render() {
    return (
      <div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={this.handleClickOpen}
        >
          ADD ITEM
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Fill in information about the item
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              <span>
                <TextField
                  margin="dense"
                  id="item"
                  label="Item"
                  name="item"
                  value={this.state.item}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="distributionQuantity"
                  label="Distribution Quantity"
                  name="distributionQuantity"
                  type="number"
                  value={this.state.distributionQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="description"
                  label="Distribution Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualQuantity"
                  label="Individual Quantity"
                  name="individualQuantity"
                  type="number"
                  value={this.state.individualQuantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="individualDescription"
                  label="Individual Description"
                  name="individualDescription"
                  value={this.state.individualDescription}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <FormControlLabel
                  label="Suggested Price"
                  control={
                    <Switch
                      checked={this.state.suggestedPrice}
                      name="suggestedPrice"
                      onChange={this.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <TextField
                  margin="dense"
                  id="price"
                  label="Price ($CAD)"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="category"
                  label="Category"
                  name="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="displayImage"
                  label="Display Image"
                  name="displayImage"
                  value={this.state.displayImage}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="limit"
                  name="limit"
                  label="Limit"
                  type="number"
                  value={this.state.limit}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="farmName"
                  name="farmName"
                  label="Supplying Farm"
                  value={this.state.farmName}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
              </span>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
