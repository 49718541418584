// This is 2/3 dialogs used by distribution locations to send their customers a
// order drop off email.   It displays all the users that can be emailed and are
// picking up from a community location, select a greeting, and allows for the message to be customized.
// The user can't move to the final state until they've selected users to email and
// written a message.
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CommunityPickupAccordion from "../../../../pages/MyAccountPage/Components/Accordions/CommunityPickupAccordion.jsx";
import Switch from "@material-ui/core/Switch";

export default function OrderDropOffMessageDialog({
  userEmails,
  openOrderDropOffMessageDialog,
  setOpenOrderDropOffMessageDialog,
  stateOfDialog,
  setStateOfDialog,
  checkList,
  setCheckList,
  greeting,
  setGreeting,
  message,
  setMessage,
  sendEmails,
  setSendEmails,
}) {
  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenOrderDropOffMessageDialog(false);
    setStateOfDialog("selectAction");
    setCheckList({});
  };

  // If the user moves to the next state update it.
  const handleNext = (value) => {
    setOpenOrderDropOffMessageDialog(false);
    setStateOfDialog(value);
  };

  // When the user changes the checklist
  const handleChange = (e) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
  };

  // When the user clicks select all for a community location.
  const handleSelectAll = (e) => {
    const target = e.target;
    const location = target.name;
    const locationEmails = Object.keys(userEmails[location]);
    const newCheckList = {};
    for (let i = 0; i < locationEmails.length; i++) {
      newCheckList[locationEmails[i]] = target.checked;
    }
    setCheckList({ ...checkList, ...newCheckList });
  };

  // When the user changes the greeting option.
  const handleGreetingChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    const target = e.target;
    setGreeting(e.target.value);
  };

  // When the user changes the customized message.
  const handleMessageChange = (e) => {
    let value = e.target.value;
    if (value.slice(-1) === "\n") {
      value = value.slice(0, -1);
      value = value.concat("<br/>");
    }
    setMessage(value);
  };

  // This use effect sets the message to a message about the order being dropped off
  // and tips about leaving their order.
  useEffect(() => {
    if (stateOfDialog === "OrderDropOff") {
      setMessage(`Thank you for placing your order! We've dropped it off and it's ready for pick up.<br /><br />
      Please be conscious of any perishable or frozen foods that should not be left out for too long.<br />`);
    }
  }, [stateOfDialog]);

  // Since we don't want the user moving to the next state until they've selected
  // users and created a message then we just check to make sure those conditions are
  // met and then set this variable to true which displays the next button.
  let nextButtonVisible = false;
  if (message !== "") {
    Object.keys(checkList).forEach((user) => {
      if (checkList[user]) {
        nextButtonVisible = true;
      }
    });
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openOrderDropOffMessageDialog}
      >
        <DialogTitle id="simple-dialog-title">
          What Would You Like To Do?
        </DialogTitle>
        <DialogContent>
          {Object.keys(userEmails).map((location, ind) => (
            <CommunityPickupAccordion
              key={ind}
              location={location}
              userEmails={userEmails}
              checkList={checkList}
              handleChange={handleChange}
              handleSelectAll={handleSelectAll}
            />
          ))}
          <FormControlLabel
            label="Send Emails To Users"
            control={
              <Switch
                checked={sendEmails}
                onChange={(e) => setSendEmails(!sendEmails)}
                name="SendEmailsEnabled"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
          />
          {sendEmails && (
            <>
              <RadioGroup
                aria-label="greeting"
                name="greeting"
                value={greeting}
                onChange={handleGreetingChange}
              >
                <FormControlLabel
                  value="Dear"
                  control={<Radio />}
                  label="Dear <First Name>"
                />
                <FormControlLabel
                  value="Hi"
                  control={<Radio />}
                  label="Hi <First Name>"
                />
                <FormControlLabel
                  value="Hello"
                  control={<Radio />}
                  label="Hello <First Name>"
                />
              </RadioGroup>
              <TextField
                id="outlined-multiline-static"
                label="Custom Message"
                multiline
                rows={4}
                placeholder="Put your custom message here."
                variant="outlined"
                value={message}
                onChange={handleMessageChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          {nextButtonVisible && (
            <Button
              color="primary"
              onClick={() => handleNext("ConfirmOrderDropOff")}
            >
              NEXT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
