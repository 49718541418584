// This is a form that the distribution location uses to approve a community member
// to become a community host.  It will include the information that the user filled out
// themselves and will include more fields for the distribution location to fill out.
import React, { useState } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// eslint-disable-next-line no-unused-vars
import ApplicationEmails from "../../MyAccountPage/Functions/ApplicationEmails.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

export default function ApproveCommunityPickupDialog({
  communityLocation,
  communityLocations,
  setCommunityLocations,
  index,
}) {
  const [open, setOpen] = useState(false);
  // communityHubType is for whether the user wants to be public no approval, private or public with approval
  const [communityHubType, setCommunityHubType] = useState(
    communityLocation.communityHubType,
  );
  // The community hub's name.
  const [locationName, setLocationName] = useState(
    communityLocation.locationName,
  );
  // The address of the community hub.
  const [address, setAddress] = useState(communityLocation.address);
  // The obscured address of the community hub that the community member added
  const [obscuredAddress, setObscuredAddress] = useState(
    communityLocation.obscuredAddress,
  );
  // The maximum number of people that are allowed to order in a given week.
  const [maxParticipants, setMaxParticipants] = useState(
    communityLocation.maxParticipants,
  );
  // The available times allowed for pick up.
  const [availableTimes, setAvailableTimes] = useState(
    communityLocation.availableTimes,
  );
  // Any additional comments for the location.
  const [additionalComments, setAdditionalComments] = useState(
    communityLocation.additionalComments,
  );
  // The pickup day allowed.
  const [pickupDay, setPickupDay] = useState("");
  // The longitude of the location.
  const [longitude, setLongitude] = useState("");
  // The latitude of the location.
  const [latitude, setLatitude] = useState("");
  // The latitude of the obscured address.
  const [obscuredLatitude, setObscuredLatitude] = useState("");
  // The longitude of the obscured address.
  const [obscuredLongitude, setObscuredLongitude] = useState("");

  const handleClose = () => {
    setOpen(false);
    setCommunityHubType(communityLocation.communityHubType);
    setLocationName(communityLocation.locationName);
    setAddress(communityLocation.address);
    setObscuredAddress(communityLocation.obscuredAddress);
    setMaxParticipants(communityLocation.maxParticipants);
    setAvailableTimes(communityLocation.availableTimes);
    setAdditionalComments(communityLocation.additionalComments);
    setPickupDay("");
    setLongitude("");
    setLatitude("");
    setObscuredLatitude("");
    setObscuredLongitude("");
  };

  // When the community hub is being saved and added.
  const handleSave = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();

    const documentName =
      communityLocation.firstName +
      communityLocation.lastName +
      communityLocation.userId;
    // References the database where the community locations exist.
    const docRef = database.collection("CommunityLocations").doc(documentName);

    // Update all the fields to equal the state which will be up to date.
    communityLocation.locationName = locationName;
    communityLocation.communityHubType = communityHubType;
    communityLocation.address = address;
    communityLocation.obscuredAddress = obscuredAddress;
    communityLocation.maxParticipants = maxParticipants;
    communityLocation.availableTimes = availableTimes;
    communityLocation.additionalComments = additionalComments;
    communityLocation.pickupDay = pickupDay;
    communityLocation.longitude = longitude;
    communityLocation.latitude = latitude;
    communityLocation.obscuredLatitude = obscuredLatitude;
    communityLocation.obscuredLongitude = obscuredLongitude;
    communityLocation.approved = true;

    // Update the database.
    batch.update(docRef, {
      locationName,
      address,
      communityHubType,
      obscuredAddress,
      maxParticipants,
      availableTimes,
      additionalComments,
      pickupDay,
      longitude,
      latitude,
      obscuredLatitude,
      obscuredLongitude,
      approved: true,
      approvedCommunityMembers: [communityLocation.userId],
    });

    // Update the user's database information.
    const userDocRef = database
      .collection("Users")
      .doc(communityLocation.userId);
    batch.update(userDocRef, {
      communityLocation,
    });

    // Update the community host pile to include the user themselves for their own
    // location.
    const communityMembersDocRef = database
      .collection("Users")
      .doc(communityLocation.userId)
      .collection("CommunityHosts")
      .doc("CommunityMembers");

    const keyLocation = [
      communityLocation.firstName,
      communityLocation.lastName,
      communityLocation.userId,
    ].join("-");

    batch.set(communityMembersDocRef, {
      [keyLocation]: true,
    });

    batch.commit();

    // Create a temporary variable with all the community locations.
    const communityLocationsTemp = communityLocations;
    // Find the index of this community location.
    communityLocationsTemp[index] = communityLocation;

    // Update the community locations for the distribution location.
    setCommunityLocations([...communityLocationsTemp]);
    console.log("Successfully wrote to database!");
    setOpen(false);
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => setOpen(true)}
      >
        APPROVE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Approve a Community Pickup Location
        </DialogTitle>
        <DialogContent>
          <p>
            You are approving {locationName} to be used as a community
            distribution location. Please fill out the remaining information.
            Only approve this once you have confirmed with the applying member
            and they understand their responsibilities.
          </p>
          <form onSubmit={handleSave}>
            <span>
              <FormLabel component="legend">Community Hub Type</FormLabel>
              <RadioGroup
                aria-label="communityHubType"
                name="communityHubType"
                value={communityHubType}
                onChange={(e) => setCommunityHubType(e.target.value)}
              >
                <FormControlLabel
                  value="publicApproval"
                  control={<Radio />}
                  label="Public, Approval Required"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private Hidden"
                />
                <FormControlLabel
                  value="publicNoApproval"
                  control={<Radio />}
                  label="Public, No Approval Required"
                />
              </RadioGroup>
              <TextField
                margin="dense"
                id="locationName"
                label="Location Name"
                name="locationName"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="address"
                label="Your Address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="latitude"
                label="Latitude"
                name="latitude"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="longitude"
                label="Longitude"
                name="longitude"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="obscuredAddress"
                label="Obscured Address"
                name="obscuredAddress"
                value={obscuredAddress}
                onChange={(e) => setObscuredAddress(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="obscuredLatitude"
                label="Obscured Latitude"
                name="obscuredLatitude"
                value={obscuredLatitude}
                onChange={(e) => setObscuredLatitude(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="obscuredLongitude"
                label="Obscured Longitude"
                name="obscuredLongitude"
                value={obscuredLongitude}
                onChange={(e) => setObscuredLongitude(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="maxParticipants"
                label="Maximum Participants Per Week"
                name="maxParticipants"
                type="number"
                value={maxParticipants}
                onChange={(e) => setMaxParticipants(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="pickupDay"
                label="Pickup Days for Customers"
                name="pickupDay"
                value={pickupDay}
                onChange={(e) => setPickupDay(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="availableTimes"
                label="What are the Available Times for Others to Pickup?"
                name="availableTimes"
                value={availableTimes}
                onChange={(e) => setAvailableTimes(e.target.value)}
                fullWidth
                required
              />

              <TextField
                id="additionalComments"
                label="Additional Comments?"
                placeholder="Questions, details about pickup spot, such as, sun exposure, accessiblity, etc."
                rowsMax={5}
                multiline
                variant="outlined"
                name="additionalComments"
                value={additionalComments}
                onChange={(e) => setAdditionalComments(e.target.value)}
                fullWidth
              />
            </span>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                CANCEL
              </Button>
              <Button type="submit" color="primary">
                APPROVE
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
