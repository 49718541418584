// The individual location's basket is called by the main Basket.js function.
// This renders the basket page for an individual user.
import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import PlacingOrderDialog from "../../../components/dialogs/PlacingOrderDialog.jsx";
import CustomizedSnackbars from "../../../components/snackBars/Snackbar.js";
import BasketFoodRow from "../Components/HeadersAndRows/BasketFoodRow.jsx";
import OrderSummary from "../Components/OrderSummary.jsx";
import CalculateCommunityLocationStatus from "../../../functions/CalculateCommunityLocationStatus.js";
import IndBasketHeaderInformation from "../Components/HeadersAndRows/IndBasketHeaderInformation.jsx";
import PropTypes from "prop-types";

export default function IndividualBasket({
  foodItems,
  userInfo,
  combinedFarmList,
  customerContribution,
  setCustomerContribution,
  donationSubsidy,
  setDonationSubsidy,
  farmTotals,
  packageTotals,
  dollarColour,
  textFieldColour,
  costTotal,
  grandTotal,
  placingOrder,
  snackBarOpen,
  handleRefresh,
  handleQuantityChange,
  handlePlaceOrder,
  handleSnackBarClose,
  deliveryFee,
  suggestedFees,
}) {
  // This sets the current status of a community host.
  const [currentStatus, setCurrentStatus] = useState([
    "loading",
    "loading",
    false,
  ]);

  // Set the communityLocation
  let communityLocation = userInfo.pickupLocation;

  // First make sure the user has selected a distribution location.
  if (communityLocation === undefined) {
    communityLocation = {};
  }
  // Calculates the current status of the community host if it exists.
  useEffect(() => {
    // First make sure the user has even selected a distribution location yet.
    if (communityLocation !== undefined) {
      // Check to see if the user has selected a community host.
      if (communityLocation.pickupLocation) {
        // Calculates the community host's status and sets the hooks.
        CalculateCommunityLocationStatus(
          userInfo.pickupLocation,
          setCurrentStatus,
          userInfo,
        );
      } else {
        // sets the status to failed if it doesn't exist.
        setCurrentStatus(["failed to load", "failed to load", false]);
      }
    } else {
      // sets the status to failed if it doesn't exist.
      setCurrentStatus(["failed to load", "failed to load", false]);
    }
  }, []);

  return (
    <div>
      <div className="Marketplace-Page-Header">
        <IndBasketHeaderInformation
          userInfo={userInfo}
          currentStatus={currentStatus}
        />
      </div>
      <div className="Page-Market">
        {currentStatus[0] !== "loading" &&
          communityLocation.maxParticipants < currentStatus[1] &&
          !currentStatus[2] && (
            <Alert severity="error">
              <AlertTitle>Location Full</AlertTitle>
              This location has reached its capacity of partipants{" "}
              {currentStatus[1]}/{communityLocation.maxParticipants}.
              <strong> Please select another pickup location!</strong>
            </Alert>
          )}
        {/* If your basket is empty or if the user is a distribution Location and
          the community orders are empty then tell the user it's empty otherwise
          render the basket items and farms */}
        {foodItems.length !== 0 ? (
          <div className="Order-Summary-And-Food-Items">
            {/* Create the rows of food and farms.  What is displayed depends
              on the userType */}
            <div
              style={{
                height: "fit-content",
                flexGrow: "1",
                marginRight: "20px",
                background: "white",
                maxHeight: "75vh",
                overflow: "auto",
                minWidth: "580px",
                border: "2px solid #9FBF59",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {/* Cycle through the list of food initialised at the beginning and
                create the rows */}

              {foodItems.map((row, idx) => (
                <BasketFoodRow
                  key={row + idx}
                  row={row}
                  checked={row.checked}
                  idx={idx}
                  handleRefresh={handleRefresh}
                  handleQuantityChange={handleQuantityChange}
                  userType={userInfo.userType}
                />
              ))}
            </div>
            <OrderSummary
              userInfo={userInfo}
              currentStatus={currentStatus}
              farmTotals={farmTotals}
              packageTotals={packageTotals}
              combinedFarmList={combinedFarmList}
              customerContribution={customerContribution}
              setDonationSubsidy={setDonationSubsidy}
              setCustomerContribution={setCustomerContribution}
              dollarColour={dollarColour}
              donationSubsidy={donationSubsidy}
              textFieldColour={textFieldColour}
              costTotal={costTotal}
              grandTotal={grandTotal}
              handlePlaceOrder={handlePlaceOrder}
              deliveryFee={deliveryFee}
              suggestedFees={suggestedFees}
            />
          </div>
        ) : (
          <div>
            <h3 className="Header-3"> No items in your Basket yet! </h3>
          </div>
        )}
        <PlacingOrderDialog open={placingOrder} />
        <CustomizedSnackbars
          message="Your order has been placed"
          open={snackBarOpen}
          handleClose={handleSnackBarClose}
        />
      </div>
    </div>
  );
}

IndividualBasket.propTypes = {
  foodItems: PropTypes.array,
  userInfo: PropTypes.object,
  combinedFarmList: PropTypes.array,
  customerContribution: PropTypes.string,
  setCustomerContribution: PropTypes.func,
  donationSubsidy: PropTypes.number,
  setDonationSubsidy: PropTypes.func,
  farmTotals: PropTypes.object,
  packageTotals: PropTypes.object,
  dollarColour: PropTypes.string,
  textFieldColour: PropTypes.string,
  costTotal: PropTypes.number,
  grandTotal: PropTypes.number,
  placingOrder: PropTypes.bool,
  snackBarOpen: PropTypes.bool,
  handleRefresh: PropTypes.func,
  handleQuantityChange: PropTypes.func,
  handlePlaceOrder: PropTypes.func,
  handleSnackBarClose: PropTypes.func,
  deliveryFee: PropTypes.number,
  suggestedFees: PropTypes.number,
};
