// Called to update individual community orders once they've been updated to include
// any modifications and make sure all the amounts still work out properly like the subsidy
// isn't too much making the order negative and the volunteer fees are correct.  This is called
// when the modification is happening to one specific community order and not coming from a modification
// to all user from a distribution location.
import PriceTotals from "../../../../functions/PriceTotals.js";
import PackageTotals from "../../../../functions/PackageTotals.js";
import AdjustVolunteerFee from "./../AdjustVolunteerFee.js";

export default function ModifyIndividualCommunityOrder(
  user,
  communityOrdersTemp,
  userId,
  userInfo,
  order,
  foodItemUpdate,
  updatedFoodItem,
  priceUpdate,
  database,
  batch,
) {
  // This is the original cost owed to each farm in a dictionary
  const originalOrderFarmsCost = PriceTotals(
    communityOrdersTemp[user].foodList,
    true,
  );
  // This is the original cost owed for all the packaging per farm in a dictionary
  const originalPackageCost = PackageTotals(communityOrdersTemp[user].foodList);
  // This is the total cost of the order with all the farm's owed added up.
  let originalOrderCost = 0;

  // // Cycle through the totals owed to each farm and add them up.
  Object.keys(originalOrderFarmsCost).forEach((farmName) => {
    originalOrderCost += originalOrderFarmsCost[farmName];
    // If the cost of the packaging is greater than 0 then add it to the original
    // cost.
    if (originalPackageCost[farmName] > 0) {
      originalOrderCost += originalPackageCost[farmName];
    }
  });

  // If the cash cashPortion of the volunteer fee is greater than 0 then add it to
  // the original cost.
  if (parseFloat(communityOrdersTemp[user].cashPortion) > 0) {
    originalOrderCost += parseFloat(communityOrdersTemp[user].cashPortion);
  }

  // Add or subtract the subsidy and donations to the total order cost.
  if (communityOrdersTemp[user].customerContribution === "donation") {
    originalOrderCost += communityOrdersTemp[user].donationSubsidy;
  } else {
    originalOrderCost -= communityOrdersTemp[user].donationSubsidy;
  }

  // Finds the index of the item to be updated in the individual customer's orders
  const index = communityOrdersTemp[user].foodList.findIndex(
    (element) =>
      element.item === foodItemUpdate.item &&
      element.description === foodItemUpdate.description &&
      element.farmName === foodItemUpdate.farmName,
  );

  // Updates the indivdual order in the communityOrders dictionary to the new updated
  // foodItem.
  communityOrdersTemp[user].foodList[index].item = updatedFoodItem.item;
  communityOrdersTemp[user].foodList[index].distributionQuantity =
    updatedFoodItem.distributionQuantity;
  communityOrdersTemp[user].foodList[index].description =
    updatedFoodItem.description;
  communityOrdersTemp[user].foodList[index].individualQuantity =
    updatedFoodItem.individualQuantity;
  communityOrdersTemp[user].foodList[index].individualDescription =
    updatedFoodItem.individualDescription;
  communityOrdersTemp[user].foodList[index].suggestedPrice =
    updatedFoodItem.suggestedPrice;
  communityOrdersTemp[user].foodList[index].price = updatedFoodItem.price;
  communityOrdersTemp[user].foodList[index].category = updatedFoodItem.category;
  communityOrdersTemp[user].foodList[index].packageFee =
    updatedFoodItem.packageFee;
  communityOrdersTemp[user].foodList[index].displayImage =
    updatedFoodItem.displayImage;
  communityOrdersTemp[user].foodList[index].farmName = updatedFoodItem.farmName;
  // If the limit exists then set the limit otherwise delete the limit so it's not
  // on the item.
  if (updatedFoodItem.limit) {
    communityOrdersTemp[user].foodList[index].limit = updatedFoodItem.limit;
  } else {
    delete communityOrdersTemp[user].foodList[index].limit;
  }
  communityOrdersTemp[user].foodList[index].quantity = parseInt(
    updatedFoodItem.quantity,
  );

  // If price Update is true then we know that the modification to the order is
  // triggering a price change and we will need to update the payment history and the
  // volunteer fees.
  if (priceUpdate) {
    // Set the total order cost.
    let orderTotal = 0;
    // Cycle through the user's order and add up the total price of the order
    communityOrdersTemp[user].foodList.forEach((foodItem) => {
      orderTotal +=
        foodItem.quantity *
        ((foodItem.price / foodItem.distributionQuantity) *
          foodItem.individualQuantity);
    });

    // Adjust the volunteer fee and update the credits and cash portion and issue credit refunds.
    AdjustVolunteerFee(
      orderTotal,
      communityOrdersTemp[user],
      user,
      userInfo,
      {},
      database,
      batch,
    );
    // Add the cash portion to the order total.
    orderTotal += parseFloat(communityOrdersTemp[user].cashPortion);

    // Cycle through the user's order and add up the packaging fee.  We want to do
    // this after the volunteer fee has been calculated as we only want to calculate
    // the volunteer fees based off the food costs.
    communityOrdersTemp[user].foodList.forEach((foodItem) => {
      // If the package fee is greater than 0 then add it to the total.
      if (foodItem.packageFee > 0) {
        orderTotal += foodItem.packageFee * foodItem.quantity;
      }
    });

    // Add the delivery fee if there is one.
    if (communityOrdersTemp[user].deliveryFee > 0) {
      orderTotal += communityOrdersTemp[user].deliveryFee;
    }

    // If the deliveryFee is undefined, but there is a community pickup then a delivery
    // fee will be added and so we don't want to update the donationSubsidy yet as that will
    // be done when the delivery fee is finalized.
    if (
      !(
        communityOrdersTemp[user].deliveryFee === undefined &&
        communityOrdersTemp[user].communityPickup.pickupLocation !== undefined
      )
    ) {
      // Check that if the user is using a subsidy it is not more than what they’re using
      if (
        communityOrdersTemp[user].customerContribution === "subsidy" &&
        communityOrdersTemp[user].donationSubsidy > orderTotal
      ) {
        // Check to see if the subsidy used is larger than the orderTotal and if it is
        // then the reset the orderTotal.
        communityOrdersTemp[user].donationSubsidy = orderTotal;
      }
    }
  }
  return originalOrderCost;
}
