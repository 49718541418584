// Called by CreateVisibleCommunityOrders and will create an html element that can be
// converted into a word document to print out the orders for a user.
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";

// This function calculates the total for the user's order including all their fees.
function UserGrandTotal(userOrder, customerCount) {
  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);
  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);
  // The total including the donation or subsidy
  let grandTotal = 0;
  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
  });

  // Cycle through the package Totals and add them to the grandTotal.
  Object.keys(packageTotals).forEach((packageTotal) => {
    grandTotal += packageTotals[packageTotal];
  });

  // Add in the subdidy and donation amounts
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // If the volunteer cash portion is greater than 0 then we need to add to the
  // grandTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
  }

  // If the delivery fee is greater than 0 then add it to the grandTotal.
  if (parseFloat(userOrder.deliveryFee) > 0) {
    grandTotal += userOrder.deliveryFee;
  }
  // Otherwise we will check if the user is picking up from a community host.
  else if (userOrder.communityPickup.pickupLocation !== undefined) {
    // If the community hub's partner location has a base delivery fee then calculate the delivery fee
    // from the current customer count and add it to the total.
    if (
      userOrder.communityPickup.pickupLocation.baseDeliveryFee !== undefined
    ) {
      grandTotal +=
        userOrder.communityPickup.pickupLocation.baseDeliveryFee /
        parseInt(customerCount);
    }
  }

  // Make sure that the grandTotal isn't ever negative.
  if (grandTotal < 0) {
    grandTotal = 0;
  }
  return grandTotal;
}

// This function takes community orders which is a dictionary of each order placed
// for this distribution location as the argument.  Each location, distribution location
// or community host is key with the values being that locations orders.  It combines multiple
// orders that were placed by the same user and creates a new dictionary that
// copies the data from the community orders and adds a foodDict and farmDict for
// easier use when trying to print out the message.  It writes the text out as html
// which is later converted to a word doc by another function.
export default function CreateDownloadOrdersString(
  communityOrdersByLocationDict,
  selectedDate,
) {
  // This stores each order string separately so we can use them later
  // to make the grids.
  const ordersData = [];
  // These are all the locations to print.  They key will be
  // the location.
  const downloadFiles = {};
  // The list of orders in Grid format.
  let downloadFileGrid = `<div><table style="width: 100%; border-collapse: collapse;">`;
  // This is the total list of pickup locations.  We want to sort them and make sure
  // that pickup on site is first so we will create an area of the keys.
  const listOfPickups = [];
  // Cycle through the keys and add them.
  Object.keys(communityOrdersByLocationDict).forEach((pickupLocation) => {
    listOfPickups.push(pickupLocation);
  });
  // Sort the list alphabetically
  listOfPickups.sort();
  // Add the Pickup On Site to the front of the list
  listOfPickups.sort(function (x, y) {
    // eslint-disable-next-line eqeqeq
    return x == "Pickup On Site" ? -1 : y == "Pickup On Site" ? 1 : 0;
  });

  // If there are no orders being picked up on site then remove that pickup.  This
  // will only be done for pick up on site as other pick up locations won't exist if
  // there aren't any orders for them.
  if (
    Object.keys(communityOrdersByLocationDict["Pickup On Site"]).length === 0
  ) {
    // Remove the first element of the list which we know is Pickup On Site.
    listOfPickups.shift();
  }

  // Cycle through all they pickup locations.
  listOfPickups.forEach((pickupLocation) => {
    // The title of the doc which is the distribution location and the date in list format.
    let downloadFile = `<h2> ${pickupLocation} on ${selectedDate} </h2><br/>`;
    // Add the starting float and the collected for the site lines.
    downloadFile = downloadFile.concat(
      `Starting Float : ________________<br/>`,
    );
    downloadFile = downloadFile.concat(
      `Cash Collected : ________________<br/>`,
    );

    // Cycle through the community orders at this pickup location.
    Object.keys(communityOrdersByLocationDict[pickupLocation]).forEach(
      (user) => {
        // Create the user's name string which is their first name.
        const userName = `${user.split("-")[0]}`;
        // create an easy to use reference to the user's order.
        const userOrder = communityOrdersByLocationDict[pickupLocation][user];
        // Set the phone number
        let phoneNumber = userOrder.phoneNumber;
        // If there is no phone number then reset it to empty.
        if (phoneNumber == null) {
          phoneNumber = "";
        }
        // The length of the array will show many customers at a location there are.
        const customerCount = Object.keys(
          communityOrdersByLocationDict[pickupLocation],
        ).length;

        // This is used to put each order into a string.
        let userOrderString = "";
        // Add the user's name, pickup location and their phone number.
        userOrderString = userOrderString.concat(
          `<h5 style = "font-size : 15px; margin-bottom : 5px">${userName}  |  <text style = "font-weight : 400">${pickupLocation}</text>: </h5> <p style= "margin: 5px 0px; font-size: 12px">${phoneNumber}</p>`,
        );

        // Cycle through the user's foodList and add each item to the file.
        // eslint-disable-next-line array-callback-return
        userOrder.foodList.map((food, itemNumber) => {
          // The price of the item for the individual.
          let individualPrice = parseFloat(
            food.price / (food.distributionQuantity / food.individualQuantity),
          );
          // The individual description of the food item for the user to know how
          // much they are purchasing.
          let individualDescription = food.individualDescription;

          // if suggestedPrice is true then we want to set the price to 0
          if (food.suggestedPrice) {
            // First add to the description the suggested Donation.
            // eslint-disable-next-line no-unused-vars
            individualDescription = individualDescription.concat(
              " - Suggested Donation of $",
              individualPrice,
            );
            // Then reset the prices to 0.
            individualPrice = 0.0;
          }

          // Add the packaging fee if there is one.
          if (food.packageFee > 0) {
            individualPrice += parseFloat(food.packageFee);
          }
          // Find the total cost of the item including multiple orders of this item.
          const individualPriceTotal = parseFloat(
            individualPrice * food.quantity,
          ).toFixed(2);
          // This is the individual price fixed to two decimals.
          individualPrice = individualPrice.toFixed(2);

          // The colour of the multiplier that shows the quantity of an item
          // ex. Potatoes x2
          let colourOfMultiplier = "black";

          // If the quantity is greater than 1 then make it red so it stands out more.
          if (food.quantity > 1) {
            colourOfMultiplier = "red";
          }
          // Add the food items to the order.
          userOrderString =
            userOrderString.concat(`<p style = "font-size: 12px; margin : 0px 0px 0px 20px">
                                              ${itemNumber + 1}. <b><u>${
                                                food.item
                                              }</u></b> -> <text style = "color : purple">${
                                                food.individualDescription
                                              }</text>  <b><u style = "color : ${colourOfMultiplier}">x${
                                                food.quantity
                                              }</u></b>  <i>${
                                                food.farmName
                                              }</i> -- <text style = "font-size : 10px">$${individualPrice} ($${individualPriceTotal})</text>
                                            </p>`);
        });

        // Add the order without the total to the ordersData
        ordersData.push(userOrderString);

        // Calculate the user's order total.
        const grandTotal = UserGrandTotal(userOrder, customerCount);
        // Add the total to the usersOrder file.
        userOrderString = userOrderString.concat(
          `<p style = "font-size: 12px; margin: 10px 0px 0px 0px">Total : $${grandTotal.toFixed(
            2,
          )}</p>`,
        );
        // Add to the downloadFile which will be used to store the orders
        // in a string format in a list.
        downloadFile = downloadFile.concat(userOrderString);
      },
    );
    // Add this file to the list of locations.
    downloadFiles[pickupLocation] = downloadFile;
  });

  // Cycle through all the orders and add them to the
  // grid format.
  ordersData.forEach((order, index) => {
    // If the order is odd we want it on a new row.
    if (index % 2 === 0) {
      downloadFileGrid += `<tr>`;
    }
    // Add the row styling, order, etc.
    downloadFileGrid += `<td style="width: 50%; vertical-align: top; border: 1px solid black; padding: 5px; page-break-inside: avoid;">`;
    downloadFileGrid += order;
    downloadFileGrid += `</td>`;
    // If the order is even then it's the second item and is
    // the end of the row.
    if (index % 2 !== 0) {
      downloadFileGrid += `</tr>`;
    }
  });
  // Finish the table.
  downloadFileGrid += `</table></div>`;
  // Return both the formats in list and grid.
  return [downloadFiles, downloadFileGrid];
}
