// This dialog appears when a distribution location is changing a single order
// from a community order or from an imported order from another distribution location.
import React, { useState, useContext } from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
// eslint-disable-next-line no-unused-vars
import CommunityOrdersAffected from "../../../functions/CommunityOrdersAffected.js";
import UpdateFoodItemsCommunityOrders from "../Functions/UpdateFoodItemsCommunityOrders.js";
import { ChangeOrderLogContext } from "../../MyAccount.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// This is the dialog box that opens when a user clicks the edit button
function EditCommunityOrdersItemBox(props) {
  const { onClose, open } = props;
  // Since suggestedPrice was added later on we have to make sure
  // that is defined otherwise it will trigger a bug.
  let suggestedPriceProps = props.suggestedPrice;
  if (props.suggestedPrice === undefined) {
    suggestedPriceProps = false;
  }

  // These are all the options that can be changed for the user.  They can are initialized
  // to the value of the props sent in but are separate as they are what can be modified.
  const [item, setItem] = useState(props.item);
  const [distributionQuantity, setDistributionQuantity] = useState(
    props.distributionQuantity,
  );
  const [description, setDescription] = useState(props.description);
  const [individualQuantity, setIndividualQuantity] = useState(
    props.individualQuantity,
  );
  const [individualDescription, setIndividualDescription] = useState(
    props.individualDescription,
  );
  const [suggestedPrice, setSuggestedPrice] = useState(suggestedPriceProps);
  const [price, setPrice] = useState(props.price);
  const [category, setCategory] = useState(props.category);
  const [displayImage, setDisplayImage] = useState(props.displayImage);
  const [quantity, setQuantity] = useState(props.quantity);
  const [farmName, setFarmName] = useState(props.farmName);
  const [limit, setLimit] = useState(props.limit);
  const [packageFee, setPackageFee] = useState(props.packageFee);

  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  // If the user closes or cancels their changes then set everything back to what
  // the original values were sent in through the props.
  const handleClose = () => {
    onClose(false);
    setItem(props.item);
    setDistributionQuantity(props.distributionQuantity);
    setDescription(props.description);
    setIndividualQuantity(props.individualQuantity);
    setIndividualDescription(props.individualDescription);
    setSuggestedPrice(suggestedPriceProps);
    setPrice(props.price);
    setCategory(props.category);
    setDisplayImage(props.displayImage);
    setQuantity(props.quantity);
    setFarmName(props.farmName);
    setLimit(props.limit);
    setPackageFee(props.packageFee);
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();

    // This sends in all the values the user could have changed that need to be
    // updated and updated the database.
    UpdateFoodItemsCommunityOrders(
      props,
      item,
      distributionQuantity,
      description,
      individualQuantity,
      individualDescription,
      suggestedPrice,
      price,
      category,
      displayImage,
      quantity,
      farmName,
      limit,
      packageFee,
      updatePaymentHistory,
    );
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Fill in information about the item
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <span>
            <TextField
              margin="dense"
              id="item"
              label="Item"
              name="item"
              value={item}
              onChange={(e) => setItem(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="distributionQuantity"
              label="Distribution Quantity"
              name="distributionQuantity"
              type="number"
              value={distributionQuantity}
              onChange={(e) => setDistributionQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Distribution Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="individualQuantity"
              label="Individual Quantity"
              name="individualQuantity"
              type="number"
              value={individualQuantity}
              onChange={(e) => setIndividualQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="individualDescription"
              label="Individual Description"
              name="individualDescription"
              value={individualDescription}
              onChange={(e) => setIndividualDescription(e.target.value)}
              fullWidth
              required
            />
            <FormControlLabel
              label="Suggested Price"
              control={
                <Switch
                  checked={suggestedPrice}
                  name="suggestedPrice"
                  onChange={() => setSuggestedPrice(!suggestedPrice)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
            <TextField
              margin="dense"
              id="price"
              label="Price ($CAD)"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="category"
              label="Category"
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="displayImage"
              label="Display Image"
              name="displayImage"
              value={displayImage}
              onChange={(e) => setDisplayImage(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="quantity"
              label="Quantity"
              name="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="farmName"
              name="farmName"
              label="Supplying Farm"
              value={farmName}
              onChange={(e) => setFarmName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="limit"
              name="limit"
              label="Remaining in Pantry"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              fullWidth
              type="number"
            />
            <TextField
              margin="dense"
              id="packageFee"
              label="Package Fee"
              name="packageFee"
              value={packageFee}
              onChange={(e) => setPackageFee(e.target.value)}
              type="number"
              fullWidth
              required
            />
          </span>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditCommunityOrdersItemBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EditCommunityOrdersItemDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <EditCommunityOrdersItemBox
        open={open}
        onClose={handleClose}
        item={props.item}
        distributionQuantity={props.distributionQuantity}
        description={props.description}
        individualQuantity={props.individualQuantity}
        individualDescription={props.individualDescription}
        suggestedPrice={props.suggestedPrice}
        price={props.price}
        category={props.category}
        displayImage={props.displayImage}
        quantity={props.quantity}
        farmName={props.farmName}
        order={props.order}
        foodItemUpdate={props.foodItemUpdate}
        orderLog={props.orderLog}
        updateOrderLog={props.updateOrderLog}
        orderIndex={props.orderIndex}
        userInfo={props.userInfo}
        user={props.user}
        imported={props.imported}
        location={props.location}
        limit={props.limit}
        packageFee={props.packageFee}
      />
    </div>
  );
}
