// This is used when a user is applying to become a community host.  It displays
// information about the distribution location that the community member would be
// partnered with in a card on the dialog.  Called by CommunityPickupFormDialog.jsx
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function DistributionLocationCard({ pickupLocation }) {
  const classes = useStyles();

  // Make sure the user has a pickupLocation defined.
  if (pickupLocation !== undefined) {
    // Check if the user's pickupLocation has a pickupLocation property which would
    // mean the user has a community host as their current pickup location.
    if (pickupLocation.pickupLocation !== undefined) {
      // set the pickupLocation to the distribution location of the current community
      // host.
      pickupLocation = pickupLocation.pickupLocation;
    }
  }
  // In case there is no pickupLocation set a default
  else {
    pickupLocation = {};
  }

  return (
    <Card className={classes.root} variant="outlined">
      {Object.keys(pickupLocation).length === 0 ? (
        <CardContent>
          Please first select a distribution location partner by clicking
          &quot;CHANGE&quot;.
        </CardContent>
      ) : (
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Partnered Distribution Location
          </Typography>

          <Typography variant="h5" component="h2">
            {pickupLocation.name}
          </Typography>
          <Typography>
            <strong>Address: </strong>
            {pickupLocation.address}
          </Typography>
          <Typography>
            <strong>Pickup Day: </strong>
            {pickupLocation.pickupDay}
          </Typography>
          <Typography>
            <strong>Pickup Time: </strong>
            {pickupLocation.pickupTime}
          </Typography>
          <Typography>
            <strong>Order Cutoff: </strong>
            {pickupLocation.orderCutoff}
          </Typography>
          <Typography>
            <strong>Max Subsidy Per Order: </strong>$
            {pickupLocation.subsidyAmount.toFixed(2)}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}
