// This component is called by the indivdiual basket.  This is placed on the basket
// page and includes information about the the distribution location pickup information.
import React, { useEffect, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FarmTotalLineIndividual from "../Components/FarmTotalLineIndividual.jsx";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import SimplePopover from "../../../components/userInputs/SimplePopover.js";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import UpdateDonationSubsidy from "../Functions/UserInputs/UpdateDonationSubsidy.js";
import ConfirmOrderDialog from "../../../components/dialogs/ConfirmOrder.js";
import VolunteerFee from "./VolunteerFee.jsx";
import { NonFoodCostsContext } from "../../Basket.js";
import AnnouncementDialog from "./Dialogs/AnnouncementDialog.jsx";
import QuestionnaireDialog from "./Dialogs/QuestionnaireDialog.jsx";
import PropTypes from "prop-types";
import CalcMaxSubsidy from "../Functions/UserInputs/CalcMaxSubsidy.js";

// This is a little square that is shown on the indivdiual user's basket page.
// It provides the user with information on picking food up and changing the
// distribution.
export default function OrderSummary({
  userInfo,
  currentStatus,
  farmTotals,
  packageTotals,
  combinedFarmList,
  customerContribution,
  setDonationSubsidy,
  setCustomerContribution,
  dollarColour,
  donationSubsidy,
  textFieldColour,
  costTotal,
  grandTotal,
  handlePlaceOrder,
  deliveryFee,
  suggestedFees,
}) {
  // This holds the state of whether or not the notice will open.
  const [openNotice, setOpenNotice] = useState(false);

  // This is used to indicate when max subsidy line needs to shake to indicate to
  // the user that they are trying to go over the allowed amount.  It changes the className
  // to trigger the shake that lasts for 1 second.  When it is nothing than it has no class.
  const [shake, setShake] = useState("nothing");
  const { cashPortion } = useContext(NonFoodCostsContext);
  // The amount of subsidy they have available without matching
  const [freeSubsidyAmount, setFreeSubsidyAmount] = useState(0);
  // The amount of subsidy the user has used this week already.
  const [usedSubsidyAmount, setUsedSubsidyAmount] = useState(0);
  // The subsidy they have available including the matching portion.
  const [subsidyAmount, setSubsidyAmount] = useState(0);
  // The pickup location that is a distribution location.
  let pickupLocation = userInfo.pickupLocation;
  // The package fee total which we set to 0.
  let packageFeeTotal = 0;

  // Sets the community location.
  const communityLocation = userInfo.pickupLocation;

  // Checks to see if the user has a community location and if so then update the
  // pickup location to the distribution location.
  if (communityLocation.pickupLocation) {
    pickupLocation = communityLocation.pickupLocation;
  }

  const subsidyExplanation = `Our subsidy program is designed to assist those in need, thanks to the generosity of our community members. Each user can access a maximum subsidy of $${pickupLocation.subsidyAmount.toFixed(
    2,
  )}. If a user contributes an equal amount through matching, they can access the full subsidy every week. If a user is unable to contribute financially, the subsidy resets to its maximum every ${
    pickupLocation.freqOfFreeSubsidy
  } weeks. This system encourages both receiving and giving within our community while ensuring that everyone has access to essential assistance to support our farmers.`;

  // The dictionary that says which questionnaires are currently active and their creation date.
  // {questionnaireCreationDate : DateQuestionnaireWasAdded}
  let questionnaireList = pickupLocation.questionnaireList;

  // If questionnaireList doesn't exist then set it to an empty dictionary.
  if (questionnaireList === undefined) {
    questionnaireList = {};
  }

  // User's total credit balance.
  let userCredits = userInfo.credits;
  // If the user doesn't have this key then set their credits to 0.
  if (userCredits === undefined) {
    userCredits = 0;
  }

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
  });

  // Finds the user's past orders and determines how much free subsidy
  // they have available.
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchSubsidy = async () => {
      try {
        const [freeSubsidy, usedSubsidy] = await CalcMaxSubsidy(
          userInfo,
          pickupLocation,
        );
        if (isMounted) {
          setFreeSubsidyAmount(freeSubsidy);
          setUsedSubsidyAmount(usedSubsidy);
        }
      } catch (error) {
        console.error("Error fetching subsidy:", error);
      }
    };

    fetchSubsidy();

    // Cleanup function to run when the component unmounts
    return () => {
      isMounted = false; // Set the flag to false when unmounting
    };
  }, []);

  // This is used to update the subsidy available when a user changes
  // their order which may affect how much subsidy can be matched.
  useEffect(() => {
    // The total cost of the order excluding the donation/subsidy.
    const totalCostIncFees =
      packageFeeTotal +
      costTotal +
      parseFloat(deliveryFee) +
      parseFloat(cashPortion);

    // Calculate how much the user could matched after using all
    // of the free subsidy.  If the free subsidy is greater than
    // ability to match then this won't add to their max subsidy.
    let matchedPortion = (totalCostIncFees - freeSubsidyAmount) / 2;
    if (matchedPortion < 0) {
      matchedPortion = 0;
    }

    // The subsidy available is the free subsidy amount plus their
    // matched portion.
    let subsidyAmountTemp = freeSubsidyAmount + matchedPortion;

    // Make sure that their subsidy doesn't go above the available
    // subsidy amount even if they spend more.
    if (subsidyAmountTemp > pickupLocation.subsidyAmount) {
      subsidyAmountTemp = pickupLocation.subsidyAmount;
    }

    // The usedSubsidyAmount is how much the user used already
    // this week so we will subtract that from their total regardless.
    subsidyAmountTemp += usedSubsidyAmount;
    // If their subsidy goes below 0 for some ready then make it
    // 0.
    if (subsidyAmountTemp < 0) {
      subsidyAmountTemp = 0;
    }
    // Make sure that the subsidy amount doesn't go above the max amount.
    else if (subsidyAmountTemp > pickupLocation.subsidyAmount) {
      subsidyAmountTemp = pickupLocation.subsidyAmount;
    }

    setSubsidyAmount(subsidyAmountTemp);
  }, [freeSubsidyAmount, usedSubsidyAmount, costTotal, cashPortion]);

  return (
    <div className="Order-Summary-Box">
      {pickupLocation === undefined ? (
        <div>
          <h3 className="Header-3">Your Pickup Location</h3>
          <p>
            Please select a location
            <Link
              to={{
                pathname: "/DistributionLocationSelection",
                query: "/Basket",
              }}
            >
              <Button color="primary">CHANGE</Button>
            </Link>
          </p>
        </div>
      ) : (
        <div>
          <h3>Order Summary</h3>
          <h5>
            <u>Total Paid to Vendors </u>
          </h5>

          <div className="Checkout">
            <div className="Totals-And-Button">
              {Object.keys(farmTotals).map((farmTotal, index) =>
                FarmTotalLineIndividual(
                  farmTotals,
                  farmTotal,
                  combinedFarmList,
                  index,
                  userInfo.userType,
                  packageTotals,
                ),
              )}
              {packageFeeTotal > 0 && (
                <p className="Total-Fees">
                  Total Package Fees: ${packageFeeTotal.toFixed(2)}
                </p>
              )}

              {!!communityLocation.pickupLocation && (
                <>
                  {currentStatus[2] ? (
                    <p className="Total-Fees">
                      <s>Delivery Charge:</s>
                      <p
                        style={{
                          margin: "0px 0px 0px 5px",
                          fontSize: "10px",
                          color: "gray",
                        }}
                      >
                        Adding to existing order
                      </p>
                    </p>
                  ) : (
                    <p className="Total-Fees">
                      Delivery Charge: ${deliveryFee.toFixed(2)}
                    </p>
                  )}
                </>
              )}
              {pickupLocation.volunteerPercentage > 0 && (
                <VolunteerFee
                  costTotal={costTotal}
                  pickupLocation={pickupLocation}
                  userInfo={userInfo}
                  setDonationSubsidy={setDonationSubsidy}
                  customerContribution={customerContribution}
                  suggestedFees={suggestedFees}
                />
              )}

              {/* Reset the value of the donationSubsidy to 0 when the user
                changes to the subsidy radioButton.  This is to prevent mistakenly
                going into the negative if the value is bigger than what is owed */}
              <FormControl style={{ marginTop: "10px", alignItems: "end" }}>
                <FormLabel className={shake}>
                  Optional: (Max Subsidy is ${subsidyAmount.toFixed(2)})
                </FormLabel>
                <span className="Radio-TextField">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={customerContribution}
                    onChange={(event) => {
                      setDonationSubsidy(0);
                      setCustomerContribution(event.target.value);
                    }}
                  >
                    <span className="Info-And-Radio-Button">
                      <SimplePopover content="Donations are collected to provide subsidies for others to buy food.  100% of the donations are used to directly subsidize guests that cannot afford to pay full price." />
                      <FormControlLabel
                        value="donation"
                        control={<Radio />}
                        label="Donation"
                      />
                    </span>
                    <span className="Info-And-Radio-Button">
                      <SimplePopover content={subsidyExplanation} />
                      <FormControlLabel
                        value="subsidy"
                        control={<Radio />}
                        label="Subsidy"
                      />
                    </span>
                  </RadioGroup>
                  <p
                    className="Donation-Subsidy-Text-Field"
                    style={{ color: dollarColour }}
                  >
                    : $
                  </p>
                  {
                    <TextField
                      value={donationSubsidy}
                      style={{ maxWidth: "60px" }}
                      color={textFieldColour}
                      type="number"
                      onChange={(e) => {
                        UpdateDonationSubsidy(
                          e.target.value,
                          customerContribution,
                          freeSubsidyAmount,
                          setDonationSubsidy,
                          costTotal,
                          setShake,
                          packageFeeTotal,
                          deliveryFee,
                          cashPortion,
                          pickupLocation,
                        );
                      }}
                    />
                  }
                </span>
              </FormControl>
            </div>
          </div>

          <p className="Total-Owed-Font">Total : ${grandTotal.toFixed(2)}</p>

          {currentStatus[0] !== "loading" &&
          communityLocation.maxParticipants < currentStatus[1] &&
          !currentStatus[2] ? (
            <Button disabled>LOCATION FULL</Button>
          ) : (
            <ConfirmOrderDialog
              message="Place Order"
              placeOrder={handlePlaceOrder}
              userInfo={userInfo}
              farmTotals={farmTotals}
              farmerInfo={combinedFarmList}
              grandTotal={grandTotal}
              customerContribution={customerContribution}
              donationSubsidy={donationSubsidy}
              setOpenNotice={setOpenNotice}
            />
          )}
          <AnnouncementDialog
            openNotice={openNotice}
            setOpenNotice={setOpenNotice}
            pickupLocation={pickupLocation}
          />
          {Object.keys(questionnaireList).map((docKey, index) => (
            <QuestionnaireDialog
              key={index}
              openNotice={openNotice}
              docKey={parseInt(docKey)}
              pickupLocation={pickupLocation}
              userId={userInfo.userId}
            />
          ))}
        </div>
      )}
    </div>
  );
}

OrderSummary.propTypes = {
  userInfo: PropTypes.object,
  currentStatus: PropTypes.array,
  farmTotals: PropTypes.object,
  packageTotals: PropTypes.object,
  combinedFarmList: PropTypes.array,
  customerContribution: PropTypes.string,
  setDonationSubsidy: PropTypes.func,
  setCustomerContribution: PropTypes.func,
  dollarColour: PropTypes.string,
  donationSubsidy: PropTypes.number,
  textFieldColour: PropTypes.string,
  costTotal: PropTypes.number,
  grandTotal: PropTypes.number,
  handlePlaceOrder: PropTypes.func,
  deliveryFee: PropTypes.number,
  suggestedFees: PropTypes.number,
};
