// The About Us page shows the Concept write up, How it works, and supporting
// organisations.
import React, { useLayoutEffect } from "react";
import Menu from "../components/Menu.js";
import "../styles/AboutUs.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import HowItWorksSteps from "./AboutUsPage/Components/HowItWorksSteps.jsx";
import Button from "@material-ui/core/Button";
import ColourButton from "../components/userInputs/ColourButton.js";

export default function AboutUs() {
  // The details of each step.
  const stepsDetails = [
    {
      title: "Virtual Farmers Market",
      src: "https://lh3.googleusercontent.com/pw/AM-JKLX-Md2dEbWo0PWdgL7x8vDFeohh4OG1v8K0CxymWe6wJz202q3GRyeO2J6jvsdB3Hsz-AXHgQOX_vH0lgJbiZqMRDWSm7dl7Wwt3aF00XOF9egxzY5h2kYnr6xGGB_P-7wF3z7UNweK4wqUO_dUmHLG=w1178-h560-no?authuser=1",
      text: "Food vendors upload their available items every week for customers to browse and order. Community members can order on their own, or call a volunteer to help them place their order. \n\nThere are multiple partner distribution locations, as well as community pick up hubs, allowing customers to choose their most convenient place to pick up.",
    },
    {
      title: "Pay What You Can",
      src: "https://lh3.googleusercontent.com/pw/AM-JKLU7S90TK4jMuzPKXrAMy9rA8gVR9vcy92vvZ7FjXtXsYrHjnxPgx8FUiavTO6YeiTCWnjzEOxOL-sh5hNf2EelKyKPTiPSe-V1HsAOtgA5_VhI86s7bj8R3GRNJH8gjmqgFFpM-76PlZ-Yl3P2-Znio=w1178-h576-no?authuser=1",
      text: "Upon checkout, customers choose one of three options: \n1. Pay the cost of their food.  \n2. Use a subsidy to pay what they can. \n3. Pay the cost of their food and pay it forward. \n\nPAY WHAT YOU CAN:\nCustomers who need assistance in purchasing their food can select to use a subsidy at checkout. The subsidy amount is automatically deducted from their total cost.  The process provides community members with access to support in a discreet and dignified way.\n\nPAY IT FORWARD:\nCommunity members can contribute a donation at checkout to help subsidize food for those who need it. At the end of each year a tax receipt is issued for the total amount donated.  \n\nCommunity Members can monitor each week’s order to view and suggest improvements to the allocation of donations.",
    },
    {
      title: "Order Compilation",
      src: "https://lh3.googleusercontent.com/pw/AM-JKLVm6-9vQYUiEagURg8M0ivPRx31QLakA8gdmCZPw6ij6B0LpZ8kl-VSivMHGA9h2-F3RlqLNvE1OxDtRk_MA_xApUA33SuPsmUb3abJ1S0sprT_tXWuJYjRIKBpmejZij6BmvFpPCbDRcq22-l2uXjZ=w1103-h789-no?authuser=1",
      text: "Customers order directly from the vendors.  Each week a communal order is compiled for each individual vendor and sent directly to them.  By ordering collectively, we are able to meet vendor minimum orders that they require to cover their transportation and labour costs incurred by the delivery.  Payment is then issued to each vendor by a supporting organisation on behalf of the community.",
    },
    {
      title: "Distribution",
      src: "https://lh3.googleusercontent.com/pw/AM-JKLVGFr4BxuIzZZ0kZqAAzvDEBO4cOsJVsCfz9YHUpnJuHoxbxf1QfkuAkJaivL0HV69GGnWz4zXFriV6zfK0VkdUTowTICghwP_H5osHHqPo05x_0ikvFkn4851simLxplKgsLycsu9y7d32IVLiLRZk=w1205-h766-no?authuser=1",
      text: "Vendors deliver our food to the hub at JFS Kitchen.   Upon arrival volunteers sort and package all of our orders.  The orders are then distributed to community members through JFS or our other partnered organisations.  \n\nIndividuals can offer their homes to be used as a pickup location for themselves and their neighbours by becoming a Community Hub.  Community Hubs help create connections and increase efficiency of deliveries.  Let us know if you’re interested in learning more!",
    },
    {
      title: "Data Sharing",
      src: "https://lh3.googleusercontent.com/pw/AM-JKLXHZmcmVuhPWgN8GJu28RGaY0Ny5EBNEvD2i199ChMp9DiMwAWMPW7B5Iw8yAV8ocQKQzzzi7vHclqlS5FiU-5jVrOs0RtIPRSBwkfam6CVKBgoAYmBbK0sVQyUTus4UgGuEx6al76nIDOq8QX8_Uty=w1073-h789-no?authuser=1",
      text: "All information regarding vendor payments and individual customer orders, including details of donations, subsidies, and items ordered, is anonymized before being posted on the website. You can access this information by heading to the Community Tab and scrolling down to the Program Data and Detailed Information section.\n\nWhy?\nTransparency is fundamental to the Farm-to-Plate Marketplace Program. By safeguarding individuals' privacy and anonymity, we provide collective data to empower community members with actionable insights. These insights enable us to collectively address challenges and enhance access to healthy local food.",
    },
  ];

  // The details of each organisation.
  const supportingOrganisations = [
    {
      name: "Jewish Family Services",
      src: "https://lh3.googleusercontent.com/wiBWmwH5DU1Q-Wt7t9OIk2oZt5bIAABdWwHmpWZrdQdCNC6Xye3v5PyB2OChYo_s5YXAF1TqkcWQoOptriUWvw7RsZ9UL_alUDml2PZQW8IYfcqO_vsiE1vEUfvxv7IF2IF2TPodiGi6zSbJ9e3WpQV4488x3rVPrUXPl2aW5_fzF4B-7RC11DVamoWr_hV6bdXkgP_ZHrU2o_f_wbOJETSOUdvxCavMVsxh3qgMEOWh3i_ICG0_AgEjwScnC3I1wHh3BXxIhIiMvb4p_ZtumC-MCT_IB41LaiCGDkaJCvuGl4Et1CNpORkLPIpqVegBJ6F8GrE8X5lZHFGjNBMx1kg6FIpQyv0p-3TknLysZES1tThCY_BsK3ZJ7cowEDIJhwygm1jOQS82wTAPow4RmYWr7YN_XxsnqycDmb2pbPhnpFhdpDu3-_EEiOWK8OqSJpXQUkM2gTz_6oDgb5l3Hd0fAYZQolYsAkTHB_xTL4HegM81oM6vfoKoCYRm0OUFrzGfgMtBFPC9mn9DcLZ59dRvDo5wbKUmvVKNa_INoB8kMEqMgRZY_CcOfJwL8OqssZbHquTE0rpd9LAxNsHadCcuKpHrKNhueShf4kLw3VSFGBfja9O9xnIpM7Brf5_k90tePg5H6aJzr8cpOB6Q8TccHH04fP1fwfKTpBRf6coW2vm6PnnZD8EcXLVP3tZVDwfw8tprhg76lpopS3QbBdI592GfwZ4umE_RCft9Jt35Wqk3iacnq5Ley1OIaEJ4Rsdn4XgIEYtv2oeQVUQg20zWg_bUDiHe=w275-h139-no?authuser=1",
      text: "Jewish Family Services (JFS) is a compassionate force providing vital support to both Jewish and non-Jewish communities. Guided by the principles of Tikkun Olam, JFS's mission is to enhance the well-being of those most in need.  \n\nIn April 2021, JFS opened \"The Kitchen,\" a transformative food hub addressing food security, housing, financial aid, advocacy, and counseling. The Kitchen's programs offer essential resources, including dry goods, fresh produce, and holiday assistance. \n\nJoining the Farm-to-Plate Marketplace program in April 2021, JFS's role has expanded to provide crucial resources, offering space, packaging, distribution, accounting, and strategic planning support at The Kitchen. JFS exemplifies community commitment, actively contributing to the program's success.",
      url: "https://www.jfsvancouver.ca/farm-to-plate/",
    },
    {
      name: "Columbia College",
      src: "https://lh3.googleusercontent.com/pw/AP1GczNlIZDDcaD_aPwyBMTn2r7Isl357fQOScZzoZA9wRgJA8AJSAHkf8XsU7tkfZDtCyWDX7rKSgcHfXuYn4RPu33SfLvNitjHYUT0-kcpwsviHbqiADzWm2qYDA-BPx1Pv-3jP-Bof4uo0krC-5NZxiCH=w200-h80-s-no-gm?authuser=1",
      text: "Columbia College, located in Vancouver, Canada, is a private international college offering high school completion, university transfer, associate degree, and ESL programs. Known for its diverse student body and supportive learning environment, Columbia College prepares students for academic success and future career opportunities.\n\nFarm-to-Plate Marketplace is supported by their Community Connections Program which provides paid externships for students to provide administrative services and project support to community organizations or non-profits located in Vancouver’s Inner City or the Metro Vancouver Region in areas of interest to Columbia College students. Externships are short-term work experience opportunities where students get exposure to a role or a field that is of interest to them, which can be completed while they continue their studies.",
      url: "https://www.columbiacollege.ca/community-connection-program/",
    },
    {
      name: "Trinity Grace United Church",
      src: "https://lh3.googleusercontent.com/pw/ADCreHfhtGm-E2ydIs3Ne6FpeB5zxVJLHXc3CKWckFIjPOXXipbZOMSN-gykgCBmApAb91YIzWY_gw7bdlYzgcB9TquQOvYwt7Tq0UlLgyC7LXp1yk2pjhH_y1zirgAShvk90tHiYWy503zuJuE0JDh1R1hS=w492-h96-s-no-gm?authuser=1",
      text: "Trinity Grace United Church, a proud member of the United Church of Canada, originated in 2017 through the merger of three nearby congregations—Trinity United, St. James United, and Grace Memorial United. This union forms a mosaic of like-minded, faithful, and visionary individuals, fostering unity and shared values. \n\nIn November 2023, Trinity Grace United Church enthusiastically joined the Farm-to-Plate Marketplace, starting as a community hub and quickly showcasing support by hosting a screening of \"The Interceptors,\" a short film highlighting Vancouver's food rescue efforts. They actively contribute to the program's growth and express eagerness to provide further support. Trinity Grace United Church exemplifies unity, progress, and a commitment to fostering community connections and sustainable initiatives. In May 2024, Trinity Grace became the host of Columbia College's Community Connections program and administers the grant and payment support for the student that we receive to help cover our community coordinator position.",
      url: "https://www.tgucvan.com/",
    },
    {
      name: "Society Promoting Enviromental Conservation",
      src: "https://lh3.googleusercontent.com/I7D4Tv8L2u6VpTL1iYqxDnl_J7whVWuYTIjGrtIpFPdVkBYGucgbRWMRtWnyuIJT1-eq6ahrQXYDXdJDgexYOhPvm_My54xqgw9XfLPoTB80c5ze1vnOXssHOovd8qfyyR0Qdu0NlofFnTa0EraBRQxSkiKn22raPEy1Y3Mu_MCDL8DeQbJrFHVwrOSXi9InCvVPkrWBkpnp4_486t1kEs1kt0eDk3zNDmNGgPSTgh8U7oI_cO-rAt4pGD6RN0M5y2_zfkNQ6f38inu7D_vwFKIoKnP8LK9LhVrUsVQkcv-Lfry3PoKUGwsrdkFbGPRwqhJpYqEbUMXVXUsPJWhbmx6N65K5VHBLEaHGn6KXUTG3Qq2O7pgclRikReYjp-LRk3rmsPXGcpmZFcIFb_xqFPNDsOe7N379ZJdtTVZhSiEtCqE4CJBMRYUFNxgdI7wGn7ZcxPblfRXQ-Z_KcMWwI6Ewd01Kp_acG1kA5xx0N1Z0vOqNItEtPAVl8LyGt_vQj1DVsQHppqG9aVJoxOrZFzxW3Ds98B_iCE8tUSb_fychsKXjirlo2rqjIge0_dTpwIeKhB4bJibGFdbghpYdFqS4tPcfUtzXkvidI4ZZDkGFGT9f19jvzYwbaUF2utk2fmJ4uc07mPGxLNPV7rhMvbIgXVujOVcza03-ap3KtK5-LnEyrGGgrPtTzhuxWkKrHrIghdmH82035rg8eGYf3cp5p2bRPcVNa6JrgGCI9QPm6lJxkxz3StRyzlw7mNP7B1dzOsX0VbPqrh1o_68sVwo0fPzhRXFQ=s789-no?authuser=1",
      text: "Society Promoting Environmental Conservation (SPEC) stands as a pioneer in environmental advocacy, holding the distinction of being the oldest environmental non-profit charitable organization in Canada. With a dedicated focus on urban sustainability in food, waste, energy, transportation, and climate justice, SPEC provides practical solutions that resonate with the community.\n\nAt the heart of SPEC's impact are its community-led volunteer committees, where passionate individuals converge to discuss, share ideas, and collaboratively problem-solve. The Farm-to-Plate Marketplace, a transformative initiative, was conceived at a SPEC Food Team meeting in November 2019 by Anthony Csikos. Since then, SPEC has embraced and actively contributed to the program's growth. \n\nIn the Winter of 2021, Anthony Csikos, community member Yee Chan, and SPEC's executive director applied for and successfully secured the Healthy Community Initiative Grant. This pivotal grant played a crucial role in expanding the Farm-to-Plate Marketplace, allowing SPEC to deepen its involvement in the program. \n\nToday, SPEC's role revolves around advising and providing support through knowledge-sharing initiatives. As a guiding force, SPEC continues to inspire positive change, creating a lasting impact on urban sustainability and fostering a sense of community that resonates with its core values.",
      url: "https://spec.bc.ca/food/",
    },
    {
      name: "Vancouver Food Runners",
      src: "https://lh3.googleusercontent.com/pw/ABLVV85lgFuUlInMejTpOBx-bnvZENJlJ4D1zVED51UAKcbMka6K9bSi_HV2lcueKloDZVcAPjeLwajxBOal9smaqfqIRF2XhGfwNIjqhy5Twkn4U3JMb0Qa0g2XpF56Su2SE0kk5isx9eiXtOqNtsThca0p=w709-h709-s-no-gm?authuser=1",
      text: "At Vancouver Food Runners, their mission is driven by the concerning fact that 58% of all food produced in Canada is wasted, while one in seven households in British Columbia faces food insecurity. They are dedicated to bridging this gap by redirecting perfectly good food from the waste stream to nonprofits in Vancouver that serve the most vulnerable members of the community. This belief is rooted in the principle that good food belongs to people, not landfills. \n\nThe collaboration with JFS Vancouver is a crucial aspect of our program. They facilitate the efficient delivery of food from JFS Vancouver to other non-profit partners through their network of volunteer drivers for free. This strategic partnership ensures that our food reaches those in need, bringing it closer food access to everyone. Through our combined efforts, we aim to make a meaningful impact, addressing both food waste and food insecurity in the community.",
      url: "https://www.vancouverfoodrunners.com/",
    },
    {
      name: "Healthy Community Initiative",
      src: "https://lh3.googleusercontent.com/pw/AP1GczMqoX4GnzomZJl2s_OyOa58W-3YERdSvMtIHWf5rx_tF3KmSC6t8xCFn03o_E9DEwLO7b2EQbYac9FvDVuPpGe0iQPUJQfDboqJqNSaVdIcpyUjd0q05fO8O3JGUI-P183u-ESwoeJ2zYP9J-cm3HB_=w826-h298-s-no-gm?authuser=1",
      text: "The Farm-to-Plate Marketplace has been supported by the Healthy Communities Initiative, which funds projects that create and adapt public spaces to respond to the challenges of COVID-19. This initiative supports a broad range of organizations, including local governments, charities, Indigenous communities, and nonprofits, to help communities stay connected, healthy, and safe.\n\nPublic spaces are vital for fostering social cohesion and a sense of community. However, the COVID-19 pandemic has limited access to these spaces, highlighting existing inequalities and affecting vulnerable populations the most. Communities have responded with innovative solutions to maintain connectivity and safety, from digital platforms to redesigned public spaces.\n\nThe Farm-to-Plate Marketplace received $43,000 in 2021 and an additional $21,000 recently to support its programs. This funding helps us continue our mission to provide healthy food options and support community connections in a safe and inclusive manner.",
      url: "https://communityfoundations.ca/initiatives/canada-healthy-communities-initiative/",
    },
    {
      name: "Shift Delivery",
      src: "https://lh3.googleusercontent.com/pw/ADCreHfG14LfGu87Ll0NVTpo9CSyWZ0xOYf44CCltVj1VwymNNL1O3rlpg4TZCgWzidUQCIsloXqMVRjB0cqfdfiS_VkTfbQsrLPqgz5kSqlOBh_ZYCBVCf44b44NT2p_UjlxnoOTI6cguWC-cW5e3f0cgBv=w1000-h344-s-no-gm?authuser=1",
      text: "Shift Delivery, a worker-owned cooperative based in Vancouver, specializes in last-mile delivery services. Their commitment to community support is evident through their collaboration with the Farm-to-Plate Marketplace. Shift Delivery plays a vital role in our operations by facilitating delivery to private community hubs and ensuring the efficient pickup of bins from all other locations. Their dedication is instrumental in expanding our services to more convenient locations, making nutritious food accessible to a wider audience. We are grateful for the essential contribution of Shift Delivery in enhancing the reach and convenience of the Farm-to-Plate Marketplace.",
      url: "https://www.shift.coop/",
    },
    {
      name: "Kitsilano Neighbourhood House",
      src: "https://lh3.googleusercontent.com/pw/ADCreHfBP28mTkPYj73VBCTFbMVhUw_sCr11EMf3qnEx95H_2D2q-KLL_bhSqfD00-cNPG0zWk8QyLvC-ricHWwl4NaLfbBXmAQp2cm5AJOCQikKdvqeKrw8hXtnrmp4_lP6JXRuRWWIJYa2Uenrxjxcfhk0=w1200-h750-s-no-gm?authuser=1",
      text: "Kitsilano Neighbourhood House (Kits House) is an integral partner in our mission to build and foster a sense of community on Vancouver's Westside. As a registered charity and proud member of the Association of Neighbourhood Houses of BC, Kits House is committed to bringing people together, meeting community needs, and creating strong, collaborative, and inclusive neighborhoods.\n\nIn June 2022, Kits House expanded its community impact by joining the Farm to Plate Marketplace initiative. Since then, it has transformed into a public community hub, offering a convenient Thursday evening pickup location for food orders. This collaboration has not only addressed essential food needs but has also turned Kits House into a central gathering space. The community hall generously provided by Kits House serves as a welcoming venue for our community to come together, fostering connections and shared experiences.\n\nWith a rich history dating back to 1894 and a present-day commitment to sustainability, health, and connectivity, Kitsilano Neighbourhood House remains an invaluable partner in our shared goal of building a thriving and inclusive community on the Westside of Vancouver.",
      url: "https://www.kitshouse.org/",
    },
    {
      name: "St. Mary's Kerrisdale",
      src: "https://lh3.googleusercontent.com/pw/AP1GczM3n2uLoowETk7WoDrQb37e1echrN5FQjdDdgwQ-ehuo7Hy7YJfyCaHSBi9m0CQIJ98Ih_u8HvPST0nMge7rHyooDwHaQHwQ5xV78e1U41IqTOR70pWvAS6kZk8HJ8ZJ2SW3Bmu9OkWkpO-xpWi6nY8=w692-h1044-s-no-gm?authuser=1",
      text: "St. Mary’s is a progressive Anglican Church in Kerrisdale, Vancouver, with a 112-year history as a community pillar. Generations of families from across Greater Vancouver call St. Mary’s home. We are united through worship, service, reconciliation, and radical hospitality, striving to form a beloved community where everyone is cared for, free from poverty, hunger, and hate.\n\nThrough transformative worship, learning opportunities, service for all ages, and intergenerational fellowship, we grow in faith and encourage one another to embody Christ in the world.\n\nSt. Mary's hosts a community pantry and fridge, open to everyone to give what they can and take what they need. They also offer sit-down lunches on Tuesdays for most of the year and take-out lunches in the summer. Farm-to-Plate Marketplace began working with St. Mary's in February 2024, establishing a community hub. We also send them our surplus rescued food to stock their fridges and support their community food programs.",
      url: "https://www.stmaryskerrisdale.ca/",
    },
  ];

  // This function takes in a url and then opens it in another tab.  Used to open
  // The farmer's websites
  function handleUrlOpen(value) {
    const win = window.open(value, "_blank");
    win.focus();
  }

  // When the page loads make sure it loads at the top of the page or to the place
  // the user should be directed to.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const { hash } = window.location;
    if (hash === "#HowDoesItWork") {
      const aboutUs = document.getElementById("howDoesItWork");
      aboutUs.scrollIntoView();
    } else if (hash === "#SupportingOrganisations") {
      const aboutUs = document.getElementById("supportingOrganisations");
      aboutUs.scrollIntoView({ top: "40px" });
    }
  }, []);

  return (
    <div>
      <Menu />
      <div className="About-Us-Page" id="home">
        <div className="Concept-Write-Up-And-Image">
          <div className="Our-Concept-Text">
            <h1>The Concept</h1>
            <p>
              Farm-to-Plate Marketplace is a tool for communities to use to
              empower themselves to collectively support local food producers.
              Through the platform’s transparency, communities can better
              understand and address the challenges of our food system and
              tackle food insecurity. F2PM encourages eating locally and
              seasonally, promotes community development, and facilitates
              communal problem solving. Food is a powerful connector that can
              bring people together to better understand and support one
              another.
            </p>
          </div>
          <img
            style={{ width: "400px", height: "100%", objectFit: "cover" }}
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AM-JKLUaajGi0bvqpb1QngeDQKhFDe-7-hpNdc8Ibg_OMtLnD_UNlb6GZcDLYX5Bz-uLtAEJQTQ7vg-yiEW_nOqqSj41dCxiemUW43DVvSQjfKLhCe4IowPYTfbG9UEX1LA4aq6xMnAehTBatqV4_HyVJauc=s789-no?authuser=1"
          />
        </div>

        <div>
          <h1 style={{ textAlign: "center" }}>The Process</h1>
          <div className="HDIW-Steps" id="howDoesItWork">
            <hr
              style={{ marginBottom: "50px" }}
              className="How-It-Works-Line-Divider"
            />
            {stepsDetails.map((step, index) => (
              <HowItWorksSteps
                stepNumber={index + 1}
                title={step.title}
                key={index}
                src={step.src}
                totalSteps={stepsDetails.length}
                text={step.text}
              />
            ))}
            <a
              style={{ textAlign: "right", marginTop: "5px" }}
              href="https://www.flaticon.com/free-icons/"
              title="icons"
            >
              Icons created by Freepik - Flaticon.{" "}
            </a>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/HowItWorks">
                <Button
                  style={{ background: "#45AA45", color: "white" }}
                  size="large"
                  variant="contained"
                >
                  LEARN MORE ABOUT HOW WE WORK!
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div style={{ width: "800px", marginBottom: "50px" }}>
          <h1 className="Supporting-Organisations" id="supportingOrganisations">
            Supporting Organisations
          </h1>
          <hr className="How-It-Works-Line-Divider" />
          {supportingOrganisations.map((organisation, index) => (
            <span
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              {index % 2 > 0 ? (
                <>
                  <span>
                    <h3>{organisation.name}</h3>
                    <p className="Organisation-Text">{organisation.text}</p>
                  </span>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      style={{
                        width: "300px",
                        height: "100%",
                        marginLeft: "20px",
                        marginBottom: "10px",
                      }}
                      referrerPolicy="no-referrer"
                      src={organisation.src}
                    />
                    <ColourButton
                      hoverColour="#1c8c1c"
                      colour="#a6ce39"
                      title={"Website"}
                      onClick={() => handleUrlOpen(organisation.url)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      style={{
                        width: "300px",
                        height: "100%",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                      referrerPolicy="no-referrer"
                      src={organisation.src}
                    />
                    <ColourButton
                      hoverColour="#1c8c1c"
                      colour="#a6ce39"
                      title={"Website"}
                      onClick={() => handleUrlOpen(organisation.url)}
                    />
                  </div>
                  <span>
                    <h3>{organisation.name}</h3>
                    <p className="Organisation-Text">{organisation.text}</p>
                  </span>
                </>
              )}
            </span>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
