// This dialog appears to all a distribution location user to modify their orders.
import React, { useState, useContext, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CommunityOrdersAffected from "../../../functions/CommunityOrdersAffected.js";
import UpdateFoodItemsDistributionLocation from "../Functions/UpdateFoodItemsDistributionLocation.js";
import { ChangeOrderLogContext } from "../../MyAccount.js";
import AddFoodItem from "../../../components/ModifyOrders/AddFoodItem.js";
import AddPantryFoodItem from "../../../components/ModifyOrders/AddPantryFoodItem.js";
import RemoveFoodItem from "../../../components/ModifyOrders/RemoveFoodItem.js";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// This is the dialog box that opens when a user clicks the edit button
function EditDistributionLocationItemBox(props) {
  const { onClose, open } = props;
  // Since suggestedPrice was added later on we have to make sure
  // that is defined otherwise it will trigger a bug.
  let suggestedPriceProps = props.suggestedPrice;
  if (props.suggestedPrice === undefined) {
    suggestedPriceProps = false;
  }

  // These are all the options that can be changed for the user.  They can are initialized
  // to the value of the props sent in but are separate as they are what can be modified.
  const [item, setItem] = useState(props.item);
  const [distributionQuantity, setDistributionQuantity] = useState(
    props.distributionQuantity,
  );
  const [description, setDescription] = useState(props.description);
  const [individualQuantity, setIndividualQuantity] = useState(
    props.individualQuantity,
  );
  const [suggestedPrice, setSuggestedPrice] = useState(suggestedPriceProps);
  const [individualDescription, setIndividualDescription] = useState(
    props.individualDescription,
  );
  const [price, setPrice] = useState(props.price);
  const [category, setCategory] = useState(props.category);
  const [displayImage, setDisplayImage] = useState(props.displayImage);
  const [quantity, setQuantity] = useState(props.quantity);
  const [farmName, setFarmName] = useState(props.farmName);
  const [limit, setLimit] = useState(props.limit);
  const [packageFee, setPackageFee] = useState(props.packageFee);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  // This useEffect is used when the props change and the form needs to be updated.  This happens
  // if an item is removed, or changed and so there will need to be an update on the form's fields.
  useEffect(() => {
    // Since displayImage is the only non mandatory field we have to make sure it
    // isn't undefined otherwise it won't save in the database properly.
    let displayImageTemp = props.displayImage;
    if (props.displayImage === undefined) {
      displayImageTemp = "";
    }
    setItem(props.item);
    setDistributionQuantity(props.distributionQuantity);
    setDescription(props.description);
    setIndividualQuantity(props.individualQuantity);
    setIndividualDescription(props.individualDescription);
    setSuggestedPrice(suggestedPriceProps);
    setPrice(props.price);
    setCategory(props.category);
    setDisplayImage(displayImageTemp);
    setQuantity(props.quantity);
    setFarmName(props.farmName);
    setLimit(props.limit);
    setSelectedDate(new Date());
  }, [props]);

  // If the user closes or cancels their changes then set everything back to what
  // the original values were sent in through the props.
  const handleClose = () => {
    onClose(false);
    setItem(props.item);
    setDistributionQuantity(props.distributionQuantity);
    setDescription(props.description);
    setIndividualQuantity(props.individualQuantity);
    setIndividualDescription(props.individualDescription);
    setSuggestedPrice(suggestedPriceProps);
    setPrice(props.price);
    setCategory(props.category);
    setDisplayImage(props.displayImage);
    setQuantity(props.quantity);
    setFarmName(props.farmName);
    setLimit(props.limit);
    setPackageFee(props.packageFee);
    setSelectedDate(new Date());
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();

    // If the limit existence changed then we know that it has been moved from
    // foodItems to the pantryFoodItems.
    if (!!props.limit !== !!limit) {
      // Create the basic foodItem that we know both pantry and new items will include.
      const foodItemToAdd = {
        distributionQuantity,
        description,
        individualQuantity,
        individualDescription,
        price,
        quantity: parseFloat(quantity),
        farmName,
        item,
        packageFee,
        suggestedPrice,
      };
      // If it was originally a pantry foodItem add it to the foodList.
      if (props.limit) {
        // add the field that was missing.
        foodItemToAdd.selectedDate = selectedDate;
        // Add to foodItems
        AddFoodItem(
          foodItemToAdd,
          props.order,
          props.userInfo,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
        );
        // Remove from pantry
        RemoveFoodItem(
          props.userInfo,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
          props.index,
          props.order.pantryFoodItems[props.index],
          [],
          [],
          false,
          null,
          1,
        );
      }
      // otherwise it was originally a foodList Item add it to the pantry.
      else {
        // add the field that was missing.
        foodItemToAdd.category = category;
        foodItemToAdd.limit = limit;
        foodItemToAdd.pantryLocation = props.userInfo.organisationName;
        foodItemToAdd.userId = firebase.auth().currentUser.uid;
        // This is hardcoded to match deprecated code
        foodItemToAdd.userType = "admin";
        // Add to pantry
        AddPantryFoodItem(
          foodItemToAdd,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
        );
        // Remove from foodItems
        RemoveFoodItem(
          props.userInfo,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
          props.index,
          props.order.foodList[props.index],
          [],
          [],
          false,
          null,
          0,
        );
      }
    } else {
      // This sends in the order and the food item that needs to be updated.  It returns
      // an array with the FirstName-LastName-userid-orderDate of all the orders that
      // have this item that is being changed.
      const [ordersToChange, importedOrdersToChange] = CommunityOrdersAffected(
        props.order,
        props.foodItemUpdate,
      );
      // This sends in the array of orders needing to be updated and updated the database.
      UpdateFoodItemsDistributionLocation(
        props,
        ordersToChange,
        importedOrdersToChange,
        item,
        distributionQuantity,
        description,
        individualQuantity,
        individualDescription,
        suggestedPrice,
        price,
        category,
        displayImage,
        quantity,
        farmName,
        updatePaymentHistory,
        packageFee,
        limit,
      );
    }

    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Fill in information about the item
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <span>
            <TextField
              margin="dense"
              id="item"
              label="Item"
              name="item"
              value={item}
              onChange={(e) => setItem(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="distributionQuantity"
              label="Distribution Quantity"
              name="distributionQuantity"
              type="number"
              value={distributionQuantity}
              onChange={(e) => setDistributionQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Distribution Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="individualQuantity"
              label="Individual Quantity"
              name="individualQuantity"
              type="number"
              value={individualQuantity}
              onChange={(e) => setIndividualQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="individualDescription"
              label="Individual Description"
              name="individualDescription"
              value={individualDescription}
              onChange={(e) => setIndividualDescription(e.target.value)}
              fullWidth
              required
            />
            <FormControlLabel
              label="Suggested Price"
              control={
                <Switch
                  checked={suggestedPrice}
                  name="suggestedPrice"
                  onChange={() => setSuggestedPrice(!suggestedPrice)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
            <TextField
              margin="dense"
              id="price"
              label="Price ($CAD)"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="category"
              label="Category"
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="displayImage"
              label="Display Image"
              name="displayImage"
              value={displayImage}
              onChange={(e) => setDisplayImage(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="quantity"
              label="Quantity"
              name="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="limit"
              label="Remaining in Pantry"
              name="limit"
              value={limit}
              type="number"
              onChange={(e) => setLimit(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="farmName"
              name="farmName"
              label="Supplying Farm"
              value={farmName}
              onChange={(e) => setFarmName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="packageFee"
              label="Package Fee"
              name="packageFee"
              value={packageFee}
              onChange={(e) => setPackageFee(e.target.value)}
              type="number"
              fullWidth
              required
            />
            {!!props.limit !== !!limit && !!props.limit && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
          </span>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditDistributionLocationItemBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EditDistributionLocationItemDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <EditDistributionLocationItemBox
        open={open}
        onClose={handleClose}
        item={props.item}
        distributionQuantity={props.distributionQuantity}
        description={props.description}
        individualQuantity={props.individualQuantity}
        individualDescription={props.individualDescription}
        suggestedPrice={props.suggestedPrice}
        price={props.price}
        category={props.category}
        displayImage={props.displayImage}
        quantity={props.quantity}
        farmName={props.farmName}
        order={props.order}
        foodItemUpdate={props.foodItemUpdate}
        orderLog={props.orderLog}
        updateOrderLog={props.updateOrderLog}
        orderIndex={props.orderIndex}
        userInfo={props.userInfo}
        limit={props.limit}
        packageFee={props.packageFee}
        index={props.index}
      />
    </div>
  );
}
