// This updates a distribution Location's Pantry to include the sent in item.  IT
// then updates the hooks to correctly display the items in the pantry
import firebase from "./../../Firebase.js";

export default function ModifyItemInPantry(
  leftovers,
  setLeftovers,
  userInfo,
  foodItem,
  unEditedFoodItem,
  index,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const leftoversTemp = [...leftovers];

  const foodItemTemp = { ...foodItem };

  // Make sure that suggestedPrice exists. It was added in later and so may not
  // have a value.  It will cause a bug if it is undefined because you can't save
  // that to the database.
  if (foodItemTemp.suggestedPrice === undefined) {
    foodItemTemp.suggestedPrice = false;
  }

  leftoversTemp[index] = { ...foodItemTemp };

  // Checks to see if the name, description or farmName have changed.  Since we
  // save items in the database using these properties we'll have to delete the item
  // and make a new one.
  if (
    foodItemTemp.item !== unEditedFoodItem.item ||
    foodItemTemp.description !== unEditedFoodItem.description ||
    foodItemTemp.selectedFarm !== unEditedFoodItem.farmName
  ) {
    const deleteDocumentName = [
      unEditedFoodItem.item,
      unEditedFoodItem.individualDescription,
      unEditedFoodItem.farmName,
    ].join("");

    database
      .collection("Pantry")
      .doc(userInfo.organisationName)
      .update({
        [deleteDocumentName]: firebase.firestore.FieldValue.delete(),
      });
  }

  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  batch.update(pantryDocRef, {
    [[
      foodItemTemp.item,
      foodItemTemp.individualDescription,
      foodItemTemp.farmName,
    ].join("")]: { ...foodItemTemp },
  });
  batch.commit();
  setLeftovers([...leftoversTemp]);
}
