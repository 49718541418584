// This is a form that the user uses to send in their application to become a community
// pickup location.  It will update the database and then also send emails to distribution
// location and the user applying.
import React, { useState } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DistributionLocationCard from "../../../components/Cards/DistributionLocationCard.jsx";
import ApplicationEmails from "../../MyAccountPage/Functions/ApplicationEmails.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SimpleSelect from "../../../components/userInputs/DropDownMenu.js";
import { Link } from "react-router-dom";

export default function BecomeCommunityHostDialog({
  pickupLocations,
  userInfo,
  setSendingEmail,
  handleUserInfoChange,
  currentUser,
}) {
  // Whether the dialog is open or not.
  const [open, setOpen] = useState(false);
  // The distribution location that the community hub is partnered with.
  const [partnerLocation, setPartnerLocation] = useState(
    Object.keys(pickupLocations)[0],
  );
  // What kind of community hub this is, publicApproval, publicNoApproval, private.
  const [communityHubType, setCommunityHubType] = useState("publicApproval");
  // The name of the community location.
  const [locationName, setLocationName] = useState(
    userInfo.firstName + "'s Community Pickup",
  );
  // Their address.
  const [address, setAddress] = useState("");
  // An address that is close to their home.
  const [obscuredAddress, setObscuredAddress] = useState("");
  // How many people they want max coming to their location.
  const [maxParticipants, setMaxParticipants] = useState("");
  // The available pick up times.
  const [availableTimes, setAvailableTimes] = useState("");
  // Any additional notes about the location.
  const [additionalComments, setAdditionalComments] = useState("");

  // Closes the dialog and resets the fields.
  const handleClose = () => {
    setOpen(false);
    setPartnerLocation(Object.keys(pickupLocations)[0]);
    setCommunityHubType("publicApproval");
    setLocationName(userInfo.firstName + "'s Community Pickup");
    setAddress("");
    setObscuredAddress("");
    setMaxParticipants("");
    setAvailableTimes("");
    setAdditionalComments("");
  };

  // Save sthe application and sends it off once the user is done applying.
  const handleSave = (e) => {
    e.preventDefault();
    // Sets the circular loading to true.
    setSendingEmail(true);
    const database = firebase.firestore();
    const batch = database.batch();

    // Set the distributionLocation to the user's pickup location
    const distributionLocation = pickupLocations[partnerLocation];
    if (distributionLocation !== undefined) {
      // Update the database.
      const documentName = (
        userInfo.firstName +
        userInfo.lastName +
        firebase.auth().currentUser.uid
      ).replace(/\s+/g, "");
      const docRef = database
        .collection("CommunityLocations")
        .doc(documentName);
      const communityLocation = {
        locationName,
        partnerLocation,
        communityHubType,
        address,
        obscuredAddress,
        maxParticipants,
        availableTimes,
        additionalComments,
        userId: firebase.auth().currentUser.uid,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        pickupLocation: distributionLocation,
        distributionLocationName: distributionLocation.name,
        distributionLocationuserId: distributionLocation.userId,
        email: userInfo.email,
        secretCode: userInfo.userId.slice(0, 9),
        approved: false,
      };
      batch.set(docRef, communityLocation);

      batch.commit();

      // Updates the userInfo to include the new changes.
      const userInfoTemp = { ...userInfo };
      // Add the new Community location to the userInfo.
      userInfoTemp.communityLocation = communityLocation;
      handleUserInfoChange({ ...userInfoTemp }, true);
      console.log("Successfully wrote to database!");
      setOpen(false);
      // Send an application email.
      ApplicationEmails(
        userInfo,
        communityLocation,
        setSendingEmail,
        distributionLocation,
      );
    } else {
      // eslint-disable-next-line no-undef
      alert(
        'Please select a distribution location to partner with first by clicking "CHANGE".',
      );
    }
  };

  return (
    <div style={{ width: "100%", marginRight: "10px" }}>
      <Button
        fullWidth
        style={{ background: "#A6CE39", color: "white" }}
        size="large"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        BECOME A COMMUNITY HOST
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Become a Community Pickup Location
        </DialogTitle>
        {currentUser ? (
          <DialogContent>
            <p>
              The following information will be sent to your partnered
              distribution location. They will review your application to
              determine your eligibility and then contact you. None of this
              information will be shared online until the distribution location
              contacts you and asks for your permission. Thank you for
              supporting your community!
            </p>
            <form onSubmit={handleSave}>
              <span>
                <FormLabel component="legend">
                  Select a Partner Distribution Location
                </FormLabel>
                {Object.keys(pickupLocations).length > 0 && (
                  <SimpleSelect
                    margin="dense"
                    id="selectOrderStatsLocation"
                    options={Object.keys(pickupLocations)}
                    handleChange={(e) => setPartnerLocation(e.target.value)}
                    selected={partnerLocation}
                  />
                )}
                <DistributionLocationCard
                  pickupLocation={pickupLocations[partnerLocation]}
                />

                <FormLabel component="legend">Community Hub Type</FormLabel>
                <RadioGroup
                  aria-label="communityHubType"
                  name="communityHubType"
                  value={communityHubType}
                  onChange={(e) => setCommunityHubType(e.target.value)}
                >
                  <FormControlLabel
                    value="publicApproval"
                    control={<Radio />}
                    label="Public, Approval Required"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Private Hidden"
                  />
                  <FormControlLabel
                    value="publicNoApproval"
                    control={<Radio />}
                    label="Public, No Approval Required"
                  />
                </RadioGroup>

                <TextField
                  margin="dense"
                  id="locationName"
                  label="Location name"
                  name="locationName"
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="address"
                  label="Your address"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="obscuredAddress"
                  label="Your obscured address for the public to see, ex. cross streets."
                  name="obscuredAddress"
                  value={obscuredAddress}
                  onChange={(e) => setObscuredAddress(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="maxParticipants"
                  label="Maximum participants allowed at your location per week"
                  name="maxParticipants"
                  type="number"
                  value={maxParticipants}
                  onChange={(e) => setMaxParticipants(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="availableTimes"
                  label="What are the available times for others to pickup?"
                  name="availableTimes"
                  value={availableTimes}
                  onChange={(e) => setAvailableTimes(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  id="additionalComments"
                  label="Additional comments or special instructions for pick up?"
                  placeholder="Questions, details about pickup spot, such as, sun exposure, accessiblity, etc."
                  rowsMax={5}
                  multiline
                  variant="outlined"
                  name="additionalComments"
                  value={additionalComments}
                  onChange={(e) => setAdditionalComments(e.target.value)}
                  fullWidth
                />
              </span>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Apply
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        ) : (
          <DialogContent>
            <p>
              In order to become a community host please sign in or sign up
              first please.
            </p>
            <Link style={{ textDecoration: "none" }} to="/SignIn">
              <Button
                variant="contained"
                style={{
                  background: "rgb(225 119 245)",
                  color: "white",
                  margin: "8px 5px",
                }}
                fullWidth
              >
                Sign In
              </Button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/SignUp">
              <Button
                variant="contained"
                style={{
                  background: "rgb(159, 191, 89)",
                  color: "white",
                  margin: "8px 5px",
                }}
                fullWidth
              >
                Sign Up
              </Button>
            </Link>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
