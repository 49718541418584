// This dialog allows the users to update their community hub information.  Some of
// the items cannot be updated by the user directly.
import React, { useState, useContext } from "react";
import firebase from "../../../components/Firebase.js";
import { AuthContext } from "../../../components/authentication/Auth.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CommunityLocationFoodVisibilityDialog from "./CommunityLocationLimitations/CommunityLocationFoodVisibilityDialog.jsx";

export default function CommunityLocationEditDialog({ communityLocation }) {
  const [open, setOpen] = useState(false);
  // communityHubType is for whether the user wants to be public no approval, private or public with approval
  const [communityHubType, setCommunityHubType] = useState(
    communityLocation.communityHubType,
  );
  // The community hub's name.
  const [locationName, setLocationName] = useState(
    communityLocation.locationName,
  );
  // Secret code that the user has to share with other users to add to their location easily.
  // eslint-disable-next-line no-unused-vars
  const [secretCode, setSecretCode] = useState(communityLocation.secretCode);
  // The address of the community hub.
  const [address, setAddress] = useState(communityLocation.address);
  // The obscured address of the community hub that the community member added
  const [obscuredAddress, setObscuredAddress] = useState(
    communityLocation.obscuredAddress,
  );
  // The maximum number of people that are allowed to order in a given week.
  const [maxParticipants, setMaxParticipants] = useState(
    communityLocation.maxParticipants,
  );
  // The available times allowed for pick up.
  const [availableTimes, setAvailableTimes] = useState(
    communityLocation.availableTimes,
  );
  // Any additional comments for the location.
  const [additionalComments, setAdditionalComment] = useState(
    communityLocation.additionalComments,
  );
  // eslint-disable-next-line no-unused-vars
  const { userInfo, currentUser, handleUserInfoChange } =
    useContext(AuthContext);

  // If the user hits cancel then we want to reset the user's values.
  const handleCancel = () => {
    setOpen(false);
    setCommunityHubType(communityLocation.communityHubType);
    setLocationName(communityLocation.locationName);
    setMaxParticipants(communityLocation.maxParticipants);
    setAvailableTimes(communityLocation.availableTimes);
    setAdditionalComment(communityLocation.additionalComments);
  };

  // If the user wants to save their changes then they'll update the database.
  const handleSave = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();

    // Update the database.
    const documentName = (
      communityLocation.firstName +
      communityLocation.lastName +
      communityLocation.userId
    ).replace(/\s+/g, "");
    const docRef = database.collection("CommunityLocations").doc(documentName);
    const communityLocationTemp = { ...communityLocation };

    communityLocationTemp.communityHubType = communityHubType;
    communityLocationTemp.locationName = locationName;
    communityLocationTemp.maxParticipants = maxParticipants;
    communityLocationTemp.availableTimes = availableTimes;
    communityLocationTemp.additionalComments = additionalComments;
    batch.update(docRef, communityLocationTemp);

    // Updates the userInfo to include the new changes.
    const userDocRef = database
      .collection("Users")
      .doc(communityLocation.userId);
    // Add the new Community location to the userInfo.
    batch.update(userDocRef, {
      communityLocation: communityLocationTemp,
    });

    batch.commit();

    console.log("Successfully wrote to database!");
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Edit Your Community Location
        </DialogTitle>
        <DialogContent>
          <p>
            Please consider how your changes may affect your community members'
            access to food. If you believe these changes might limit someone's
            access please contact them and us directly at
            anthony@farmtoplatemarketplace.com so that we can find an
            alternative solution for them. If you are changing after Sunday
            users might have already placed orders and may not be aware of your
            changes. Address and obscured address can only be changed by
            emailing us at anthony@farmtoplatemarketplace.com.
          </p>
          <form onSubmit={handleSave}>
            <span>
              <Typography>
                <strong>Partner Location: </strong>
                {communityLocation.distributionLocationName}
              </Typography>

              <FormLabel component="legend">Community Hub Type</FormLabel>
              <RadioGroup
                aria-label="communityHubType"
                name="communityHubType"
                value={communityHubType}
                onChange={(e) => setCommunityHubType(e.target.value)}
              >
                <FormControlLabel
                  value="publicApproval"
                  control={<Radio />}
                  label="Public, Approval Required"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private Hidden"
                />
                <FormControlLabel
                  value="publicNoApproval"
                  control={<Radio />}
                  label="Public, No Approval Required"
                />
              </RadioGroup>

              <TextField
                margin="dense"
                id="locationName"
                label="Location name"
                name="locationName"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="secretCode"
                label="Secret Code"
                name="secretCode"
                value={secretCode}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                id="address"
                label="Your address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                id="obscuredAddress"
                label="Your obscured address for the public to see, ex. cross streets."
                name="obscuredAddress"
                value={obscuredAddress}
                onChange={(e) => setObscuredAddress(e.target.value)}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                id="maxParticipants"
                label="Maximum participants allowed at your location per week"
                name="maxParticipants"
                type="number"
                value={maxParticipants}
                onChange={(e) => setMaxParticipants(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="availableTimes"
                label="What are the available times for others to pickup?"
                name="availableTimes"
                value={availableTimes}
                onChange={(e) => setAvailableTimes(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="additionalComments"
                label="Additional comments or special instructions for pick up?"
                placeholder="Questions, details about pickup spot, such as, sun exposure, accessiblity, etc."
                maxRows={5}
                multiline
                variant="outlined"
                name="additionalComments"
                value={additionalComments}
                onChange={(e) => setAdditionalComment(e.target.value)}
                fullWidth
              />
              <CommunityLocationFoodVisibilityDialog
                communityLocation={communityLocation}
              />
            </span>
            <DialogActions>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
