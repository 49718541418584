import React, { useEffect, useState } from "react";
import firebase from "../Firebase.js";
import CheckBasketItemsAvailable from "../../functions/CheckBasketItemsAvailable.js";
import CheckCommunityHostApproval from "../../functions/CheckCommunityHostApproval.js";
// eslint-disable-next-line no-unused-vars
import LoadingContent from "../../components/LoadingContent.jsx";
import UpdateCommunityHostData from "../../functions/UpdateCommunityHostData.js";
import PropTypes from "prop-types";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const [userInfo, setUserInfo] = useState([]);

  // Sets whether or no the necessary documents have been loaded to load the page.
  const [loading, setLoading] = useState(false);

  // Loading the community host data if there is any that updates the user's
  // community host field.
  const [loadingCommunityHostData, setLoadingCommunityHostData] =
    useState(false);

  const database = firebase.firestore();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
      const usersDocRef = database.collection("Users").doc(currentUser.uid);
      // NEEDS WORK ths Vancouver needs to not be hardcoded
      const pickupLocationDocRef = database
        .collection("DistributionLocations")
        .doc("Vancouver");

      // Pulls the user information from the database.
      usersDocRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let userInfoTemp = doc.data();

            // If the user doesn't have a basketItems field in their userInfo yet then we
            // need to create it and set it to 0.  The user has to be signed in.
            if (userInfoTemp.basketItems === undefined) {
              userInfoTemp.basketItems = [];
            }

            // Checks to make sure that if this is a new distribution location or a
            // restaurant that they have a farms section.
            if (
              userInfoTemp.farms === undefined &&
              userInfoTemp.userType !== "individual"
            ) {
              userInfoTemp.farms = [
                "No farms available please contact developer",
              ];
            }

            // Makes sure that the user has the most up to date version of their
            // community hub in case there have been any changes to it.
            if (userInfoTemp.communityLocation !== undefined) {
              UpdateCommunityHostData(
                userInfoTemp,
                setUserInfo,
                setLoadingCommunityHostData,
              );
            }

            // Checks if the user has a pickupLocation field if it doesn't then there
            // is no reason to load this value.
            if (
              userInfoTemp.pickupLocation !== undefined &&
              userInfoTemp.userType === "individual"
            ) {
              // Check if the user has selected a commmunity location.
              if (userInfoTemp.pickupLocation.pickupLocation !== undefined) {
                // Set the document to the community location's name
                const documentName = [
                  userInfoTemp.pickupLocation.firstName,
                  userInfoTemp.pickupLocation.lastName,
                  userInfoTemp.pickupLocation.userId,
                ].join("");
                // Reference the CommunityLocations collection.
                const communityPickupLocationDocRef = database
                  .collection("CommunityLocations")
                  .doc(documentName);

                // Read the document
                communityPickupLocationDocRef
                  .get()
                  .then((doc2) => {
                    if (doc2.exists) {
                      // Update the pickupLocation so that it is accurate.
                      userInfoTemp.pickupLocation = doc2.data();

                      // Check to make sure that the items in this users basket are still
                      // valid.
                      userInfoTemp = CheckBasketItemsAvailable({
                        ...userInfoTemp,
                      });

                      // Check that the community host the user selected still gives
                      // them permission to use the space.
                      CheckCommunityHostApproval(
                        userInfoTemp,
                        setUserInfo,
                        setLoading,
                      );
                    }
                  })
                  .catch(function (error) {
                    console.log("Error getting document:", error);
                  });
              }
              // If the user selected a regular distribution space.
              else {
                // Read the document
                pickupLocationDocRef
                  .get()
                  .then((doc2) => {
                    // Double checks that the user has a pickupLocation
                    if (userInfoTemp.pickupLocation) {
                      // Checks to see if the location is still active.
                      if (
                        doc2.data()[userInfoTemp.pickupLocation.name].status ===
                        "inactive"
                      ) {
                        // If it's not active then we will delete the pickupLocation
                        delete userInfoTemp.pickupLocation;
                        // Clears their basket as they don't have a selected pickuplocation.
                        userInfoTemp.basketItems = [];
                      } else {
                        // Sets their pickup to the up to date database pickupLocation
                        userInfoTemp.pickupLocation =
                          doc2.data()[userInfoTemp.pickupLocation.name];
                        // Check to make sure that the items in this users basket are still
                        // valid.
                        userInfoTemp = CheckBasketItemsAvailable({
                          ...userInfoTemp,
                        });
                      }
                    }
                    setUserInfo(userInfoTemp);
                    setLoading(true);
                  })
                  .catch(function (error) {
                    console.log("Error getting document:", error);
                  });
              }
            } else {
              if (userInfo.userType === "individual") {
                // If the user hasn't even selected a pickup location yet then they
                // shouldn't have any items in their basket.  Clear them.
                userInfoTemp.basketItems = [];
              }
              setUserInfo(userInfoTemp);
              setLoading(true);
            }
          } else {
            // doc.data() will be undefined in this case
            setLoading(true);
            setLoadingCommunityHostData(true);
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      // If the user isn't logged in than initialize the userInfo to an empty dictionary
      setUserInfo({});
      setLoading(true);
      setLoadingCommunityHostData(true);
    }
  }, [currentUser, database]);

  function handleUserInfoChange(
    update,
    infoChange,
    deepUpdate,
    updatingCredit,
  ) {
    if (infoChange) {
      update.userId = currentUser.uid;
      setUserInfo({ ...update });

      const batch = database.batch();
      const usersDocRef = database
        .collection("Users")
        .doc(firebase.auth().currentUser.uid);

      batch.set(usersDocRef, { ...update }, { merge: true });

      batch.commit();
    }
    // Checks to see if the user needs to fully overwrite their userInfo
    else if (deepUpdate) {
      // makes sure the userId is correct
      update.userId = currentUser.uid;
      // Updated the userInfo
      setUserInfo({ ...update });
      // Sets the Users update
      const batch = database.batch();
      const usersDocRef = database
        .collection("Users")
        .doc(firebase.auth().currentUser.uid);
      // commits and updates the database.
      batch.update(usersDocRef, { ...update });

      batch.commit();
    }
    // If the user's credits are being changed.
    else if (updatingCredit) {
      // Check to see if their credits are less than 0.  If they are then we need
      // to set their credits to 0
      if (parseFloat(update.credits) < 0) {
        // Sets the Users update
        const batch = database.batch();
        const usersDocRef = database.collection("Users").doc(update.userId);
        // Reset the credits to 0
        update.credits = 0;
        // commits and updates the database.
        batch.update(usersDocRef, { ...update });
        batch.commit();
        // Update the user hook.
        setUserInfo({ ...update });
      } else {
        // Update the user hook.
        setUserInfo({ ...update });
      }
    } else {
      setUserInfo({ ...userInfo, votedIssues: update });
    }
  }

  // Creates the user by adding them to the database and updates the userInfo.
  function handleUserInfoCreate(newUserInfo, userId) {
    // Sets the userInfo to include the information added by the user on the signup
    // Page.
    setUserInfo({ ...newUserInfo });

    // Commits the changes to the database and creates the user.
    const batch = database.batch();
    const usersDocRef = database.collection("Users").doc(userId);

    batch.set(usersDocRef, { ...newUserInfo });
    batch.commit();
  }
  if (loading && loadingCommunityHostData) {
    return (
      <AuthContext.Provider
        value={{
          currentUser,
          userInfo,
          handleUserInfoChange,
          handleUserInfoCreate,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  } else {
    return <LoadingContent />;
  }
};

AuthProvider.propTypes = {
  children: PropTypes.object,
};
