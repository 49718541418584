// Called by the FarmLimitsCompletePlacingOrder function for when a distribution location places an order.
// Updates the database to include the order.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityLocationFarms from "../../../functions/UpdateCommunityLocationFarms.js";
import CreatePaymentHistory from "./CreatePaymentHistory.js";
import FinalisePlaceOrder from "./FinalisePlaceOrder.js";
import AddFarmsToExisting from "../AddFarmsToExisting.js";
import AddFoodToExisting from "../AddFoodToExisting.js";
import AddPantryFoodToExisting from "../AddPantryFoodToExisting.js";
import CreateUpdatedLeftoverList from "./CreateUpdatedLeftoverList.js";
import UpdateMarketplaceMessages from "./UpdateMarketplaceMessages.js";

export default function DistributionLocationOrder(
  userInfo,
  checkList,
  selectedDate,
  importedOrder,
  foodItems,
  communityOrders,
  ordersDocRef,
  now,
  farmerInfo,
  contactMethods,
  batch,
  addToExistingOrder,
  basketOrder,
  handleUserInfoChange,
  modifiedUserPickups,
  noRepeatsFarmerInfo,
  farmDeliveryDays,
  farmTotals,
  donationSubsidy,
  customerContribution,
  setPlacingOrder,
  setPlacingBatchesOrder,
  setSnackBarOpen,
  setFoodItems,
  setCommunityFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  setAddingToPantry,
  setImportedAddingToPantry,
  foodItemsUpdated,
  marketplaceMessage,
  marketplaceMessageEnabled,
  extraQuantitiesDict,
) {
  const database = firebase.firestore();

  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  // Sets the location to update in the firebase using dot notation.  This will be used to set
  // the farmPickupDates and the farmsCatalogueAndPantry so it works with the pantry.
  const locationToUpdate = [userInfo.organisationName];

  // The dictionary that saves the key (farmName) and the value (the date of pickup
  // day if it exists to display leftovers)
  const farmsPickupDates = {};

  // Same as farmsPickupDates but with the pickup date of the importedOrder
  const farmsImportedPickupDates = {};

  // The dictionary that says which questionnaires are currently active and their creation date.
  // {questionnaireCreationDate : DateQuestionnaireWasAdded}
  let questionnaireList = userInfo.questionnaireList;

  // If questionnaireList doesn't exist then set it to an empty dictionary.
  if (questionnaireList === undefined) {
    questionnaireList = {};
  }

  // Updates the information in the DistributionLocations -> Vancouver Document.
  // Cycle through the checklist and update the farms that will show the leftovers
  // with the next pickup date that the user selected.
  checkList.forEach((farm) => {
    farmsPickupDates[farm] = selectedDate;
    batch.update(distributionLocationsDocRef, {
      [[locationToUpdate, "farmsPickupDates", farm].join(".")]: selectedDate,
      [[locationToUpdate, "farmsCatalogueAndPantry", farm].join(".")]: false,
    });
    // The is called to update the farms in community hosts so that they have accurate pickup location data.
    UpdateCommunityLocationFarms(userInfo.organisationName, farm, selectedDate);
  });

  // Update the marketplace messages of the distribution locations and their community hubs and
  // any imported locations as well.
  UpdateMarketplaceMessages(
    userInfo,
    marketplaceMessage,
    marketplaceMessageEnabled,
    handleUserInfoChange,
    importedOrder,
  );

  // Update the farmsPickupDates as to allow for the pantry to be updated properly
  // and include the pantry items of the importing location.
  if (importedOrder.length !== 0) {
    // Cycle through all the distribution locations that were imported to this order.
    Object.keys(importedOrder).forEach((location) => {
      const importedLocationToUpdate = [importedOrder[location].location];
      // Cycle through the checklisted farms that we want to add to the pantry.
      checkList.forEach((farm) => {
        farmsImportedPickupDates[farm] = importedOrder[location].date;
        batch.update(distributionLocationsDocRef, {
          [[importedLocationToUpdate, "farmsPickupDates", farm].join(".")]:
            importedOrder[location].date,
          [[importedLocationToUpdate, "farmsCatalogueAndPantry", farm].join(
            ".",
          )]: false,
        });
        // The is called to update the farms in community hosts so that they have accurate pickup location data.
        UpdateCommunityLocationFarms(
          location,
          farm,
          importedOrder[location].date,
        );
      });
    });
  }

  // If we're not adding to an existing order then we just need to set the database.
  if (!addToExistingOrder) {
    batch.set(ordersDocRef, {
      orderDate: now,
      foodList: foodItems,
      pantryFoodItems,
      farmerList: farmerInfo,
      contactMethods,
      communityOrders,
      importedOrder,
      selectedDate,
      owner: firebase.auth().currentUser.uid,
      questionnaireList,
    });

    // If there is an imported order than update the location that is having its
    // order imported so that it can direct people to the correct place on the order
    // details page.
    if (importedOrder.length !== 0) {
      // Cycle through all the imported distribution locations
      Object.keys(importedOrder).forEach((location) => {
        const importedOrderDetailsIndex = importedOrder[
          location
        ].locationDetails.findIndex((locationDetailsTemp) => {
          return locationDetailsTemp.name === importedOrder[location].location;
        });
        const importedDistributionLocationOrdersDocRef = database
          .collection("Users")
          .doc(
            importedOrder[location].locationDetails[importedOrderDetailsIndex]
              .userId,
          )
          .collection("Orders")
          .doc(now);

        batch.set(importedDistributionLocationOrdersDocRef, {
          distributionDate: importedOrder[location].date,
          importedBy: userInfo.organisationName,
          selectedDate,
          userId: firebase.auth().currentUser.uid,
        });
      });
    }

    // Testing Remove
    // Send emails to any users that had their orders modified, send orders to farmers
    // and update the database.
    FinalisePlaceOrder(
      foodItems,
      importedOrder,
      communityOrders,
      userInfo,
      checkList,
      handleUserInfoChange,
      batch,
      modifiedUserPickups,
      noRepeatsFarmerInfo,
      farmDeliveryDays,
      contactMethods,
      foodItems,
      farmTotals,
      donationSubsidy,
      customerContribution,
      setPlacingOrder,
      setPlacingBatchesOrder,
      setSnackBarOpen,
      setFoodItems,
      setCommunityFoodItems,
      false,
      setAddingToPantry,
      setImportedAddingToPantry,
      setPantryFoodItems,
      foodItemsUpdated,
      [],
      extraQuantitiesDict,
    );

    // Update the Payment History to store information to help track payments.
    CreatePaymentHistory(
      now,
      selectedDate,
      importedOrder,
      communityOrders,
      userInfo,
    );
  }
  // If we're adding to an existing order then we need to update.
  else {
    // This is to set what the orderDate of the exisiting order is but in case there
    // isn't one than default it to now.
    let existingOrderDate = now;

    // Find the most recent order that had the same selected date as the current order.
    const docRef = database
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Orders")
      .where("selectedDate", "==", selectedDate)
      .orderBy("orderDate", "desc")
      .limit(1);
    docRef.get().then((collection) => {
      // If there are no items in the collection then there wasn't an order to add to
      // and we just need to create a new one.
      if (collection.docs.length === 0) {
        const ordersDocRef = database
          .collection("Users")
          .doc(firebase.auth().currentUser.uid)
          .collection("Orders")
          .doc(existingOrderDate);
        batch.set(ordersDocRef, {
          orderDate: now,
          foodList: foodItems,
          pantryFoodItems,
          farmerList: farmerInfo,
          contactMethods,
          communityOrders,
          importedOrder,
          selectedDate,
          owner: firebase.auth().currentUser.uid,
        });

        // If there is an imported order than update the location that is having its
        // order imported so that it can direct people to the correct place on the order
        // details page.
        if (importedOrder.length !== 0) {
          // Cycle through all the imported distribution locations
          Object.keys(importedOrder).forEach((location) => {
            const importedOrderDetailsIndex = importedOrder[
              location
            ].locationDetails.findIndex((locationDetailsTemp) => {
              return (
                locationDetailsTemp.name === importedOrder[location].location
              );
            });
            const importedDistributionLocationOrdersDocRef = database
              .collection("Users")
              .doc(
                importedOrder[location].locationDetails[
                  importedOrderDetailsIndex
                ].userId,
              )
              .collection("Orders")
              .doc(now);

            batch.set(importedDistributionLocationOrdersDocRef, {
              distributionDate: importedOrder[location].date,
              importedBy: userInfo.organisationName,
              selectedDate,
              userId: firebase.auth().currentUser.uid,
            });
          });
        }

        // Testing Remove
        // Send emails to any users that had their orders modified, send orders to farmers
        // and update the database.
        FinalisePlaceOrder(
          foodItems,
          importedOrder,
          communityOrders,
          userInfo,
          checkList,
          handleUserInfoChange,
          batch,
          modifiedUserPickups,
          noRepeatsFarmerInfo,
          farmDeliveryDays,
          contactMethods,
          foodItems,
          farmTotals,
          donationSubsidy,
          customerContribution,
          setPlacingOrder,
          setPlacingBatchesOrder,
          setSnackBarOpen,
          setFoodItems,
          setCommunityFoodItems,
          false,
          setAddingToPantry,
          setImportedAddingToPantry,
          setPantryFoodItems,
          foodItemsUpdated,
          [],
          extraQuantitiesDict,
        );

        // Update the Payment History to store information to help track payments.
        CreatePaymentHistory(
          now,
          selectedDate,
          importedOrder,
          communityOrders,
          userInfo,
        );
      }

      // If the collection does have a document then we know it is the one and
      // only document that we need to update.
      collection.docs.forEach((doc) => {
        // Find the exisitingOrder date which is the name of the Order document.
        existingOrderDate = doc.data().orderDate;
        // The existing Farmer List from the previous order.
        const existingfarmerList = doc.data().farmerList;
        // The existing FoodList from the previous order.
        const existingFoodList = doc.data().foodList;
        // The existing Pantry FoodList from the previous order.
        const existingPantryFoodItems = doc.data().pantryFoodItems;
        // The existing communityOrders from the previous order.
        const existingCommunityOrders = doc.data().communityOrders;
        // The existing imported communityOrders from the previous order.
        const existingImportedOrder = doc.data().importedOrder;

        // The reference of the document
        const ordersDocRef = database
          .collection("Users")
          .doc(firebase.auth().currentUser.uid)
          .collection("Orders")
          .doc(existingOrderDate);

        // The combined farm list of the new order and the existing order.
        const combinedFarmList = AddFarmsToExisting(
          existingfarmerList,
          farmerInfo,
        );
        // The combined food list of the new order and the existing order.
        const combinedFoodList = AddFoodToExisting(existingFoodList, foodItems);
        // The combined pantry food list of the new order and the existing order.
        let combinedPantryFoodList = AddPantryFoodToExisting(
          existingPantryFoodItems,
          pantryFoodItems,
        );

        // Create the leftovers food list of items that need to be added when taking into account the
        // existing foodList and the new foodList and the community's orders.  It also returns the final
        // pantry foodList of items that were taken from the pantry with this order.
        const [leftoversToAdd, combinedPantryFoodListTemp] =
          CreateUpdatedLeftoverList(
            communityOrders,
            importedOrder,
            existingCommunityOrders,
            existingImportedOrder,
            existingFoodList,
            userInfo,
            foodItems,
            combinedFoodList,
            combinedPantryFoodList,
          );
        // Sets the variable from the temp variable.
        combinedPantryFoodList = combinedPantryFoodListTemp;

        // Update the database with the updated order.
        batch.update(ordersDocRef, {
          communityOrders,
          contactMethods,
          farmerList: combinedFarmList,
          foodList: combinedFoodList,
          pantryFoodItems: combinedPantryFoodList,
          importedOrder,
          questionnaireList,
        });

        // If there is an imported order than update the location that is having its
        // order imported so that it can direct people to the correct place on the order
        // details page.
        if (importedOrder.length !== 0) {
          // Cycle through all the imported distribution locations
          Object.keys(importedOrder).forEach((location) => {
            const importedOrderDetailsIndex = importedOrder[
              location
            ].locationDetails.findIndex((locationDetailsTemp) => {
              return (
                locationDetailsTemp.name === importedOrder[location].location
              );
            });
            const importedDistributionLocationOrdersDocRef = database
              .collection("Users")
              .doc(
                importedOrder[location].locationDetails[
                  importedOrderDetailsIndex
                ].userId,
              )
              .collection("Orders")
              .doc(existingOrderDate);

            batch.set(importedDistributionLocationOrdersDocRef, {
              distributionDate: importedOrder[location].date,
              importedBy: userInfo.organisationName,
              selectedDate,
              userId: firebase.auth().currentUser.uid,
            });
          });
        }
        // Testing Remove
        // Send emails to any users that had their orders modified, send orders to farmers
        // and update the database.   FinalisePlaceOrder uses the foodList to determine
        // which items need to be added to the pantry.  Since we've already added items from
        // the exisitng order to the pantry then we don't want to add them again therefore we will
        // send the new foodList with the existing orders.
        FinalisePlaceOrder(
          combinedFoodList,
          importedOrder,
          communityOrders,
          userInfo,
          checkList,
          handleUserInfoChange,
          batch,
          modifiedUserPickups,
          noRepeatsFarmerInfo,
          farmDeliveryDays,
          contactMethods,
          foodItems,
          farmTotals,
          donationSubsidy,
          customerContribution,
          setPlacingOrder,
          setPlacingBatchesOrder,
          setSnackBarOpen,
          setFoodItems,
          setCommunityFoodItems,
          false,
          setAddingToPantry,
          setImportedAddingToPantry,
          setPantryFoodItems,
          foodItemsUpdated,
          leftoversToAdd,
          extraQuantitiesDict,
        );

        // A field that has the orderDate with "Exists" concatenated to the end to help
        // with searching for the PaymentHistory document in the database.
        const orderExistsField = existingOrderDate.concat("Exists");

        // Find the document where this order date is saved.
        const paymentHistoryCollectRef = database
          .collection("PaymentHistory")
          .where(orderExistsField, "==", true);

        // Pull the document for reading.
        paymentHistoryCollectRef.get().then((collection) => {
          // If there are no documents then we just create a new payment history.
          if (collection.docs.length === 0) {
            // Update the Payment History to store information to help track payments.
            CreatePaymentHistory(
              now,
              selectedDate,
              importedOrder,
              communityOrders,
              userInfo,
            );
          }

          // Cycle through the documents but there should only be 1 that matches.
          collection.docs.forEach((doc) => {
            if (doc.exists) {
              // Pull the data that has the community orders, imported order and
              // selectedDate for payment history field.
              const ordersPaymentHistoryExisting =
                doc.data()[existingOrderDate];
              // The date the document was created since there are multiple weeks
              // per document of paymentHistory.
              const docName = doc.data().createdDate;
              // Update the Payment History to store information to help track payments.
              CreatePaymentHistory(
                existingOrderDate,
                selectedDate,
                importedOrder,
                communityOrders,
                userInfo,
                addToExistingOrder,
                ordersPaymentHistoryExisting,
                docName,
              );
            }
          });
        });
      });
    });
  }
}
